import { DateInput } from 'atoms/DateInput';
import * as CustomGrid from 'atoms/Grid';
import * as GridItem from 'components/GridItem';
import * as AutocompleteItems from 'components/input/AutoComplete';
import * as SelectItems from 'components/input/Select';
import { projectStatusInstrunctionCreated } from 'const/project/index';
import { MstDataContext } from 'contexts/Mst';
import { handleChangeValues } from 'functions/handles';
import React, { useContext, useMemo } from 'react';
import { ListItem } from 'types/index';
import { tProject } from 'types/project';

interface GridItemFieldProps {
  values: tProject;
  setValues: React.Dispatch<React.SetStateAction<tProject>>;
  size?: Record<string, number>;
  flgLabel?: boolean;
}

export const LuggageID = ({ values, setValues }: GridItemFieldProps) => {
  return (
    <GridItem.GridItemTextFieldCustom
      size={{ xs: 6, sm: 4, md: 3, lg: 2, xl: 1 }}
    >
      <AutocompleteItems.LuggageID
        name="l_id"
        label={'荷物種類'}
        value={{ id: values.l_id || null, label: values.l_name || '' }}
        cbValueChange={(val) => {
          console.log('val', val);
          handleChangeValues(val.label, 'l_name', setValues);
          handleChangeValues(val.id, 'l_id', setValues);
        }}
      />
    </GridItem.GridItemTextFieldCustom>
  );
};

export const LuggageWeight = ({ values, setValues }: GridItemFieldProps) => (
  <GridItem.GridItemNumberField
    label="数量"
    name="l_volume"
    value={values.l_volume}
    size={{ xs: 6, sm: 4, md: 3, lg: 2, xl: 1 }}
    cbHandleChange={(val: number) => {
      handleChangeValues(val, 'l_volume', setValues);
    }}
    flgFloat={true}
  />
);

export const Weight = ({ values, setValues }: GridItemFieldProps) => (
  <GridItem.GridItemNumberField
    label="重量(kg)"
    name="weight"
    value={values.weight}
    size={{ xs: 6, sm: 4, md: 3, lg: 2, xl: 1 }}
    cbHandleChange={(val: number) => {
      handleChangeValues(val, 'weight', setValues);
    }}
    flgFloat={true}
  />
);

export const VehicleType = ({ values, setValues }: GridItemFieldProps) => {
  return (
    <CustomGrid.GridItem size={{ xs: 6, sm: 4, md: 3, lg: 2, xl: 1 }}>
      <SelectItems.VehicleType
        name="vt_id"
        label="車種"
        value={Number(values.vt_id)}
        cbValueChange={(vt_id) => {
          handleChangeValues(vt_id, 'vt_id', setValues);
        }}
        flgVehicle={true}
        flgTrailer={false}
      />
    </CustomGrid.GridItem>
  );
};

export const Vehicle = React.memo(
  ({ values, setValues }: GridItemFieldProps) => {
    return (
      <CustomGrid.GridItem size={{ xs: 6, sm: 4, md: 3, lg: 2, xl: 2 }}>
        <AutocompleteItems.Vehicle
          name="v_id"
          label="車両"
          vt_id={values.vt_id || undefined}
          value={{ id: values.v_id || null, label: values.vehicle_name || '' }}
          cbValueChange={(item) => {
            handleChangeValues(item.label, 'vehicle_name', setValues);
            handleChangeValues(item.id, 'v_id', setValues);
          }}
        />
      </CustomGrid.GridItem>
    );
  },
  (prev, next) => {
    return (
      prev.values.v_id === next.values.v_id &&
      prev.values.vehicle_name === next.values.vehicle_name &&
      prev.values.vt_id === next.values.vt_id
    );
  }
);

export const Trailer = React.memo(
  ({ values, setValues }: GridItemFieldProps) => {
    const trailerValue = useMemo(
      () => ({
        id: values.trailer_v_id || null,
        label: values.trailer_name || '',
      }),
      [values.trailer_v_id, values.trailer_name]
    );
    return (
      <CustomGrid.GridItem size={{ xs: 6, sm: 4, md: 3, lg: 2, xl: 2 }}>
        <AutocompleteItems.Trailer
          name="trailer_v_id"
          label="被牽引車"
          value={trailerValue}
          cbValueChange={(item) => {
            handleChangeValues(item.label, 'trailer_name', setValues);
            handleChangeValues(item.id, 'trailer_v_id', setValues);
          }}
        />
      </CustomGrid.GridItem>
    );
  },
  (prev, next) => {
    return (
      prev.values.trailer_v_id === next.values.trailer_v_id &&
      prev.values.trailer_name === next.values.trailer_name
    );
  }
);

export const Cars = ({ values, setValues }: GridItemFieldProps) => {
  return (
    <GridItem.GridItemNumberField
      label={'台数'}
      value={values.cars}
      name={'cars'}
      size={{ xs: 6, sm: 4, md: 3, lg: 2, xl: 1 }}
      cbHandleChange={(cars: number) => {
        handleChangeValues(cars, 'cars', setValues);
      }}
    />
  );
};

export const Memo = ({ values, setValues }: GridItemFieldProps) => (
  <GridItem.GridItemRowTextField
    name="memo"
    label="備考"
    value={values.memo}
    size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 4 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'memo', setValues)
    }
  />
);

export const InternalMemo = ({ values, setValues }: GridItemFieldProps) => (
  <GridItem.GridItemRowTextField
    name="internal_memo"
    label="備考（社内）"
    value={values.internal_memo}
    size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 4 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'internal_memo', setValues)
    }
  />
);

export const ClientCompany = ({
  values,
  setValues,
  size = { xs: 12, sm: 6, md: 4, lg: 3, xl: 3 },
}: GridItemFieldProps) => {
  return (
    <CustomGrid.GridItem size={size}>
      <AutocompleteItems.ClientCompany
        label={'取引先'}
        value={{ id: values.c_id || null, label: '' }}
        cbValueChange={(val: ListItem) => {
          handleChangeValues(val.id, 'c_id', setValues);
        }}
      />
    </CustomGrid.GridItem>
  );
};

/**
 * 担当者
 * @param param0
 * @returns
 */
export const Person = ({
  values,
  setValues,
  size = { xs: 6, sm: 4, md: 3, lg: 2, xl: 1 },
}: GridItemFieldProps) => {
  return (
    <CustomGrid.GridItem size={size}>
      <AutocompleteItems.ClientPerson
        label={'担当'}
        value={{ id: values.p_id || null, label: '' }}
        c_id={values.c_id}
        cbValueChange={(val: ListItem) => {
          handleChangeValues(val.id, 'p_id', setValues);
        }}
      />
    </CustomGrid.GridItem>
  );
};

export const Status = ({
  values,
  setValues,
  size = { xs: 6, sm: 4, md: 3, lg: 2, xl: 1 },
}: GridItemFieldProps) => (
  <CustomGrid.GridItem size={size}>
    <SelectItems.PJStatus
      name="status"
      label="ステータス"
      value={values.status}
      cbValueChange={(status) => {
        handleChangeValues(status, 'status', setValues);
      }}
      disabled={
        values.status < (projectStatusInstrunctionCreated.id || 0)
          ? false
          : true
      }
    />
  </CustomGrid.GridItem>
);

export const Kind = ({ values, setValues }: GridItemFieldProps) => (
  <CustomGrid.GridItem size={{ xs: 6, sm: 4, md: 3, lg: 2, xl: 1 }}>
    <SelectItems.ProjectKind
      value={values.kind}
      cbValueChange={(val) => {
        handleChangeValues(val, 'kind', setValues);
      }}
      disabled={
        values.status < (projectStatusInstrunctionCreated.id || 0)
          ? false
          : true
      }
      flgNoSelect={true}
    />
  </CustomGrid.GridItem>
);

export const LoadDate = ({
  values,
  setValues,
  flgLabel = true,
}: GridItemFieldProps) => (
  <CustomGrid.GridItem>
    <DateInput
      type={'date'}
      label={flgLabel ? '積日時' : ''}
      name="load_datetime"
      value={values.load_datetime}
      onDateChange={(newDate: string) =>
        handleChangeValues(newDate, 'load_datetime', setValues)
      }
    />
  </CustomGrid.GridItem>
);

export const UnloadDate = ({
  values,
  setValues,
  flgLabel = true,
}: GridItemFieldProps) => (
  <CustomGrid.GridItem>
    <DateInput
      type={'date'}
      label={flgLabel ? '卸日時' : ''}
      name="unload_datetime"
      value={values.unload_datetime}
      onDateChange={(newDate: string) =>
        handleChangeValues(newDate, 'unload_datetime', setValues)
      }
    />
  </CustomGrid.GridItem>
);

export const InCharge = ({ values, setValues }: GridItemFieldProps) => {
  const { loginUser } = useContext(MstDataContext);

  if (values.in_charge === null) {
    handleChangeValues(loginUser.id, 'in_charge', setValues);
  }

  return (
    <CustomGrid.GridItem size={{ xs: 6, sm: 4, md: 3, lg: 2, xl: 1 }}>
      <SelectItems.InCharge
        value={values.in_charge}
        cbValueChange={(newDate) =>
          handleChangeValues(newDate, 'in_charge', setValues)
        }
      />
    </CustomGrid.GridItem>
  );
};
