import { FormControl, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { NoSelect } from 'const/index';
import { ItemSelectProps } from 'types/index';

export function SmallSelect({
  label = '',
  name = `SmallSelect`,
  value,
  items = [],
  cbValueChange,
  flgNoSelect = false,
  disabled = false,
}: ItemSelectProps) {
  return (
    <>
      {label && <Typography variant="h6">{label}</Typography>}
      <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
        <Select
          labelId={`labelId-${name}`}
          name={name}
          value={value !== null ? value : undefined}
          onChange={(e: SelectChangeEvent<number | string>) =>
            cbValueChange((e.target.value as number) || null)
          }
          fullWidth={true}
          disabled={disabled}
        >
          {flgNoSelect && (
            <MenuItem value={NoSelect.id || undefined}>
              {NoSelect.label}
            </MenuItem>
          )}
          {items.map((item) => (
            <MenuItem
              key={`${name}-${item.id ? item.id.toString() : 'init'}`}
              value={Number(item.id)}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
