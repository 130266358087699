import { TableCell, TableRow } from '@mui/material';
import { SecondButton } from 'atoms/Button';
import * as BtnLuggage from 'components/luggage/Button';
import PageNation from 'components/Pagenation';
import { initSearchLuggage } from 'const/luggage';
import TableFrame from 'frames/TableFrame';
import * as apiMst from 'functions/api/mst';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiGetIndexSuccess } from 'types/index';
import { tLuggage, tLuggageSaerch } from 'types/mst';

export default function Main() {
  const [list, setList] = useState<tLuggage[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [search, setSearch] = useState<tLuggageSaerch>(initSearchLuggage);

  const cbs = ({ data, currentPage, lastPage, filter }: apiGetIndexSuccess) => {
    setList(data);
    setCurrentPage(currentPage);
    setLastPage(lastPage);
    setSearch(filter);
  };

  // 検索処理
  const getSearch = (page: number) => {
    try {
      // ここにデータ取得処理を記述します
      apiMst.getLuggages({
        page: page,
        filter: search,
        callbackSuccess: cbs,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSearch(currentPage);
  }, []);

  return (
    <TableFrame
      HeadContent={
        <HeadContent
          setData={setList}
          setCurrentPage={setCurrentPage}
          setLastPage={setLastPage}
          setFilter={setSearch}
        />
      }
      TableHeaderRows={<TableHeaderRows />}
      TableBodyRows={<TableBodyRows list={list} />}
      SubContent={
        <SubContent
          lastPages={lastPage}
          currentPage={currentPage}
          getSearch={getSearch}
        />
      }
    />
  );
}

interface HeadContentProps {
  setData: React.Dispatch<React.SetStateAction<tLuggage[]>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setLastPage: React.Dispatch<React.SetStateAction<number>>;
  setFilter: React.Dispatch<React.SetStateAction<tLuggageSaerch>>;
}
const HeadContent = (props: HeadContentProps) => {
  const navigate = useNavigate();
  return (
    <>
      <BtnLuggage.SearchBtn {...props} />
      <SecondButton
        onClick={() => {
          navigate('/mst/luggage/input');
        }}
        label="新規"
      />
    </>
  );
};

const TableHeaderRows = () => {
  return (
    <TableRow>
      <TableCell>名前</TableCell>
      <TableCell>単位</TableCell>
    </TableRow>
  );
};

interface TableBodyRowsProps {
  list: tLuggage[];
}
const TableBodyRows = ({ list }: TableBodyRowsProps) => {
  const navigate = useNavigate();
  return (
    <>
      {list.map((data) => {
        return (
          <TableRow
            key={`luggages-${data.id}`}
            onDoubleClick={() => navigate(`/mst/luggage/edit/${data.id}`)}
          >
            <TableCell>{data.name}</TableCell>
            <TableCell>{`${data.unit}`}</TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

interface SubContentProps {
  lastPages: number;
  currentPage: number;
  getSearch: (page: number) => void;
}
const SubContent = ({ lastPages, currentPage, getSearch }: SubContentProps) => {
  return (
    <PageNation
      totalPages={lastPages}
      currentPage={currentPage}
      handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
        getSearch(value)
      }
    />
  );
};
