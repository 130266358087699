import { Grid, Typography } from '@mui/material';
import { FlexColumnBox } from 'atoms/Box';
import * as cUser from 'components/mst/User';
import * as BtnUser from 'components/user/Button';
import { initUser } from 'const/user';
import ContentsFrame from 'frames/ContentsFrame';
import { getUser } from 'functions/api/mst';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { tUser } from 'types/mst';

export default function Main() {
  const { id } = useParams();
  const [role, setRole] = useState<tUser>(initUser);

  const getSearch = async (id: number) => {
    try {
      // 住所データ取得
      getUser(id).then((res) => {
        if (res.status !== 200) throw new Error('データ取得に失敗しました');
        setRole(res.data);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getSearch(Number(id));
    }
  }, [id]);

  return (
    <ContentsFrame
      HeadContent={<HeadContent id={Number(id)} values={role} />}
      MainContent={<Details values={role} setValues={setRole} />}
      SubContent={<></>}
    />
  );
}

interface HeadContentProps {
  id: tUser['id'];
  values: tUser;
}
const HeadContent = ({ id, values }: HeadContentProps) => {
  return (
    <>
      {id ? (
        <>
          <BtnUser.Update data={values} />
          <BtnUser.Delete id={values.id} />
        </>
      ) : (
        <BtnUser.Store data={values} />
      )}
    </>
  );
};

interface DetailsProps {
  values: tUser;
  setValues: React.Dispatch<React.SetStateAction<tUser>>;
}
const Details = ({ values, setValues }: DetailsProps) => {
  const { id } = useParams();
  return (
    <FlexColumnBox>
      {id && (
        <Typography variant="h4">
          ID:
          <Typography component={'span'} variant="h4" sx={{ fontWeight: 700 }}>
            {values.id}
          </Typography>
        </Typography>
      )}
      <Grid container spacing={2}>
        <cUser.Email values={values} setValues={setValues} />
        {!id && <cUser.Password values={values} setValues={setValues} />}
      </Grid>
      <Grid container spacing={2}>
        <cUser.Vehicle values={values} setValues={setValues} />
        <cUser.Role values={values} setValues={setValues} />
      </Grid>
      <Grid container spacing={2}>
        <cUser.FName values={values} setValues={setValues} />
        <cUser.GName values={values} setValues={setValues} />
      </Grid>
      <Grid container spacing={2}>
        <cUser.FNameKana values={values} setValues={setValues} />
        <cUser.GNameKana values={values} setValues={setValues} />
      </Grid>
      <Grid container spacing={2}>
        <cUser.Birthday values={values} setValues={setValues} />
        <cUser.HireDate values={values} setValues={setValues} />
      </Grid>
      <Grid container spacing={2}>
        <cUser.PostNumber values={values} setValues={setValues} />
        <cUser.Address values={values} setValues={setValues} />
        <cUser.Tell values={values} setValues={setValues} />
      </Grid>
    </FlexColumnBox>
  );
};
