import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { InputFormes } from 'components/mst/Address';
import * as cTranMethod from 'components/mst/TransportMethod';
import * as BtnTM from 'components/transportMethod/Button';
import { initAddress } from 'const/address';
import { initTransportMethod } from 'const/transportMethod/index';
import { MstDataContext } from 'contexts/Mst';
import ContentsFrame from 'frames/ContentsFrame';
import { getTransportMethod } from 'functions/api/mst';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { tAddress } from 'types/address';
import { tTransportMethod } from 'types/mst';

export default function Main() {
  const { id } = useParams();
  const theme = useTheme();
  const [data, setData] = useState<tTransportMethod>(initTransportMethod);

  useEffect(() => {
    const fetchData = async (id: number) => {
      try {
        getTransportMethod(id).then((res) => {
          if (res.status !== 200) throw new Error('データ取得に失敗しました');

          const tranMethod: tTransportMethod = res.data;
          setData(tranMethod);
        });
      } catch (error) {
        console.error(error);
        alert('データ取得に失敗しました');
      }
    };
    if (id) {
      fetchData(Number(id));
    }
  }, [id]);

  return (
    <ContentsFrame
      HeadContent={<HeadContent id={Number(id)} values={data} />}
      MainContent={<Details values={data} setValues={setData} />}
      SubContent={<></>}
    />
  );
}

interface HeadContentProps {
  id: tTransportMethod['id'];
  values: tTransportMethod;
}
const HeadContent = ({ id, values }: HeadContentProps) => {
  return (
    <>
      {id ? (
        <>
          <BtnTM.Update data={values} />
          <BtnTM.Delete id={values.id} />
        </>
      ) : (
        <BtnTM.Store data={values} />
      )}
    </>
  );
};

interface DetailsProps {
  values: tTransportMethod;
  setValues: React.Dispatch<React.SetStateAction<tTransportMethod>>;
}
const Details = ({ values, setValues }: DetailsProps) => {
  const theme = useTheme();
  const { id } = useParams();
  const { addresses } = useContext(MstDataContext);
  const [startAddress, setStartAddress] = useState<tAddress>(initAddress);
  const [endAddress, setEndAddress] = useState<tAddress>(initAddress);

  useEffect(() => {
    if (values.start_a_id) {
      setStartAddress(() => {
        const sa = addresses?.find(
          (address) => address.id === values.start_a_id
        );
        return sa || initAddress;
      });
    }

    if (values.end_a_id) {
      setEndAddress(() => {
        const ea = addresses?.find(
          (address) => address.id === values.start_a_id
        );
        return ea || initAddress;
      });
    }
  }, [values.id]);

  useEffect(() => {
    setValues((prev: tTransportMethod) => ({
      ...prev,
      start_a_id: startAddress.id,
      start_name: startAddress.name,
      start_prefectures: startAddress.prefectures,
      start_city: startAddress.city,
      start_street: startAddress.street,
      start_building: startAddress.building,
    }));
  }, [startAddress]);

  useEffect(() => {
    setValues((prev: tTransportMethod) => ({
      ...prev,
      end_a_id: endAddress.id,
      end_name: endAddress.name,
      end_prefectures: endAddress.prefectures,
      end_city: endAddress.city,
      end_street: endAddress.street,
      end_building: endAddress.building,
    }));
  }, [endAddress]);

  return (
    <>
      {id && (
        <Typography variant="h4">
          ID:
          <Typography component={'span'} variant="h4" sx={{ fontWeight: 700 }}>
            {values.id}
          </Typography>
        </Typography>
      )}
      <Grid container spacing={2}>
        <cTranMethod.Name values={values} setValues={setValues} />
        <cTranMethod.Abbreviation values={values} setValues={setValues} />
        <cTranMethod.ChipName values={values} setValues={setValues} />
        <cTranMethod.FlgOn values={values} setValues={setValues} />

        <Grid item xs={12}>
          <Typography variant="h4">発</Typography>
          <Box
            sx={{ display: 'flex', flexFlow: 'rows', gap: theme.spacing(1) }}
          >
            <cTranMethod.StartTime values={values} setValues={setValues} />
            <InputFormes values={startAddress} setValues={setStartAddress} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">着地</Typography>
          <Box
            sx={{ display: 'flex', flexFlow: 'rows', gap: theme.spacing(1) }}
          >
            <cTranMethod.EndTime values={values} setValues={setValues} />
            <InputFormes values={endAddress} setValues={setEndAddress} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
