import { Grid } from '@mui/material';
import * as PComponent from 'components/project/Index';
import { getProject } from 'functions/api/project';
import log from 'functions/logger';
import { useObjectList } from 'functions/useReducer/customHook';
import { ReactNode, useEffect, useState } from 'react';
import {
  initProject,
  initWaypoints,
  tProject,
  tProjectPriceDetail,
  tWaypoints,
} from 'types/project';
import { tObjectListReturn } from 'types/useReducer';

interface MainProps {
  pj: tProject;
  setPj: React.Dispatch<React.SetStateAction<tProject>>;
  children?: ReactNode;
}

export default function Main({ pj, setPj, children }: MainProps) {
  return (
    <Grid container spacing={2}>
      <PComponent.ClientCompany values={pj} setValues={setPj} />
      <PComponent.Person values={pj} setValues={setPj} />

      <PComponent.Status values={pj} setValues={setPj} />
      <PComponent.Kind values={pj} setValues={setPj} />
      <PComponent.Weight values={pj} setValues={setPj} />
      <PComponent.LuggageID values={pj} setValues={setPj} />
      <PComponent.VehicleType values={pj} setValues={setPj} />
      <PComponent.Vehicle values={pj} setValues={setPj} />
      <PComponent.Trailer values={pj} setValues={setPj} />
      <PComponent.InCharge values={pj} setValues={setPj} />

      {children}

      <PComponent.Memo values={pj} setValues={setPj} />
      <PComponent.InternalMemo values={pj} setValues={setPj} />
    </Grid>
  );
}

interface ViewProps {
  pjId: number;
  children?: ReactNode;
}

/**
 * 表示用
 * @param param0
 * @returns
 */
export function View({ pjId, children }: ViewProps) {
  const [values, setValues] = useState<tProject>(initProject);
  const [waypoints, setWaypoints] = useState<tWaypoints>(initWaypoints);

  // 金額明細
  const priceList: tObjectListReturn<tProjectPriceDetail> =
    useObjectList<tProjectPriceDetail>([]);

  const cbsGetProject = (data: tProject) => {
    setValues(data);
    setWaypoints(data.waypoints);
    priceList.setList(data.price_details || []);
  };
  const obeGetProject = (error: any) => {
    log.debug('project/Detail.tsx getProject Error', error);
    alert('案件情報の取得に失敗しました。');
  };
  useEffect(() => {
    // プロジェクト情報取得
    getProject(pjId, cbsGetProject, obeGetProject);
  }, [pjId]);

  if (!values || !setValues) return null;
  if (!waypoints || !setWaypoints) return null;

  return (
    <Main pj={values} setPj={setValues}>
      {children}
    </Main>
  );
}
