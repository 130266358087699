import { PrimaryButton, SecondButton } from 'atoms/Button';
import Modal from 'atoms/Modal';
import * as cMst from 'components/mst/Index';
import { initCompanySearch, orderOptions } from 'const/comapny/index';
import log from 'functions/logger';
import React, { useState } from 'react';
import { tCompanySearch } from 'types/company';
import { tOrder } from 'types/index';

interface MainProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  callback: (data: tCompanySearch, orders: tOrder[]) => void;
}
const Main = ({ open, setOpen, callback }: MainProps) => {
  const [values, setValues] = useState<tCompanySearch>(initCompanySearch);
  const [orders, setOrders] = useState<number[]>([1]);

  const handleClickSearch = () => {
    log.debug('handleClickSearch');

    // ordersを元に並び替えの指定を作成
    const paramsOrders = orders
      .map((val) => orderOptions.find((option) => option.id === val)?.order)
      .filter((order): order is tOrder => order !== undefined);

    callback(values, paramsOrders || []);
    handleCickClose();
  };

  const handleCickClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      width="md"
      title=""
      actions={
        <ActionBtn
          callbackSearch={handleClickSearch}
          callbackClose={handleCickClose}
        />
      }
      open={open}
      onClose={handleCickClose}
    >
      <cMst.Freewords value={values.freewords} setValues={setValues} />
      <cMst.Orders
        values={orders[0]}
        setValues={setOrders}
        no={0}
        options={orderOptions}
      />
    </Modal>
  );
};

export default Main;

interface ActionBtnProps {
  callbackSearch: () => void;
  callbackClose: () => void;
}
const ActionBtn = ({ callbackSearch, callbackClose }: ActionBtnProps) => {
  return (
    <>
      <PrimaryButton label="検索" onClick={callbackSearch} />
      <SecondButton onClick={callbackClose} label={'閉じる'} />
    </>
  );
};
