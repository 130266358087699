import { tDailyMemo } from 'types/dailymemo';

export const initDailyMemo: tDailyMemo = {
  id: undefined,
  datetime: '',
  memo: '',
  to_user_id: undefined,
  toUser: undefined,
  insertUser: undefined,
  updateUser: undefined,
};
