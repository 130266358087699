import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import EastIcon from '@mui/icons-material/East';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { FlexBox, FlexColumnBox } from 'atoms/Box';
import { PrimaryButton, SecondButton } from 'atoms/Button';
import CardWithToolchip from 'components/CardWithToolchip';
import * as TmpInstCard from 'components/instruction/Card';
import * as ComponentOperation from 'components/instruction/Index';
import { InputFormes } from 'components/mst/Address';
import * as ComponentTransport from 'components/transport/Index';
import { initAddress } from 'const/address/index';
import { waypointKbnLoad, waypointKbnUnload } from 'const/index';
import initInstruction from 'const/instruction/index';
import initTransport from 'const/transport';
import { MstDataContext } from 'contexts/Mst';
import { getTransport } from 'functions/api/transport';
import { handleChangeValues } from 'functions/handles';
import { truncateString } from 'functions/index';
import log from 'functions/logger';
import { getWaypointName } from 'functions/project/index';
import {
  changeDateToStrDay,
  mathDateTime,
  strDateOrigin,
} from 'functions/time';
import React, { useContext, useEffect, useState } from 'react';
import { tAddress } from 'types/address';
import { tInstruction } from 'types/instruction';
import { tTransportMethod, tUser } from 'types/mst';
import { tProject, tWaypoint } from 'types/project';
import { tTransport } from 'types/transport';

interface MainProps {
  project: tProject | undefined;
  tranNo: tTransport['no'];
  selectedIdx: number;
  setSelectedIdx: React.Dispatch<React.SetStateAction<number>>;
  driver?: tUser | undefined;
  values: tInstruction;
  setValues: React.Dispatch<React.SetStateAction<tInstruction>>;
  instructions: tInstruction[];
  setInstructions: React.Dispatch<React.SetStateAction<tInstruction[]>>;
  startAddress: tAddress;
  setStartAddress: React.Dispatch<React.SetStateAction<tAddress>>;
  endAddress: tAddress;
  setEndAddress: React.Dispatch<React.SetStateAction<tAddress>>;
  closeCallback: () => void;
  initInstCustom?: tInstruction;
}

/**
 * 運行指示入力モーダル
 * @param param0
 * @returns
 */
export default function Main({
  project,
  selectedIdx,
  setSelectedIdx,
  values,
  setValues,
  instructions,
  setInstructions,
  startAddress,
  setStartAddress,
  endAddress,
  setEndAddress,
  closeCallback,
  initInstCustom = initInstruction,
}: MainProps) {
  const theme = useTheme();
  const { users, tranMethods, SYSTEM } = useContext(MstDataContext);
  const [select, setSelect] = useState(instructions.length);
  const [transport, setTransport] = useState<tTransport>(initTransport);

  //log.debug('instruction detail ', values.user_id);

  // 案件情報、運行指示情報を元に初期値をセット
  useEffect(() => {
    if (project === undefined) {
      return;
    }

    if (instructions.length === 0) {
      // 運行指示未作成の場合は案件情報の積卸情報をセット
      setValues((prev: tInstruction) => {
        return {
          ...prev,
          start_datetime: values.start_datetime || project.load_datetime || '',
          start_a_id: project.load_a_id || null,
          start_name: project.load_name || '',
          start_prefectures: project.load_prefectures || '',
          start_city: project.load_city || '',
          start_street: project.load_street || '',

          end_datetime: values.end_datetime || project.unload_datetime || '',
          end_a_id: project.unload_a_id || null,
          end_name: project.unload_name || '',
          end_prefectures: project.unload_prefectures || '',
          end_city: project.unload_city || '',
          end_street: project.unload_street || '',
        };
      });
      setSelectedIdx(0);
    } else {
      // 運行指示がある場合は最後の運行指示の値をセット
      const lastInstruction = instructions[instructions.length - 1];
      setValues((prev: tInstruction) => {
        return {
          ...prev,
          start_datetime:
            values.start_datetime ||
            lastInstruction.end_datetime ||
            project.load_datetime,
          start_a_id: lastInstruction.end_a_id || project.load_a_id || null,
          start_name: lastInstruction.end_name || project.load_name,
          start_prefectures:
            lastInstruction.end_prefectures || project.load_prefectures,
          start_city: lastInstruction.end_city || project.load_city,
          start_street: lastInstruction.end_street || project.load_street,
          start_building: lastInstruction.end_building || project.load_building,

          end_datetime:
            values.end_datetime ||
            project.unload_datetime > lastInstruction.end_datetime
              ? project.unload_datetime
              : lastInstruction.end_datetime,
          end_a_id: project.unload_a_id,
          end_name: project.unload_name,
          end_prefectures: project.unload_prefectures,
          end_city: project.unload_city,
          end_street: project.unload_street,
          end_building: project.unload_building,
        };
      });
      setSelectedIdx(instructions.length);
    }

    // 輸送情報を取得
    if (instructions.length > 0) {
      const cbs = (data: tTransport) => {
        setTransport(data);
      };
      const cbe = (error: any) => {
        setTransport(initTransport);
      };
      getTransport(instructions[0].tran_id || 0, cbs, cbe);
    } else {
      setTransport(initTransport);
    }
  }, [project, instructions]);

  /**
   * 選択したドライバー情報を取得、セット
   */
  useEffect(() => {
    if (select < instructions.length) {
      return;
    }
    // user_idのドライバーを取得
    const driver = users?.find((user) => user.id === values.user_id);
    setValues((prev) => {
      return { ...prev, v_id: driver?.v_id, driver: driver };
    });
  }, [values.user_id, users]);

  /**
   * 輸送方法選択時の処理
   */
  useEffect(() => {
    //log.debug('instruction detail useEffect values.tm_id', values.tm_id);
    if (values.tm_id === SYSTEM?.tranMethod.own.id) {
      // 不要な項目のクリア
      setValues((prev) => {
        return {
          ...prev,
          c_id: undefined,
          c_vehicle: '',
        };
      });
      return;
    } else if (values.tm_id === SYSTEM?.tranMethod.charter.id) {
      // 不要な項目のクリア
      setValues((prev) => {
        return {
          ...prev,
          user_id: undefined,
        };
      });
      return;
    } else {
      const selected = tranMethods?.find(
        (tm: tTransportMethod) => tm.id === values.tm_id
      );

      if (!selected) {
        alert('選択した輸送手段が見つかりません。');
        return;
      }

      setStartAddress((prev) => {
        return {
          ...prev,
          id: selected?.start_a_id,
          name: selected?.start_name || '',
        };
      });

      setEndAddress((prev) => {
        return {
          ...prev,
          id: selected?.end_a_id,
          name: selected?.end_name || '',
        };
      });

      // 不要な項目のクリアと選択した情報から発着地を登録

      setValues((prev) => {
        let startDatetime = prev.start_datetime;
        let endDatetime = prev.end_datetime;

        if (selected.start_time) {
          startDatetime =
            strDateOrigin(prev.start_datetime) + ' ' + selected.start_time;
        }
        if (selected.end_time) {
          if (selected.start_time && selected.start_time > selected.end_time) {
            // 日を跨ぐ移動
            const a = mathDateTime(prev.start_datetime, [0, 0, 1, 0, 0, 0, 0]);
            const nextDay = changeDateToStrDay(a);
            endDatetime = nextDay + ' ' + selected.end_time;
          } else {
            endDatetime =
              strDateOrigin(prev.start_datetime) + ' ' + selected.end_time;
          }
        }

        return {
          ...prev,
          user_id: undefined,
          c_id: undefined,
          c_vehicle: '',
          start_datetime: startDatetime,
          end_datetime: endDatetime,
        };
      });
    }
  }, [values.tm_id, tranMethods]);

  useEffect(() => {
    //log.debug('instruction detail useEffect select', [select, instructions]);
    setSelectedIdx(select);
    if (instructions.length - 1 >= select) {
      // 選択中の運行指示が配列の範囲内の場合は選択中の運行指示を表示
      setValues(instructions[select]);
      setStartAddress({
        ...initAddress,
        id: instructions[select].start_a_id || undefined,
        name: instructions[select].start_name,
        prefectures: instructions[select].start_prefectures,
        city: instructions[select].start_city,
        street: instructions[select].start_street,
        building: instructions[select].start_building,
      });
      setEndAddress({
        ...initAddress,
        id: instructions[select].end_a_id || undefined,
        name: instructions[select].end_name,
        prefectures: instructions[select].end_prefectures,
        city: instructions[select].end_city,
        street: instructions[select].end_street,
        building: instructions[select].end_building,
      });

      return;
    } else if (instructions.length - 1 < select && instructions.length > 0) {
      // 選択中の運行指示が配列の範囲外の場合、最終運行指示の続きを想定する
      setStartAddress({
        ...initAddress,
        id: instructions[select - 1].end_a_id || undefined,
        name: instructions[select - 1].end_name,
        prefectures: instructions[select - 1].end_prefectures,
        city: instructions[select - 1].end_city,
        street: instructions[select - 1].end_street,
        building: instructions[select - 1].end_building,
      });
      setValues((prev: any) => {
        return {
          ...prev,
          ...initInstCustom,
          /*
          tm_id: SYSTEM?.tranMethod.own.id || 0,
          start_datetime: instructions[select - 1].end_datetime,
          start_a_id: instructions[select - 1].end_a_id,
          start_name: instructions[select - 1].end_name,
          start_prefectures: instructions[select - 1].end_prefectures,
          start_city: instructions[select - 1].end_city,
          start_street: instructions[select - 1].end_street,
          start_building: instructions[select - 1].end_building,
          start_trailer_v_id: instructions[select - 1].end_trailer_v_id,

          end_datetime: instructions[select - 1].end_datetime,
          end_a_id: 0,
          end_name: '',
          end_prefectures: '',
          end_city: '',
          end_street: '',
          end_building: '',
          end_trailer_v_id: null,
          */
        };
      });
      setEndAddress(initAddress);
      return;
    } else if (instructions.length === 0) {
      setSelect(0);
      setSelectedIdx(0);
    }

    // 初期値
    setValues(initInstCustom);
    setStartAddress(initAddress);
    setEndAddress(initAddress);
  }, [select]);

  useEffect(() => {
    //log.debug('selectedIdx', selectedIdx);
    setSelect(selectedIdx);
  }, [selectedIdx]);

  /**
   * 積卸地点クリックで運行指示の値を変更
   * @param waypoint
   */
  const handleWaypointClick = (
    setState: React.Dispatch<React.SetStateAction<tAddress>>,
    waypoint: tWaypoint
  ) => {
    // 運行指示の値を変更
    setValues((prev: tInstruction) => {
      return {
        ...prev,
        datetime: waypoint.datetime,
      };
    });

    // 積卸地点の値を変更
    setState((prev: tAddress) => {
      return {
        ...prev,
        id: waypoint.a_id,
        name: waypoint.a_name,
        post_number: waypoint.post_number,
        prefectures: waypoint.prefectures,
        city: waypoint.city,
        street: waypoint.street,
        building: waypoint.building,
      };
    });
  };

  log.debug('instruction detail', values);

  return (
    <FlexColumnBox>
      <FlexBox
        key={`tmpInstructions`}
        sx={{
          flexWrap: 'no-wrap',
          overflowX: 'auto',
          justifyContent: 'left',
          alignItems: 'center',
        }}
      >
        <ComponentTransport.ContainerCode
          value={transport?.container_code || ''}
          cbValueChange={(val: string) => {
            handleChangeValues(val, 'container_code', setTransport);
          }}
        />
      </FlexBox>

      {/* 案件情報 */}
      <FlexBox
        sx={{
          flexWrap: 'no-wrap',
          overflowX: 'auto',
        }}
      >
        <Waypoints
          waypoints={project?.waypoints || []}
          setLoadAddress={setStartAddress}
          setUnloadAddress={setEndAddress}
          handleWaypointClick={handleWaypointClick}
        />
      </FlexBox>
      {/* 運行指示 */}
      {/* 輸送情報 */}
      <FlexBox
        key={`tmpInstructions`}
        sx={{
          flexWrap: 'no-wrap',
          overflowX: 'auto',
          justifyContent: 'left',
          alignItems: 'center',
        }}
      >
        <Instructioned
          instructions={instructions}
          setInstructions={setInstructions}
          selectedIdx={select}
          setSelectedIdx={setSelect}
        />
      </FlexBox>
      {/* 発着日 */}
      <FlexBox>
        <ComponentOperation.StartDatetime
          values={values}
          setValues={setValues}
        />
        <ComponentOperation.EndDatetime values={values} setValues={setValues} />

        <ComponentOperation.PickUp values={values} setValues={setValues} />
      </FlexBox>
      {/* 発地 */}
      <FlexBox>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h4">発地</Typography>
          <Grid container spacing={2}>
            <InputFormes values={startAddress} setValues={setStartAddress} />
          </Grid>
        </Box>
      </FlexBox>
      {/* 着地 */}
      <FlexBox>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h4">着地</Typography>
          <Grid container spacing={2}>
            <InputFormes values={endAddress} setValues={setEndAddress} />
          </Grid>
        </Box>
      </FlexBox>

      {/* 輸送手段 ドライバー、傭車先 */}
      <FlexBox>
        <Grid container spacing={2}>
          <ComponentOperation.SelectMethods
            values={values}
            setValues={setValues}
            size={{ xs: 12, sm: 6, md: 2, lg: 2 }}
          />
          {values?.tm_id === SYSTEM?.tranMethod.own.id && (
            <ComponentOperation.Driver values={values} setValues={setValues} />
          )}
          {values?.tm_id === SYSTEM?.tranMethod.charter.id && (
            <>
              <ComponentOperation.ClientCompany
                values={values}
                setValues={setValues}
              />
              <ComponentOperation.ClientVehicle
                values={values}
                setValues={setValues}
              />
              <ComponentOperation.ClientPrice
                values={values}
                setValues={setValues}
              />
            </>
          )}
        </Grid>
      </FlexBox>

      <FlexBox>
        <Grid container spacing={2}>
          <ComponentOperation.Trailer
            label="自社シャーシ"
            name={'start_trailer_v_id'}
            value={values.start_trailer_v_id || null}
            cbValueChange={(val) => {
              handleChangeValues(val, 'start_trailer_v_id', setValues);
            }}
          />
          <ComponentOperation.TrailerMemo
            label="自社シャーシ（備考）"
            name={'start_trailer_memo'}
            value={values.start_trailer_memo}
            cbValueChange={(val: string) => {
              handleChangeValues(val, 'start_trailer_memo', setValues);
            }}
          />
          <ComponentOperation.Trailer
            label="自社シャーシ（着）"
            name={'end_trailer_v_id'}
            value={values.end_trailer_v_id || null}
            cbValueChange={(val) => {
              handleChangeValues(val, 'end_trailer_v_id', setValues);
            }}
          />
          <ComponentOperation.TrailerMemo
            label="自社シャーシ（着）（備考）"
            name={'end_trailer_memo'}
            value={values.end_trailer_memo}
            cbValueChange={(val: string) => {
              handleChangeValues(val, 'end_trailer_memo', setValues);
            }}
          />
        </Grid>
      </FlexBox>
      <FlexBox>
        <Grid container spacing={2}></Grid>
      </FlexBox>
    </FlexColumnBox>
  );
}

interface WaypointsProps {
  waypoints: tWaypoint[];
  setLoadAddress: React.Dispatch<React.SetStateAction<tAddress>>;
  setUnloadAddress: React.Dispatch<React.SetStateAction<tAddress>>;
  handleWaypointClick: (
    setState: React.Dispatch<React.SetStateAction<tAddress>>,
    waypoint: tWaypoint
  ) => void;
}

/**
 * 経由地点表示コンポーネント
 * @param param0
 * @returns
 */
const Waypoints = ({
  waypoints,
  setLoadAddress,
  setUnloadAddress,
  handleWaypointClick,
}: WaypointsProps) => {
  const theme = useTheme();
  return (
    <React.Fragment key={`waypoints-component`}>
      {waypoints.map((waypoint: tWaypoint, index) => {
        return (
          <React.Fragment key={`waypoint-${index}`}>
            <CardWithToolchip
              toolchipChildren={
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'rows',
                    gap: theme.spacing(1),
                  }}
                >
                  <SecondButton
                    label="発"
                    onClick={() =>
                      handleWaypointClick(setLoadAddress, waypoint)
                    }
                  />
                  <PrimaryButton
                    label="着"
                    onClick={() =>
                      handleWaypointClick(setUnloadAddress, waypoint)
                    }
                  />
                </Box>
              }
            >
              <Box
                sx={{
                  display: 'flex',
                  flexFlow: 'column',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  //handleWaypointClick(waypoint);
                }}
              >
                {waypoint.kbn === waypointKbnLoad.id && <ArrowCircleUpIcon />}
                {waypoint.kbn === waypointKbnUnload.id && (
                  <ArrowCircleDownIcon />
                )}
                <Typography>
                  {truncateString(getWaypointName(waypoint), 7)}
                </Typography>
              </Box>
            </CardWithToolchip>
            {waypoints.length - 1 !== index && (
              <Box
                sx={{
                  display: 'flex',
                  flexFlow: 'column',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
              >
                <EastIcon />
              </Box>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

interface InstructionedProps {
  instructions: tInstruction[];
  setInstructions: React.Dispatch<React.SetStateAction<tInstruction[]>>;
  selectedIdx: number;
  setSelectedIdx: React.Dispatch<React.SetStateAction<number>>;
}

/**
 * 作成済み運行指示表示コンポーネント
 * @param param0
 * @returns
 */
const Instructioned = ({
  instructions,
  setInstructions,
  selectedIdx,
  setSelectedIdx,
}: InstructionedProps) => {
  const theme = useTheme();
  return (
    <React.Fragment key={`instructions-component`}>
      {instructions.map((instruction: tInstruction, index) => {
        return (
          <React.Fragment
            key={`instruction-${instruction.pj_id}-${instruction.no}-${
              instruction.order || 'index' + index
            }`}
          >
            <TmpInstCard.WithToolchip
              instruction={instruction}
              flgOmit={false}
              selected={selectedIdx === index}
              cardChildren={<></>}
              callbackDoubleClick={() => {
                if (selectedIdx === index) {
                  setSelectedIdx(instructions.length);
                } else {
                  setSelectedIdx(index);
                }
              }}
              toolchipChildren={
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'row',
                    flexWrap: 'no-wrap',
                    gap: theme.spacing(2),
                  }}
                >
                  {index !== 0 && (
                    <PrimaryButton
                      label="一つ前へ"
                      onClick={() => {
                        setInstructions((prev) => {
                          const newInstructions = [...prev];
                          const tmp = newInstructions[index];
                          newInstructions[index] = newInstructions[index - 1];
                          newInstructions[index - 1] = tmp;
                          return newInstructions;
                        });
                      }}
                    />
                  )}
                  <SecondButton
                    label="削除"
                    onClick={() => {
                      // instructionsから削除
                      setInstructions((prev) => {
                        return prev.filter((_, i) => i !== index);
                      });
                    }}
                  />
                  {index !== instructions.length - 1 && (
                    <PrimaryButton
                      label="一つ後へ"
                      onClick={() => {
                        // instructionsの順番を入れ替え
                        setInstructions((prev) => {
                          const newInstructions = [...prev];
                          const tmp = newInstructions[index];
                          newInstructions[index] = newInstructions[index + 1];
                          newInstructions[index + 1] = tmp;
                          return newInstructions;
                        });
                      }}
                    />
                  )}
                </Box>
              }
            />
            {instructions.length - 1 !== index && <EastIcon />}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
