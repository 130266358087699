import { NumberField } from 'atoms/TextField';
import { ItemNumberFieldProps } from 'types';

export const Main = ({
  label = '',
  name = '',
  value,
  cbValueChange,
}: ItemNumberFieldProps) => {
  return (
    <NumberField
      label={label}
      name={name}
      value={value}
      cbHandleChangeValues={cbValueChange}
      flgFloat={false}
    />
  );
};

export const PriceUnit = ({
  label = '単価',
  name = 'price_unit',
  value,
  cbValueChange,
}: ItemNumberFieldProps) => {
  return (
    <NumberField
      label={label}
      name={name}
      value={value}
      cbHandleChangeValues={cbValueChange}
      flgFloat={false}
    />
  );
};

export const Quantity = ({
  label = '数量',
  name = 'quantity',
  value,
  cbValueChange,
}: ItemNumberFieldProps) => {
  return (
    <NumberField
      label={label}
      name={name}
      value={value}
      cbHandleChangeValues={cbValueChange}
      flgFloat={false}
    />
  );
};
