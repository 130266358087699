import * as CustomGrid from 'atoms/Grid';
import { GridItemDateField, GridItemTextField } from 'components/GridItem';
import * as AutoCompleteItems from 'components/input/AutoComplete';
import * as SelectItems from 'components/input/Select';
import { handleChangeValues } from 'functions/handles';
import React from 'react';
import { tUser } from 'types/mst';

interface GridItemFieldProps {
  values: tUser;
  setValues: React.Dispatch<React.SetStateAction<tUser>>;
}
export const Email = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="メールアドレス"
    name="email"
    value={values.email}
    size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'email', setValues)
    }
  />
);
export const Password = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="パスワード"
    name="password"
    value={values.password}
    size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'password', setValues)
    }
  />
);

export const FName = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="姓"
    name="family_name"
    value={values.family_name}
    size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'family_name', setValues)
    }
  />
);

export const FNameKana = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="セイ"
    name="family_name_kana"
    value={values.family_name_kana}
    size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'family_name_kana', setValues)
    }
  />
);

export const GName = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="名"
    name="given_name"
    value={values.given_name}
    size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'given_name', setValues)
    }
  />
);

export const GNameKana = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="ナマエ"
    name="given_name_kana"
    value={values.given_name_kana}
    size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'given_name_kana', setValues)
    }
  />
);

export const Birthday = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemDateField
    size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}
    label="生年月日"
    name="birthday"
    value={values.birthday}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'birthday', setValues)
    }
  />
);

export const Tell = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="電話番号"
    name="tell"
    value={values.tell}
    size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'tell', setValues)
    }
  />
);

export const PostNumber = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="郵便番号"
    name="post_number"
    value={values.post_number}
    size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'post_number', setValues)
    }
  />
);

export const Address = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="住所"
    name="address"
    value={values.address}
    size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'address', setValues)
    }
  />
);

export const Role = ({ values, setValues }: GridItemFieldProps) => {
  return (
    <CustomGrid.GridItem size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
      <SelectItems.Role
        name={'role_id'}
        value={values.role_id}
        cbValueChange={(val) => {
          handleChangeValues(val, 'role_id', setValues);
        }}
      />
    </CustomGrid.GridItem>
  );
};

export const Vehicle = ({ values, setValues }: GridItemFieldProps) => {
  return (
    <CustomGrid.GridItem size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
      <AutoCompleteItems.Vehicle
        name={'v_id'}
        value={{ id: values.v_id, label: '' }}
        cbValueChange={(val) => {
          handleChangeValues(val.id, 'v_id', setValues);
        }}
      />
    </CustomGrid.GridItem>
  );
};

export const HireDate = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemDateField
    label="雇用日"
    name="hire_date"
    value={values.hire_date}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'hire_date', setValues)
    }
  />
);
