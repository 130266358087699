import { Grid } from '@mui/material';
import { FlexColumnBox } from 'atoms/Box';
import { SecondButton } from 'atoms/Button';
import Modal from 'atoms/Modal';
import * as ApiBtn from 'components/company/ButtonPerson';
import { GridItemTextField } from 'components/GridItem';
import { handleChangeValues } from 'functions/handles';
import React, { useEffect } from 'react';
import { initialPerson, tPerson } from 'types/mst';

interface Props {
  flgOpen: boolean;
  cbOnClose: () => void;
  customPerson?: tPerson;
}
export const Main = ({ flgOpen, cbOnClose, customPerson }: Props) => {
  const [editPerson, setEditPerson] = React.useState<tPerson>(
    customPerson || initialPerson
  );

  useEffect(() => {
    setEditPerson(customPerson || initialPerson);
  }, [flgOpen]);

  return (
    <Modal
      title="人物"
      actions={<Actions person={editPerson} cbs={cbOnClose} />}
      open={flgOpen}
      onClose={cbOnClose}
    >
      <Detail data={editPerson} setData={setEditPerson} />
    </Modal>
  );
};

interface DetailProps {
  data: tPerson;
  setData: React.Dispatch<React.SetStateAction<tPerson>>;
}
const Detail = ({ data, setData }: DetailProps) => {
  return (
    <FlexColumnBox>
      <Grid container spacing={4}>
        <GridItemTextField
          name={'family_name'}
          label="名称"
          value={data.family_name}
          handleChangeValues={(val: string) =>
            handleChangeValues(val, 'family_name', setData)
          }
        />
        {/*
      <GridItemTextField
        name={'given_name'}
        label="名"
        value={data.given_name}
        handleChangeValues={(val: string) =>
          handleChangeValues(val, 'given_name', setData)
        }
      />
      <Grid item xs={12}></Grid>

*/}
        <GridItemTextField
          name={'family_name_kana'}
          label="名称（カナ）"
          value={data.family_name_kana}
          handleChangeValues={(val: string) =>
            handleChangeValues(val, 'family_name_kana', setData)
          }
        />
        {/*
          <GridItemTextField
            name={'given_name_kana'}
            label="名（カナ）"
            value={data.given_name_kana}
            handleChangeValues={(val: string) =>
              handleChangeValues(val, 'given_name_kana', setData)
            }
          />
        */}
      </Grid>

      <Grid container spacing={4}>
        <GridItemTextField
          name={'tell'}
          label="電話番号"
          value={data.tell}
          handleChangeValues={(val: string) =>
            handleChangeValues(val, 'tell', setData)
          }
          size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}
        />
        <GridItemTextField
          name={'email'}
          label="メールアドレス"
          value={data.email}
          handleChangeValues={(val: string) =>
            handleChangeValues(val, 'email', setData)
          }
          size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}
        />

        <GridItemTextField
          name={'post_number'}
          label="郵便番号"
          value={data.post_number}
          handleChangeValues={(val: string) =>
            handleChangeValues(val, 'post_number', setData)
          }
        />
        <GridItemTextField
          name={'address1'}
          label="住所1"
          value={data.address1}
          handleChangeValues={(val: string) =>
            handleChangeValues(val, 'address1', setData)
          }
          size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}
        />
        <GridItemTextField
          name={'address2'}
          label="住所2（建物など)"
          value={data.address2}
          handleChangeValues={(val: string) =>
            handleChangeValues(val, 'address2', setData)
          }
          size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}
        />
      </Grid>
    </FlexColumnBox>
  );
};

interface ActionsProps {
  person: tPerson;
  cbs?: () => void;
  cbe?: (error: any) => void;
}

const Actions = (props: ActionsProps) => {
  return (
    <>
      {!props.person.id ? (
        <ApiBtn.Store person={props.person} cbs={props.cbs} cbe={props.cbe} />
      ) : (
        <>
          <ApiBtn.Update
            person={props.person}
            cbs={props.cbs}
            cbe={props.cbe}
          />
          <ApiBtn.Delete id={props.person.id} cbs={props.cbs} cbe={props.cbe} />
        </>
      )}
      <SecondButton label="閉じる" onClick={props.cbs} />
    </>
  );
};
