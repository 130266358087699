import { waypointKbnLoad, waypointKbnUnload } from 'const/index';
import * as PJConst from 'const/project';
import { convertLocalDateToUTC } from 'functions/time';
import { tAddress } from 'types/address';
import { tProjectKind, tUser } from 'types/mst';
import { tTransport } from 'types/transport';
import { tCompany } from './company';
import { tInstruction } from './instruction';

export type tWaypoint = {
  kbn: number; // 種別
  datetime: string; // 日時
  a_id?: tAddress['id']; // 住所ID
  a_name: tAddress['name']; // 住所名
  abbreviation1: tAddress['abbreviation1']; // 略称1
  abbreviation2: tAddress['abbreviation1']; // 略称2
  prefectures: tAddress['prefectures']; // 都道府県
  city: tAddress['city']; // 市区町村
  street: tAddress['street']; // 番地
  building: tAddress['street']; // 建屋
  post_number: tAddress['post_number']; // 郵便番号
  memo?: string; // メモ
};
export type tWaypoints = tWaypoint[];

export type tProject = {
  id?: number; // ID

  status: number; // ステータス
  kind: number; // 種別
  c_id?: number; // 会社マスタID
  p_id?: number; // 担当者ID
  load_datetime: string; // 積：日時
  load_a_id: number | null; // 積：住所ID
  load_abbreviation1: tAddress['abbreviation1']; // 略称1
  load_abbreviation2: tAddress['abbreviation1']; // 略称2
  load_name: tAddress['name']; // 積：名称
  load_prefectures: tAddress['prefectures']; // 積：都道府県
  load_city: tAddress['city']; // 積：市区町村
  load_street: tAddress['street']; // 積：番地
  load_building: tAddress['building']; // 積：建屋
  load_post_number: tAddress['post_number']; // 積：郵便番号
  load_tell: tAddress['tell']; // 積：電話番号
  load_fax?: tAddress['fax']; // 積：FAX
  waypoints: tWaypoints; // 経由地点
  unload_datetime: string; // 卸：日時
  unload_a_id: number | null; // 卸：住所ID
  unload_name: tAddress['name']; // 卸：名称
  unload_abbreviation1: tAddress['abbreviation1']; // 略称1
  unload_abbreviation2: tAddress['abbreviation1']; // 略称2
  unload_prefectures: tAddress['prefectures']; // 卸：都道府県
  unload_city: tAddress['city']; // 卸：市区町村
  unload_street: tAddress['street']; // 卸：番地
  unload_building: tAddress['building']; // 卸：建屋
  unload_post_number: tAddress['post_number']; // 卸：郵便番号
  unload_tell: tAddress['tell']; // 卸：電話番号
  unload_fax?: tAddress['fax']; // 卸：FAX
  vt_id: number | null; // 車種ID（車種マスタID）
  cars: number; // 台数
  weight: number; // 重量
  l_id: number | null; // 荷物種類ID（荷物種類マスタID）
  l_name: string; // 荷物種類名
  l_volume: number; // 数量
  memo: string; // 荷物メモ
  price_unit_reference: number; // 参考単価
  internal_memo: string; // 内部メモ
  v_id: number | null; // 車両ID（車両マスタID）
  vehicle_name: string; // 車両名
  trailer_v_id: number | null; // トレーラー車両ID（車両マスタID）
  trailer_name: string; // トレーラー車両名

  // 以下、新たに追加
  transports?: tTransport[]; // 新たに追加
  company?: tCompany; // 新たに追加
  load_address?: tAddress; // 新たに追加
  unload_address?: tAddress; // 新たに追加
  project_kind?: tProjectKind; // 新たに追加

  // 2024/08/15追加
  in_charge: number | null; // 担当者ID

  price_details?: tProjectPriceDetail[]; // 金額明細
  price_sum_detail?: number;
  price_tax_sum_detail?: number;
  price_sum_separate?: number;
  price_tax_sum_separate?: number;
  price_sum_expenses?: number;
  price_sum_other?: number;
  price_tax_sum_other?: number;
};

export const initialWaypoint: tWaypoint = {
  kbn: waypointKbnLoad.id,
  datetime: convertLocalDateToUTC(new Date().toDateString()).toISOString(),
  a_id: undefined,
  a_name: '',
  abbreviation1: '',
  abbreviation2: '',
  prefectures: '',
  city: '',
  street: '',
  building: '',
  post_number: '',
  memo: '',
};
export const initWaypoints: tWaypoints = [
  { ...initialWaypoint, kbn: waypointKbnLoad.id },
  { ...initialWaypoint, kbn: waypointKbnLoad.id },
  { ...initialWaypoint, kbn: waypointKbnUnload.id },
  { ...initialWaypoint, kbn: waypointKbnUnload.id },
];

/**
 * 初期値を生成する
 * @returns
 */
export const initProject: tProject = {
  id: undefined,

  status: PJConst.projectStatusOrdered.id || 0,
  kind: 0,
  c_id: undefined,
  p_id: undefined,
  load_datetime: new Date().toISOString(),
  load_a_id: null,
  load_name: '',
  load_abbreviation1: '',
  load_abbreviation2: '',
  load_prefectures: '',
  load_city: '',
  load_street: '',
  load_building: '',
  load_post_number: '',
  load_tell: '',
  load_fax: undefined,
  waypoints: initWaypoints,
  unload_datetime: new Date().toISOString(),
  unload_a_id: null,
  unload_name: '',
  unload_abbreviation1: '',
  unload_abbreviation2: '',
  unload_prefectures: '',
  unload_city: '',
  unload_street: '',
  unload_building: '',
  unload_post_number: '',
  unload_tell: '',
  unload_fax: undefined,
  vt_id: null,
  cars: 1,
  weight: 0.0,
  l_id: null,
  l_name: '',
  l_volume: 0,
  memo: '',
  price_unit_reference: 0,
  internal_memo: '',
  in_charge: null,
  v_id: null,
  vehicle_name: '',
  trailer_v_id: null,
  trailer_name: '',
  /*
    transports: [],
    company: {},
    load_address: {},
    unload_address: {},
    project_kind: {},
    */
};
export type tProjectCard = {
  pj_id: number;
  company_name: tCompany['name'];
  company_abbreviation: tCompany['abbreviation'];

  load_datetime: tProject['load_datetime'];
  load_id: tProject['load_a_id'];
  load_name: tProject['load_name'];
  load_abbreviation1: tProject['load_abbreviation1'];
  load_abbreviation2: tProject['load_abbreviation2'];

  unload_datetime: tProject['unload_datetime'];
  unload_id: tProject['unload_a_id'];
  unload_name: tProject['unload_name'];
  unload_abbreviation1: tProject['load_abbreviation1'];
  unload_abbreviation2: tProject['load_abbreviation2'];

  cars: tProject['cars'];
  waypoint_count: number;
  status: number;
  kind: number;
  kind_name: string;

  tmp_instructions: tInstruction[];
  instructions: tInstruction[];
};

export type tProjectCardList = tProjectCard[];

export type tPriceOther = { label: string; value: number }; // その他の金額

export type tProjectSearch = {
  status: tProject['status'][];
  load_datetime_from: tProject['load_datetime'] | null;
  load_datetime_to: tProject['load_datetime'] | null;
  unload_datetime_from: tProject['unload_datetime'] | null;
  unload_datetime_to: tProject['unload_datetime'] | null;
  c_id: tCompany['id'] | null;
  kind: tProject['kind'] | null;
};

export type tPjMemo = {
  created_at?: string;
  id?: number;
  c_id?: tCompany['id'];
  memo: string;
  flg_pj: boolean;
};

export type tPjMemoSearch = {
  c_id?: tCompany['id'] | null;
  flg_pj?: boolean | null;
  insert_user_id?: tUser['id'] | null;
};

/**
 * 案件金額明細
 */
export type tProjectPriceDetail = {
  id?: number; // 主キー
  pj_id?: number; // 案件ID

  name: string; // 名称
  kbn: number; // 区分
  price_unit: number; // 単価
  quantity: number; // 数量
  price: number; // 金額（税抜）
  tax_id: number | null; // 消費税マスタID
  price_tax: number; // 金額（税込）
  memo: string; // メモ
};
