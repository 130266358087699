import { Grid, Typography } from '@mui/material';
import { FlexColumnBox } from 'atoms/Box';
import * as CustomGird from 'atoms/Grid';
import * as BtnAddress from 'components/address/Button';
import { GridItemCheckbox, GridItemTextField } from 'components/GridItem';
import * as TexiItems from 'components/input/Text';
import * as ComponentAddress from 'components/mst/Address';
import { initAddress } from 'const/address';
import ContentsFrame from 'frames/ContentsFrame';
import { Console_log } from 'functions';
import { getAddress } from 'functions/api/mst';
import { handleChangeValues } from 'functions/handles';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { tAddress } from 'types/address';

export default function Main() {
  const { id } = useParams();
  const [address, setAddress] = useState<tAddress>(initAddress);

  Console_log('id', id);

  useEffect(() => {
    const fetchData = async (id: number) => {
      try {
        // 住所データ取得
        getAddress(id).then((res) => {
          if (res.status !== 200)
            throw new Error('住所データ取得に失敗しました');
          Console_log('addresses.data', res.data);
          setAddress(res.data);
        });
      } catch (error) {
        alert('住所データ取得に失敗しました');
        console.error(error);
      }
    };
    if (id) {
      fetchData(Number(id));
    }
  }, [id]);

  return (
    <ContentsFrame
      HeadContent={<HeadContent id={id} values={address} />}
      MainContent={<Details values={address} setValues={setAddress} />}
      SubContent={<></>}
    />
  );
}

interface HeadContentProps {
  id: string | undefined;
  values: tAddress;
}
const HeadContent = ({ id, values }: HeadContentProps) => {
  const navigate = useNavigate();
  return (
    <>
      {id ? (
        <>
          <BtnAddress.Update data={values} />
          <BtnAddress.Delete
            id={values.id}
            cbs={() => navigate('mst/address/')}
          />
        </>
      ) : (
        <>
          <BtnAddress.Store
            data={values}
            cbs={(data: tAddress) => navigate('mst/address/edit' + data.id)}
          />
        </>
      )}
    </>
  );
};

interface DetailsProps {
  values: tAddress;
  setValues: React.Dispatch<React.SetStateAction<tAddress>>;
}
const Details = ({ values, setValues }: DetailsProps) => {
  const { id } = useParams();
  return (
    <FlexColumnBox>
      {id && (
        <Typography variant="h4">
          ID:
          <Typography component={'span'} variant="h4" sx={{ fontWeight: 700 }}>
            {values.id}
          </Typography>
        </Typography>
      )}
      <Grid container spacing={2}>
        <GridItemTextField
          name={'name'}
          label="名称"
          value={values.name}
          handleChangeValues={(val: string) =>
            handleChangeValues(val, 'name', setValues)
          }
        />

        <CustomGird.GridItem size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
          <TexiItems.Main
            label={'略称1'}
            name={'abbreviation1'}
            value={values.abbreviation1}
            cbValueChange={(val: string) =>
              handleChangeValues(val, 'abbreviation1', setValues)
            }
          />
        </CustomGird.GridItem>
        <CustomGird.GridItem size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
          <TexiItems.Main
            label={'略称2'}
            name={'abbreviation2'}
            value={values.abbreviation2}
            cbValueChange={(val: string) =>
              handleChangeValues(val, 'abbreviation2', setValues)
            }
          />
        </CustomGird.GridItem>

        <GridItemTextField
          name={'post_number'}
          label="郵便番号"
          value={values.post_number}
          handleChangeValues={(val: string) =>
            handleChangeValues(val, 'post_number', setValues)
          }
        />
        <Grid item xs={12}></Grid>

        <ComponentAddress.Prefectures values={values} setValues={setValues} />
        <ComponentAddress.City values={values} setValues={setValues} />
        <ComponentAddress.Street values={values} setValues={setValues} />
        <ComponentAddress.Building values={values} setValues={setValues} />

        <Grid item xs={12}></Grid>

        <GridItemTextField
          name={'tell'}
          label="電話番号"
          value={values.tell}
          handleChangeValues={(val: string) =>
            handleChangeValues(val, 'tell', setValues)
          }
        />

        <GridItemTextField
          name={'fax'}
          label="FAX"
          value={values.fax}
          handleChangeValues={(val: string) =>
            handleChangeValues(val, 'fax', setValues)
          }
        />

        <Grid item xs={12}></Grid>

        <GridItemCheckbox
          size={{ xs: 4, sm: 3, md: 2, lg: 1, xl: 1 }}
          label="積地"
          name={'flg_load'}
          value={values.flg_load}
          handleChangeValues={(val: boolean) =>
            handleChangeValues(val, 'flg_load', setValues)
          }
        />
        <GridItemCheckbox
          size={{ xs: 4, sm: 3, md: 2, lg: 1, xl: 1 }}
          label="卸地"
          name={'flg_unload'}
          value={values.flg_unload}
          handleChangeValues={(val: boolean) =>
            handleChangeValues(val, 'flg_unload', setValues)
          }
        />
        <GridItemCheckbox
          size={{ xs: 4, sm: 3, md: 2, lg: 1, xl: 1 }}
          label="事務所"
          name={'flg_office'}
          value={values.flg_office}
          handleChangeValues={(val: boolean) =>
            handleChangeValues(val, 'flg_office', setValues)
          }
        />
      </Grid>
    </FlexColumnBox>
  );
};
