import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button } from '@mui/material';
import Fab from '@mui/material/Fab';
import Link from '@mui/material/Link';
import { styled } from '@mui/system';
import React from 'react';

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'right',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  '& button': {
    minWidth: '120px',
  },
}));

interface PrimaryButtonProps {
  onClick?: () => void;
  label: string;
  disabled?: boolean;
  className?: string[];
  href?: string;
  aTab?: boolean;
}

export function PrimaryButton({
  onClick,
  label,
  disabled = false,
  className = [],
  ...props
}: PrimaryButtonProps) {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      className={className.join(' ')}
      disabled={disabled}
      {...props}
    >
      {label}
    </Button>
  );
}

export function SecondButton({
  onClick,
  label,
  disabled = false,
  className = [],
  ...props
}: PrimaryButtonProps) {
  return (
    <Button
      {...props}
      disabled={disabled}
      variant="contained"
      color="secondary"
      onClick={onClick}
      className={className.join(' ')}
    >
      {label}
    </Button>
  );
}

export function DeleteButton({
  onClick,
  label,
  disabled = false,
  className = [],
  ...props
}: PrimaryButtonProps) {
  return (
    <Button
      {...props}
      disabled={disabled}
      variant="contained"
      color="warning"
      onClick={(e) => {
        if (onClick) {
          if (
            window.confirm(
              'この処理は取り消すことが出来ません。本当に削除しますか？'
            )
          ) {
            onClick();
          }
        }
      }}
      className={className.join(' ')}
    >
      {label}
    </Button>
  );
}

export function PlusButton({
  onClick,
  props,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  props?: any;
}) {
  return (
    <Fab color="primary" onClick={onClick} {...props}>
      <AddIcon />
    </Fab>
  );
}

export function MinusButton({
  onClick,
  props,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  props?: any;
}) {
  return (
    <Fab color="secondary" onClick={onClick} {...props}>
      <RemoveIcon />
    </Fab>
  );
}

export function LinkPrimaryButton({
  href,
  aTab = false,
  label,
}: PrimaryButtonProps) {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (aTab && href) {
      e.preventDefault();
      window.location.href = href;
    }
  };

  return (
    <Button
      variant="contained"
      component={Link}
      color="primary"
      href={href}
      onClick={handleClick}
    >
      {label}
    </Button>
  );
}
