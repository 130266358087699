import * as CustomGrid from 'atoms/Grid';
import Loading from 'atoms/Loading';
import {
  GridItemCheckbox,
  GridItemDateField,
  GridItemNumberField,
  GridItemTextField,
} from 'components/GridItem';
import * as AutocompleteItem from 'components/input/AutoComplete';
import * as SelectItems from 'components/input/Select';
import { NoSelect } from 'const';
import { MstDataContext } from 'contexts/Mst';
import { handleChangeValues } from 'functions/handles';
import { strTimeOrigin } from 'functions/time';
import React, { useContext, useEffect } from 'react';
import {
  ItemSelectProps,
  ItemTextFieldProps,
  List,
  ListItem,
} from 'types/index';
import { tInstruction } from 'types/instruction';

interface GridItemFieldProps {
  name?: string;
  values: tInstruction;
  setValues: React.Dispatch<React.SetStateAction<tInstruction>>;
  size?: Record<string, number>;
}

export const SelectMethods = React.memo(
  ({ values, setValues, size = undefined }: GridItemFieldProps) => {
    return (
      <CustomGrid.GridItem size={size}>
        <SelectItems.TransportMethoed
          value={values.tm_id || null}
          cbValueChange={(val) => {
            handleChangeValues(val, 'tm_id', setValues);
          }}
        />
      </CustomGrid.GridItem>
    );
  }
);

/**
 * ドライバー選択
 * ※実業務では車両を選択するが、本システムではドライバーを選択することで
 * その日ドライバーにも付いている車両を選択する
 * @param param0
 * @returns
 */
export const Driver = React.memo(
  ({ values, setValues }: GridItemFieldProps) => {
    const { loading, users, drivers } = useContext(MstDataContext);

    /**
     * ドライバーが変更された場合の処理
     */
    useEffect(() => {
      if (!users || values.user_id) {
        return;
      }

      const dv = users.find((user) => user.id === values.user_id);
      if (dv) {
        setValues({
          ...values,
          v_id: dv.v_id,
        });
      }
    }, [values.user_id, users]);

    //log.debug("selectedDriver", selectedDriver);

    if (loading || !drivers) {
      return <Loading flg={true} />;
    }

    return (
      <>
        <CustomGrid.GridItem size={{ xs: 12, sm: 4, md: 3, lg: 2, xl: 2 }}>
          <SelectItems.Driver
            name={'user_id'}
            value={values.user_id || null}
            cbValueChange={(val) => {
              handleChangeValues(val, 'user_id', setValues);
            }}
          />
        </CustomGrid.GridItem>
        <CustomGrid.GridItem size={{ xs: 12, sm: 4, md: 3, lg: 2, xl: 2 }}>
          <SelectItems.DriverHead
            value={values.v_id || null}
            size={{ xs: 12, sm: 4, md: 3, lg: 2, xl: 2 }}
            cbValueChange={(val) => {
              handleChangeValues(val, 'v_id', setValues);
            }}
          />
        </CustomGrid.GridItem>
      </>
    );
  }
);

/**
 * シャーシ選択
 * @param param0
 * @returns
 */
export const Trailer = React.memo(
  ({
    label = '自社シャーシ',
    name,
    value,
    size,
    cbValueChange,
  }: ItemSelectProps) => {
    const { loading, trailer } = useContext(MstDataContext);

    if (!size) {
      size = { xs: 12, sm: 4, md: 3, lg: 2, xl: 2 };
    }

    const selected = trailer?.find((obj) => obj.id === value);

    const [options, setOptions] = React.useState<List>([]);

    useEffect(() => {
      if (trailer) {
        const trailers = trailer.map((obj) => ({
          id: obj.id || null,
          label: obj.plate,
        }));
        setOptions([NoSelect, ...trailers]);
      }
    }, [trailer]);

    //log.debug("selectedDriver", selectedDriver);

    if (loading || !trailer) {
      return <Loading flg={true} />;
    }

    return (
      <CustomGrid.GridItem size={size}>
        <SelectItems.Trailer
          name={name}
          label={label}
          value={selected ? Number(selected.id) : 0}
          items={options}
          cbValueChange={cbValueChange}
        />
      </CustomGrid.GridItem>
    );
  }
);

/**
 * シャーシメモ
 * @param param0
 * @returns
 */
export const TrailerMemo = React.memo(
  ({
    label = 'シャーシ備考',
    name = '',
    value,
    size,
    cbValueChange,
  }: ItemTextFieldProps) => {
    if (!size) {
      size = { xs: 12, sm: 8, md: 9, lg: 4, xl: 4 };
    }

    return (
      <GridItemTextField
        label={label}
        value={value}
        name={name}
        handleChangeValues={(val: string) => cbValueChange(val)}
        size={size}
      />
    );
  }
);

/**
 * 発日時
 * @param param0
 * @returns
 */
export const StartDatetime = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemDateField
    label="発日"
    name="start_datetime"
    value={values.start_datetime}
    handleChangeValues={(val: string) => {
      const strDatetime = `${val} ${strTimeOrigin(values.start_datetime)}`;
      // 入力日のに設定
      const inputDateTime = new Date(strDatetime);
      if (inputDateTime.toString() === 'Invalid Date') {
        return;
      }
      handleChangeValues(strDatetime, 'start_datetime', setValues);
    }}
  />
);

/**
 * 着日時
 * @param param0
 * @returns
 */
export const EndDatetime = ({ values, setValues }: GridItemFieldProps) => {
  return (
    <GridItemDateField
      label="着日"
      name="end_datetime"
      value={values.end_datetime}
      handleChangeValues={(val: string) => {
        const strDatetime = `${val} ${strTimeOrigin(values.end_datetime)}`;
        // 入力日のに設定
        const inputDateTime = new Date(strDatetime);
        if (inputDateTime.toString() === 'Invalid Date') {
          return;
        }
        handleChangeValues(strDatetime, 'end_datetime', setValues);
      }}
    />
  );
};

/**
 * 会社選択
 * @param param0
 * @returns
 */
export const ClientCompany = ({
  values,
  setValues,
  size = { xs: 12, lg: 3, xl: 3 },
}: GridItemFieldProps) => (
  <CustomGrid.GridItem size={size}>
    <AutocompleteItem.ClientCompany
      label={'取引先'}
      value={{ id: values.c_id || null, label: '' }}
      cbValueChange={(val: ListItem) => {
        handleChangeValues(val.id, 'c_id', setValues);
      }}
    />
  </CustomGrid.GridItem>
);

/**
 * 傭車先車両情報
 * @param param0
 * @returns
 */
export const ClientVehicle = ({
  values,
  setValues,
  size = { xs: 12, lg: 3, xl: 3 },
}: GridItemFieldProps) => (
  <GridItemTextField
    name="c_start_vehicle"
    label="車両情報"
    value={values.c_vehicle || ''}
    size={size}
    handleChangeValues={(val: string) => {
      handleChangeValues(val, 'c_vehicle', setValues);
    }}
  />
);

/**
 * 傭車先料金
 * @param param0
 * @returns
 */
export const ClientPrice = ({
  values,
  setValues,
  size = { xs: 12, lg: 3, xl: 3 },
}: GridItemFieldProps) => {
  return (
    <GridItemNumberField
      name="price"
      label="金額"
      value={values.price || 0}
      size={size}
      cbHandleChange={(val: number) => {
        handleChangeValues(val, 'price', setValues);
      }}
    />
  );
};

/**
 * ピックアップ運行フラグ 手当に使用する
 * @param param0
 * @returns
 */
export const PickUp = ({
  values,
  setValues,
  size = { xs: 12, lg: 3, xl: 3 },
}: GridItemFieldProps) => {
  return (
    <GridItemCheckbox
      name="flg_picup"
      label="ピックアップ運行"
      value={values.flg_picup}
      size={size}
      handleChangeValues={(val: boolean) => {
        handleChangeValues(val, 'flg_picup', setValues);
      }}
    />
  );
};
