import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { formatNumber, toHalfWidth } from 'functions/index';
import log from 'functions/logger';
import React, { FocusEvent, KeyboardEvent, useEffect, useState } from 'react';
import { ListItem } from 'types/index';

interface SmallTextFieldProps {
  label?: string;
  props: TextFieldProps; // ここでpropsを定義します
}

const Master: React.FC<TextFieldProps> = (props) => {
  return <TextField fullWidth {...props} />;
};

export const SmallTextField: React.FC<SmallTextFieldProps> = ({
  label,
  props,
}) => {
  return (
    <>
      {label && <Typography variant="h6">{label}</Typography>}
      <Master {...props} size="small" hiddenLabel />
    </>
  );
};

export const TableCellTextField: React.FC<SmallTextFieldProps> = ({
  label = undefined,
  props,
}) => {
  return (
    <>
      {label && <Typography variant="h6">{label}</Typography>}
      <Master {...props} size="small" hiddenLabel />
    </>
  );
};

interface NumberTextFieldProps {
  name: string;
  label: string;
  value: number;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  callback?: (val: number) => void;
  //handleChangeValues: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  size?: Record<string, number> | null;
  flgFloat?: boolean;
  disabled?: boolean;
}
export const NumberTextField: React.FC<NumberTextFieldProps> = ({
  name,
  label,
  value,
  setValues,
  callback,
  flgFloat = false,
  disabled = false,
}) => {
  const [viewVal, setViewVal] = useState<string>('');

  const changeNumber = (val: string): string => {
    val = toHalfWidth(val); // 全角を半角に変換

    if (flgFloat) {
      // 数値とドット以外の文字を削除
      val = val.replace(/[^0-9.]/g, '');

      // 小数点が複数ある場合、最初の1つだけ残す
      const dotIndex = val.indexOf('.');
      if (dotIndex !== -1) {
        val =
          val.slice(0, dotIndex + 1) +
          val.slice(dotIndex + 1).replace(/\./g, '');
      }
    } else {
      // 整数モード: 数値以外の文字を削除
      val = val.split('.')[0].replace(/[^0-9]/g, '');
    }

    // 数値に変換できない場合、空文字を返す
    if (!isNaN(Number(val))) {
      return val;
    } else {
      return '';
    }
  };

  const changeFormatNumber = (numStr: string): void => {
    const valNum = changeNumber(numStr);

    if (callback) {
      callback(Number(valNum));
    } else {
      setValues((prev: any) => {
        return { ...prev, [name]: valNum };
      });
    }

    // 小数点以下2桁にフォーマットして表示
    setViewVal(
      formatNumber(
        flgFloat ? parseFloat(valNum).toFixed(2) : valNum.split('.')[0]
      )
    );
  };

  useEffect(() => {
    // 初期値をフォーマットして表示
    setViewVal(
      formatNumber(
        flgFloat
          ? parseFloat(value.toString()).toFixed(2)
          : value.toString().split('.')[0]
      )
    );
  }, [value]);

  return (
    <SmallTextField
      label={label}
      props={{
        name: name,
        value: viewVal,
        disabled: disabled,
        onBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          changeFormatNumber(e.target.value);
        },
        onFocus: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setViewVal(changeNumber(e.target.value));
        },
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          setViewVal(e.target.value);
        },
        onKeyPress: (e: KeyboardEvent<HTMLInputElement>) => {
          const char = e.key;
          if (char === 'Enter') {
            const temp = e as unknown as FocusEvent<
              HTMLInputElement | HTMLTextAreaElement
            >;
            // フォーカスを外す
            temp.target.blur();
          }
        },
      }}
    />
  );
};

interface SelectTextFieldProps {
  label?: string;
  items: Record<string, string>[];
  selectedValue: string;
  props: TextFieldProps;
}
export const SelectTextField = ({
  label,
  items,
  selectedValue,
  props,
}: SelectTextFieldProps) => {
  return (
    <>
      {label && <Typography variant="h6">{label}</Typography>}
      <TextField
        select
        value={selectedValue}
        variant="outlined"
        fullWidth
        {...props}
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

interface AutocompleteTextFieldProps {
  freeSolo?: boolean;
  disableClearable?: boolean;
  label: string;
  options: Record<string, any>[];
  value: Record<string, any> | null;
  inputValue?: string;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    newValue: ListItem
  ) => void;
}
export const AutocompleteTextField = ({
  freeSolo = false,
  disableClearable = false,
  label,
  options,
  value,
  inputValue = '',
  onChange,
}: AutocompleteTextFieldProps) => {
  if (!inputValue) {
    inputValue = value ? value.label : '';
  }
  return (
    <>
      {label && <Typography variant="h6">{label}</Typography>}
      <Autocomplete
        autoSelect={true}
        fullWidth={true}
        freeSolo={freeSolo}
        disableClearable={disableClearable}
        sx={{ padding: 0 }}
        getOptionLabel={(option) =>
          option.label ? String(option.label) : String(option.name)
        }
        value={value}
        inputValue={inputValue}
        onChange={(e, newValue) => {
          /* 2025-01-07 案件入力の積卸地のバグ対応時にコメントアウト
          log.debug('onChange', newValue);
          onChange(e, newValue);
          */
        }}
        onInputChange={(e, newInputValue) => {
          log.debug('onInputChange', newInputValue);
          if (newInputValue === 'undefined' || !newInputValue) {
            // クリアボタンが押されたとき
            if (e?.type === 'click') {
              newInputValue = ''; // クリアボタンクリック時の処理
            } else {
              const html = e.target as HTMLInputElement;
              // フォーカスが外れた時などの処理
              newInputValue = html.value;
            }
          }
          onChange(e, { id: 0, label: newInputValue } as ListItem);
        }}
        options={options}
        renderInput={(params) => (
          <SmallTextField label={undefined} props={params} />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        )}
        limitTags={5} // 最大表示数を5件に制限
      />
    </>
  );
};

interface RowTextFieldProps {
  name: string;
  label: string;
  value: string;
  handleChangeValues: (event: React.ChangeEvent<HTMLInputElement>) => void;
  row?: number;
}
export function RowTextField({
  name,
  label,
  value,
  handleChangeValues,
  row = 4,
}: RowTextFieldProps) {
  return (
    <SmallTextField
      label={label}
      props={{
        name: name,
        value: value,
        onChange: handleChangeValues,
        multiline: true,
        rows: row,
      }}
    />
  );
}

interface NumberProps {
  name?: string;
  label?: string;
  value: number | undefined;
  cbHandleChangeValues?: (val: number) => void;
  setValues?: React.Dispatch<React.SetStateAction<any>>;
  //handleChangeValues: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  size?: Record<string, number> | null;
  flgFloat?: boolean;
  disabled?: boolean;
}
export function NumberField({
  name = '',
  label = '',
  value,
  cbHandleChangeValues,
  setValues,
  flgFloat = false,
  disabled = false,
}: NumberProps) {
  const [viewVal, setViewVal] = useState<string>('');

  const changeNumber = (val: string): string => {
    val = toHalfWidth(val); // 全角を半角に変換

    if (flgFloat) {
      // 数値とドット以外の文字を削除
      val = val.replace(/[^0-9.]/g, '');

      // 小数点が複数ある場合、最初の1つだけ残す
      const dotIndex = val.indexOf('.');
      if (dotIndex !== -1) {
        val =
          val.slice(0, dotIndex + 1) +
          val.slice(dotIndex + 1).replace(/\./g, '');
      }
    } else {
      // 整数モード: 数値以外の文字を削除
      val = val.split('.')[0].replace(/[^0-9]/g, '');
    }

    // 数値に変換できない場合、空文字を返す
    if (!isNaN(Number(val))) {
      return val;
    } else {
      return '';
    }
  };

  const changeFormatNumber = (numStr: string): void => {
    const valNum = changeNumber(numStr);

    /**
     * コールバック関数がある場合はコールバック関数を実行
     * ない場合はsetValuesを実行
     */
    if (cbHandleChangeValues) {
      cbHandleChangeValues(Number(valNum));
    } else if (setValues) {
      setValues((prev: any) => {
        return { ...prev, [name]: valNum };
      });
    }

    // 小数点以下2桁にフォーマットして表示
    setViewVal(
      formatNumber(
        flgFloat ? parseFloat(valNum).toFixed(2) : valNum.split('.')[0]
      )
    );
  };

  useEffect(() => {
    // 初期値をフォーマットして表示
    setViewVal(
      value
        ? formatNumber(
            flgFloat
              ? parseFloat(value.toString()).toFixed(2)
              : value.toString().split('.')[0]
          )
        : ''
    );
  }, [value]);

  return (
    <SmallTextField
      label={label}
      props={{
        name: name,
        value: viewVal,
        disabled: disabled,
        onBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          changeFormatNumber(e.target.value);
        },
        onFocus: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setViewVal(changeNumber(e.target.value));
        },
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          setViewVal(e.target.value);
        },
        onKeyPress: (e: KeyboardEvent<HTMLInputElement>) => {
          const char = e.key;
          if (char === 'Enter') {
            const temp = e as unknown as FocusEvent<
              HTMLInputElement | HTMLTextAreaElement
            >;
            // フォーカスを外す
            temp.target.blur();
          }
        },
      }}
    />
  );
}
