import { Grid, Typography } from '@mui/material';
import { FlexColumnBox } from 'atoms/Box';
import * as CustomGrid from 'atoms/Grid';
import { GridItemTextField } from 'components/GridItem';
import * as TextItems from 'components/input/Text';
import * as BtnLuggage from 'components/luggage/Button';
import ContentsFrame from 'frames/ContentsFrame';
import { getLuggage } from 'functions/api/mst';
import { handleChangeValues } from 'functions/handles';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { tLuggage } from 'types/mst';

export default function Main() {
  const { id } = useParams();
  const [luggage, setLuggage] = useState<tLuggage>({
    id: undefined,
    name: '',
    unit: '',
    volume: 1,
    cm_ids: [],
  });

  useEffect(() => {
    const fetchData = async (id: number) => {
      try {
        //await getCsrf();
        getLuggage(id)
          .then((res) => {
            if (res.status !== 200) throw new Error('データ取得に失敗しました');
            if (res.data.volume === null || res.data.volume < 1)
              res.data.volume = 1;
            setLuggage(res.data);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (error) {
        console.error(error);
      }
    };
    if (id) {
      fetchData(Number(id));
    }
  }, [id]);

  return (
    <ContentsFrame
      HeadContent={<HeadContent id={id} values={luggage} />}
      MainContent={<Details values={luggage} setValues={setLuggage} />}
      SubContent={<></>}
    />
  );
}

interface HeadContentProps {
  id: string | undefined;
  values: tLuggage;
}
const HeadContent = ({ id, values }: HeadContentProps) => {
  const navigate = useNavigate();
  return (
    <>
      {id ? (
        <>
          <BtnLuggage.Update data={values} />
          <BtnLuggage.Delete id={id as unknown as number} />
        </>
      ) : (
        <>
          <BtnLuggage.Store
            data={values}
            cbs={(data: tLuggage) => navigate('/mst/luggage/edit/' + data.id)}
          />
        </>
      )}
    </>
  );
};

interface DetailsProps {
  values: tLuggage;
  setValues: React.Dispatch<React.SetStateAction<tLuggage>>;
}
const Details = ({ values, setValues }: DetailsProps) => {
  const { id } = useParams();
  return (
    <FlexColumnBox>
      {id && (
        <Typography variant="h4">
          ID:
          <Typography component={'span'} variant="h4" sx={{ fontWeight: 700 }}>
            {values.id}
          </Typography>
        </Typography>
      )}
      <Grid container spacing={2}>
        <CustomGrid.GridItem>
          <TextItems.Name
            value={values.name}
            cbValueChange={(val: string) => {
              handleChangeValues(val, 'name', setValues);
            }}
          />
        </CustomGrid.GridItem>

        <GridItemTextField
          name={'unit'}
          label="単位"
          value={values.unit}
          handleChangeValues={(val: string) =>
            handleChangeValues(val, 'unit', setValues)
          }
        />
        {/*
        <GridItemTextField
          name={'volume'}
          label="容量"
          value={values.volume}
          handleChangeValues={(val: string) =>
            handleChangeValues(val, 'volume', setValues)
          }
        />
        */}
      </Grid>
    </FlexColumnBox>
  );
};
