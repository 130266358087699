import * as GridItem from 'atoms/Grid';
import * as TextItems from 'components/input/Text';
import React from 'react';
import { ItemTextFieldProps } from 'types/index';

/**
 * コンテナ番号
 */
export const ContainerCode = React.memo(
  ({ value, size, cbValueChange }: ItemTextFieldProps) => {
    if (!size) {
      size = { xs: 12, sm: 8, md: 9, lg: 4, xl: 4 };
    }

    return (
      <GridItem.GridItem size={size}>
        <TextItems.ContainerCode
          label="コンテナ番号"
          name="container_code"
          value={value}
          size={size}
          cbValueChange={cbValueChange}
        />
      </GridItem.GridItem>
    );
  }
);
