import { Box, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

interface MainProps {
  HeadContent: React.ReactNode;
  MainContent: React.ReactNode;
  SubContent: React.ReactNode;
}
const Main = ({ HeadContent: Buttons, MainContent, SubContent }: MainProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          flexGrow: 1, // 残りの高さを占める
          transition: 'height 0.3s ease',
          overflow: 'auto', // 高さが足りない時にスクロールバーを表示
        }}
      >
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              height: 'auto',
              ClassName: 'buttons',
              display: 'flex',
              justifyContent: 'flex-end',
              gap: theme.spacing(2),
              p: theme.spacing(2),
              position: 'sticky',
              top: 0,
              zIndex: 1,
              left: 0,
            }}
          >
            {Buttons}
          </Box>
          <Box
            sx={{
              flexGrow: 1, // 残りの高さを占める
              transition: 'height 0.3s ease',
              overflow: 'auto', // 高さが足りない時にスクロールバーを表示
              p: theme.spacing(1),
            }}
          >
            {MainContent}
          </Box>
        </Box>
      </Box>
      <Container
        sx={{
          height: 'auto',
          backgroundColor: theme.palette.common.white,
          transition: 'height 0.3s ease',
          //overflow: "hidden", // 高さが0の時にコンテンツが見えないように
        }}
      >
        {SubContent}
      </Container>
    </Box>
  );
};

export default Main;
