import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FlexBox, FlexColumnBox } from 'atoms/Box';
import PDFModal from 'components/invoice/PDFModal';
import PageNation from 'components/Pagenation';
import { getInvocies } from 'functions/api/paperwork';
import React, { useEffect, useState } from 'react';
import { tInvoice } from 'types/invoice';

export default function Main() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [invoices, setInvoices] = useState<tInvoice[]>([]);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<tInvoice['id']>();
  const [pdfOpen, setPdfOpen] = useState(false);
  const [invoice, setInvoice] = useState<tInvoice | null>(null);

  const getSearch = async (page: number) => {
    interface ApiResponse {
      data: {
        data: tInvoice[];
        current_page: number;
        last_page: number;
      };
      status: number;
    }

    getInvocies(page, [], [])
      .then((res: ApiResponse) => {
        if (res.status !== 200) throw new Error('error');
        setInvoices(res.data.data);
        setCurrentPage(res.data.current_page);
        setTotalPages(res.data.last_page);
      })
      .catch(() => {
        alert('データの取得に失敗しました。');
      });
  };

  const handleInvoiceDoubleClick = (invoice_id: tInvoice['id']) => {
    setSelectedInvoiceId(invoice_id);
    setPdfOpen(true);
    setInvoice(invoices.find((invoice) => invoice.id === invoice_id) || null);
  };

  useEffect(() => {
    getSearch(currentPage);
  }, []);

  let sheetName = '';
  if (invoice) {
    sheetName = `請求書_${invoice.invoice_number}.pdf`;
  }

  return (
    <FlexColumnBox gapSize={6}>
      <PDFModal
        sheetName={sheetName}
        invoiceId={selectedInvoiceId}
        open={pdfOpen}
        setOpen={setPdfOpen}
      />
      <FlexBox>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>請求書番号</TableCell>
                <TableCell>取引先</TableCell>
                <TableCell>期間</TableCell>
                <TableCell>件数</TableCell>
                <TableCell>請求金額</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices?.map((invoice) => {
                if (!invoice) return null;
                return (
                  <TableRow
                    key={`invoice-${invoice.id}`}
                    onDoubleClick={() => handleInvoiceDoubleClick(invoice.id)}
                  >
                    <TableCell>
                      <Typography>{invoice.invoice_number}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{invoice.c_name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{invoice.title}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="number">
                        {invoice.detail_count}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="number">
                        {invoice.billing_amount}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <PageNation
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
              getSearch(value)
            }
          />
        </TableContainer>
      </FlexBox>
    </FlexColumnBox>
  );
}
