import { useTheme } from '@mui/material/styles';
import Loading from 'atoms/Loading';
import Header from 'components/Header';
import SubMenu from 'components/SubMenu';
import { typeMenu } from 'const/menu';
import { MstDataContext } from 'contexts/Mst';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProviderCustom } from 'themes/Base';

interface MainProps {
  menu: typeMenu;
}
export default function Main({ menu }: MainProps) {
  const { loading, SYSTEM } = useContext(MstDataContext);
  const theme = useTheme();

  const styles = {
    headerContainer: {
      width: '100%',
      maxHeight: '40px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    headerMenu: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start', // 左寄せに変更
      gap: theme.spacing(1), // リストアイテム間のスペースを追加（必要に応じて調整）
      padding: '0 16px', // 左右にパディングを追加（必要に応じて調整）
    },
    subMenu: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    content: {
      flexGrow: 1 /* 残りの横幅を均等に分配する */,
      flexBasis: 0,
      minWidth: 0 /* 必要に応じて、文字がはみ出ないようにするために設定 */,
      padding: theme.spacing(2),
      //margin: theme.spacing(2),
      overflow: 'scroll',
    },
  };

  return (
    <ThemeProviderCustom>
      <Loading flg={loading} />
      <Header />
      {!loading && (
        <main>
          <SubMenu menu={menu} />
          <div style={styles.content}>
            <Outlet />
          </div>
        </main>
      )}
    </ThemeProviderCustom>
  );
}
