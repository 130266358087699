import { PrimaryButton, SecondButton } from 'atoms/Button';
import Modal from 'atoms/Modal';
import * as cMst from 'components/mst/Index';
import { initSearchRole } from 'const/role/index';
import log from 'functions/logger';
import React, { useState } from 'react';
import { tRoleSearch } from 'types/mst';

interface MainProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  callback: (data: tRoleSearch) => void;
}
const Main = ({ open, setOpen, callback }: MainProps) => {
  const [values, setValues] = useState<tRoleSearch>(initSearchRole);

  const handleClickSearch = () => {
    log.debug('handleClickSearch');
    callback(values);
    handleCickClose();
  };

  const handleCickClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      title=""
      actions={
        <ActionBtn
          callbackSearch={handleClickSearch}
          callbackClose={handleCickClose}
        />
      }
      open={open}
      onClose={handleCickClose}
    >
      <cMst.Freewords value={values.freewords} setValues={setValues} />
    </Modal>
  );
};

export default Main;

interface ActionBtnProps {
  callbackSearch: () => void;
  callbackClose: () => void;
}
const ActionBtn = ({ callbackSearch, callbackClose }: ActionBtnProps) => {
  return (
    <>
      <PrimaryButton label="検索" onClick={callbackSearch} />
      <SecondButton onClick={callbackClose} label={'閉じる'} />
    </>
  );
};
