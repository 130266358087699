import { Grid, TextField, Typography } from '@mui/material';
import * as CustomGrid from 'atoms/Grid';
import { GridItemDateField } from 'components/GridItem';
import * as SelectItems from 'components/input/Select';
import { handleChangeValues } from 'functions/handles';
import React from 'react';
import { tVehicle } from 'types/mst';

interface GridItemFieldProps {
  values: tVehicle;
  setValues: React.Dispatch<React.SetStateAction<tVehicle>>;
}
export const Plate = ({ values, setValues }: GridItemFieldProps) => (
  <Grid item xs={12}>
    <Typography>車番</Typography>
    <div style={{}}>
      <TextField
        size="small"
        label="支局"
        value={values.plate_branch}
        name="plate_branch"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeValues(e.target.value, e.target.name, setValues)
        }
      />
      <TextField
        size="small"
        label="種別"
        value={values.plate_kind}
        name="plate_kind"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeValues(e.target.value, e.target.name, setValues)
        }
      />
      <TextField
        size="small"
        label="ひらがな"
        value={values.plate_hiragana}
        name="plate_hiragana"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeValues(e.target.value, e.target.name, setValues)
        }
      />
      <TextField
        size="small"
        label="ナンバー"
        value={values.plate_number}
        name="plate_number"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeValues(e.target.value, e.target.name, setValues)
        }
      />
    </div>
  </Grid>
);

/*
export const CmId = ({ values, setValues }: GridItemFieldProps) => {
  const { loading, carModels } = useContext(MstDataContext);
  if (loading || !carModels) return <Typography>loading...</Typography>;

  const options = carModels.map((cm) => ({
    id: cm.id,
    label: cm.name,
  }));
  const selectedOption = options.find(
    (option: any) => option.id === values.cm_id
  );

  return (
    <GridItemTextFieldCustom size={{ xs: 12, lg: 3, xl: 2 }}>
      <AutocompleteSelect
        name="cm_id"
        label={"車種"}
        options={options}
        value={selectedOption || null}
        onChange={(e: React.ChangeEvent<{}>, newValue: ListItem) =>
          handleChangeValues(newValue.id, "cm_id", setValues)
        }
      />
    </GridItemTextFieldCustom>
  );
};
*/

export const VehicleType = ({ values, setValues }: GridItemFieldProps) => {
  return (
    <CustomGrid.GridItem>
      <SelectItems.VehicleType
        name={'vt_id'}
        value={values.vt_id}
        cbValueChange={(val) => {
          handleChangeValues(val, 'vt_id', setValues);
        }}
        flgVehicle={true}
        flgTrailer={true}
      />
    </CustomGrid.GridItem>
  );
};
export const Inspection = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemDateField
    label="車検日"
    name="inspection"
    value={values.inspection}
    handleChangeValues={(newDate: string) =>
      handleChangeValues(newDate, 'inspection', setValues)
    }
  />
);
