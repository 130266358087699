// リダイレクト
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { SecondButton } from 'atoms/Button';
import Loading from 'atoms/Loading';
import Modal from 'atoms/Modal';
import { SmallTextField } from 'atoms/TextField';
import * as DailyMemoBtn from 'components/dailyMemo/Button';
import PJModal from 'components/project/Modal';
import { LineNomal } from 'components/transport/Card';
import SearchModal from 'components/transport/Search';
import { initDailyMemo } from 'const/dailyMemo/index';
import { initProjectKind } from 'const/projectKind/index';
import { MstDataContext } from 'contexts/Mst';
import { useSelection } from 'contexts/Schedules';
import { getDailyMemoList } from 'functions/api/dailymemo';
import { getProject } from 'functions/api/project';
import * as apiTransport from 'functions/api/transport';
import { getDailyMemosToDate } from 'functions/dailymemo/index';
import { handleChangeValues } from 'functions/handles';
import { truncateString } from 'functions/index';
import log from 'functions/logger';
import {
  changeDateToStrDay,
  convertLocalDateToUTC,
  getCharWeekday,
  isSameDate,
  mathDateTime,
  strDateOrigin,
} from 'functions/time';
import { checkInTransportCard } from 'functions/transport';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { palette } from 'themes/Base';
import { typeHash } from 'types';
import { tDailyMemo } from 'types/dailymemo';
import { cardType } from 'types/index';
import { tProjectKind } from 'types/mst';
import { initProject, initWaypoints, tProject } from 'types/project';
import {
  tTransportCard,
  tTransportCardSearch,
  tTransportKey,
} from 'types/transport';

const ContentsArea = styled('div')({
  '& .head': {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    //border: "1px solid #e0e0e0",
    position: 'sticky',
    top: 0,
    zIndex: 95,
    '& .cell': {
      textAlign: 'center',
      '& .MuiFormControlLabel-root': {
        margin: 'auto',
      },
      '&.day': {
        position: 'sticky',
        left: 0,
        zIndex: 99,
        margin: 'none',
        backgroundColor: 'inherit',
      },
    },
  },
  '& .cell': {
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
    '&.day': {
      width: '150px',
      //border: "1px solid #e0e0e0",
      textAlign: 'center',
    },
    '&.kind': {
      /*
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      */
      width: '100px',
      //border: "1px solid #e0e0e0",
      '&.active': {
        minWidth: '220px',
        borderLeft: '1px solid e0e0e0',
        borderRight: '1px solid e0e0e0',
      },
    },
  },

  '& .row': {
    display: 'inline-flex',
    border: '1px solid black',
    minWidth: '100%',
  },

  '& .row.data': {
    '&.Monday': {
      '.odd': { backgroundColor: 'rgba(255, 211, 44, 0.3)' },
      '.even': { backgroundColor: 'rgba(255, 211, 44, 0.6)' },
    },
    '&.Tuesday': {
      '.odd': { backgroundColor: 'rgba(240, 107, 68, 0.3)' },
      '.even': { backgroundColor: 'rgba(240, 107, 68, 0.6)' },
    },
    '&.Wednesday': {
      '.odd': { backgroundColor: 'rgba(93, 127, 204, 0.3)' },
      '.even': { backgroundColor: 'rgba(93, 127, 204, 0.6)' },
    },
    '&.Thursday': {
      '.odd': { backgroundColor: 'rgba(93, 170, 102, 0.3)' },
      '.even': { backgroundColor: 'rgba(93, 170, 102, 0.6)' },
    },
    '&.Friday': {
      '.odd': { backgroundColor: 'rgba(204, 170, 51, 0.3)' },
      '.even': { backgroundColor: 'rgba(204, 170, 51, 0.6)' },
    },
    '&.Saturday': {
      '.odd': { backgroundColor: 'rgba(139, 103, 61, 0.3)' },
      '.even': { backgroundColor: 'rgba(139, 103, 61, 0.6)' },
    },
    '&.Sunday': {
      '.odd': { backgroundColor: 'rgba(144, 144, 144, 0.3)' },
      '.even': { backgroundColor: 'rgba(144, 144, 144, 0.6)' },
    },
    '& .cell.day': {
      backgroundColor: '#f5f5f5',
      display: 'flex',
      justifyItems: 'center',
      alignItems: 'center',
      position: 'sticky',
      left: 0,
      zIndex: 91,
      '& *': {
        margin: 'auto',
      },
    },
    '& .cell.kind': {
      '& .status-complete': {
        backgroundColor: palette.glayDark,
        color: palette.white,
      },
      '& .status-transport': {
        //backgroundColor: useTheme.palette.primary.main,
      },
      '& .status-no': {
        backgroundColor: palette.white,
      },
    },
  },
});

/**
 * 案件情報の積み日範囲を必須にするためカスタム型を作成
 */
type tTransportCardSearchCustom = tTransportCardSearch & {
  load_datetime_from: string;
  load_datetime_to: string;
};

export default function Main() {
  const theme = useTheme();
  const navigate = useNavigate();

  const { transportCards: transports } = useSelection();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (transports.length > 0) {
      setIsExpanded(true);
    }
  }, [transports]);

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <ContentsArea
        sx={{
          flexGrow: 1, // 残りの高さを占める
          transition: 'height 0.3s ease',
          overflow: 'auto', // 高さが足りない時にスクロールバーを表示
          maxWidth: '100vw',
        }}
      >
        <Contents />
      </ContentsArea>
      {/* Bのエリア: クリックで高さが0 -> 100pxに変わる */}
      <Container
        className="SlideArea"
        sx={{
          height: isExpanded ? 'auto' : '0px',
          backgroundColor: theme.palette.common.white,
          transition: 'height 0.3s ease',
          //overflow: "hidden", // 高さが0の時にコンテンツが見えないように
        }}
      >
        <SelectedArea
          transportCards={transports}
          optionNode={
            <Button
              variant="contained"
              onClick={() => {
                navigate(
                  '/operation/instructions/edit' /*"/operation/base-transport"*/
                );
              }}
            >
              配車手配
            </Button>
          }
        />
      </Container>
    </Box>
  );
}

/**
 * コンテンツ
 * @param param0
 * @returns
 */
function Contents() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { loading, projectKinds, SYSTEM, loginUser } =
    useContext(MstDataContext);
  const [openSearch, setOpenSearch] = useState(false);

  // メモ機能
  const [dailyMemos, setDailyMemos] = useState<tDailyMemo[]>([]);
  const [selectedMemo, setSelectedMemo] = useState<tDailyMemo>();

  const [dateTransports, setDateTransports] = useState<
    Record<string, tTransportCard[]>
  >({});

  const [searchLoading, setSearchLoading] = useState(false);
  const [viewPK, setViewPK] = useState<tProjectKind[]>([]);
  const [checkedKind, setCheckedKind] = useState<Record<number, boolean>>({});

  const [openCreatePJModal, setOpenCreatePJModal] = useState(false);
  const [initPJCustom, setInitPJCustom] = useState<tProject>({
    ...initProject,
    in_charge: loginUser.id,
  });

  const [searchTrigger, setSearchTrigger] = useState<string>('');
  const [openTran, setOpenTran] = useState<tTransportKey>();
  const [selectedPJ, setSelectedPJ] = useState<tProject['id'] | undefined>();

  /**
   * 検索条件
   */
  const [termsTC, setTermsTC] = useState<tTransportCardSearchCustom>({
    status: SYSTEM?.project.status.ordered
      ? [SYSTEM.project.status.ordered]
      : [],
    load_datetime_from: mathDateTime(
      convertLocalDateToUTC(),
      [0, 0, -7, 0, 0, 0]
    ).toISOString(),
    load_datetime_to: mathDateTime(
      convertLocalDateToUTC(),
      [0, 0, 7, 0, 0, 0]
    ).toISOString(),
    unload_datetime_from: undefined,
    unload_datetime_to: undefined,
    c_id: undefined,
    kind: undefined,
    in_charge: null,
  });

  // 検索結果を日別の連想配列にいれるようのState
  const [viewDate, setViewDate] = useState<Record<string, typeHash>>({});

  /**
   * 検索処理
   * @param searchPJ
   */
  const searchTC = async (termsTJ: tTransportCardSearchCustom) => {
    const res = await apiTransport.getTransportCards(termsTJ);

    // 対象の日付を取得
    const tmpViewDate = getWeekDatesWithDays(
      termsTJ.load_datetime_from,
      termsTJ.load_datetime_to
    );

    const transports = res.data; // 検索条件に一致するもの

    // データを格納
    setDateTransports((prev) => {
      const temp: Record<string, tTransportCard[]> = {};
      Object.keys(tmpViewDate).forEach((date) => {
        // 1日分のデータを取得
        const targets: tTransportCard[] = transports.filter(
          (transport: tTransportCard) => {
            if (!transport.pj_id) {
              // プロジェクト情報がない場合は何も返さない
              return false;
            }

            if (!isSameDate(transport.load_datetime, date)) {
              return false;
            }

            return true;
          }
        );

        const dateTransportCard: tTransportCard[] = targets.filter(
          (target) => target.pj_id !== undefined
        );

        temp[date] = dateTransportCard;
      });

      return {
        ...temp,
      };
    });

    setViewDate(tmpViewDate);
    setSearchLoading(false);
  };

  /**
   * 日付セルダブルクリック
   * @param date
   */
  const handleDayCellDoubleClick = (date: string) => {
    // 選択日が00:00:00がローカル時間となる
    setSelectedMemo({
      ...initDailyMemo,
      datetime: convertLocalDateToUTC(date).toISOString(),
    });
  };

  /**
   * 案件表示セルダブルクリック
   * @param date
   * @param kbn
   */
  const handlePJCellDoubleClick = (date: string, kbn: number) => {
    // 積地の初期値を設定

    const ways = initWaypoints.map((way) => ({ ...way }));

    ways.map((way) => {
      way.datetime = convertLocalDateToUTC(date).toISOString();
    });

    setInitPJCustom({
      ...initProject,
      waypoints: ways,
      kind: kbn,
      status: SYSTEM?.project.status.ordered || 0,
    });
    setSelectedPJ(undefined);
    setOpenCreatePJModal(true);
  };

  /**
   * メモダブルクリック
   * @param date
   * @param id
   */
  const handleDayDoubleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    date: string,
    id: number
  ) => {
    // 後続のイベントを抑制する
    event.stopPropagation();
    const obj = dailyMemos.find((dm) => dm.id === id);
    if (obj) {
      log.debug(obj);
      setSelectedMemo({
        ...obj,
        datetime: convertLocalDateToUTC(date).toISOString(),
      });
    } else {
      alert('対象のメモを取得できませんでした。');
    }
  };

  useEffect(() => {
    if (!selectedMemo) {
      /**
       * デイリーメモを表示から非表示にした場合、データを再取得する
       */
      getDailyMemoList().then((res) => {
        if (res.status !== 200) {
          throw new Error('');
        }
        setDailyMemos(res.data);
      });
    }
  }, [selectedMemo]);

  /**
   * デイリーメモ変更イベント
   * @param name
   * @param value
   */
  const handleDailyMemoChange = (name: string, value: any) => {
    setSelectedMemo((prev) => {
      if (prev === undefined) {
        prev = initDailyMemo;
      }
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * メモの登録更新処理
   * @param data
   */
  const callbackDailyMemoSave = (data: tDailyMemo) => {
    getDailyMemoList().then((res) => {
      if (res.status !== 200) {
        throw new Error('');
      }
      setDailyMemos(res.data);
    });
  };

  /**
   * メモ削除コールバック
   * @param data
   */
  const callbackDailyMemoDelete = (data: tDailyMemo) => {
    getDailyMemoList().then((res) => {
      if (res.status !== 200) {
        throw new Error('');
      }
      setDailyMemos(res.data);
    });
  };

  // 自動更新の判定に使うstateをuseRefで管理
  const openSearchRef = useRef(openSearch);
  const openCreatePJModalRef = useRef(openCreatePJModal);
  const selectedPJRef = useRef(selectedPJ);
  const openTranRef = useRef(openTran);

  useEffect(() => {
    // `useEffect` で `useState` の最新値を `useRef` に更新
    openSearchRef.current = openSearch;
    openCreatePJModalRef.current = openCreatePJModal;
    selectedPJRef.current = selectedPJ;
    openTranRef.current = openTran;
  }, [openSearch, openCreatePJModal, selectedPJ, openTran]);

  useEffect(() => {
    const fetchData = async (termsTC: tTransportCardSearchCustom) => {
      setSearchLoading(true);
      searchTC(termsTC);
      getDailyMemoList().then((res) => {
        if (res.status !== 200) {
          throw new Error('');
        }
        setDailyMemos(res.data);
      });
    };

    // 初回データ取得
    fetchData(termsTC);

    // 定期的にデータを更新する
    const interval = setInterval(() => {
      // モーダルを開いている、選択中の案件がある場合は更新しない
      if (
        !openSearchRef.current &&
        !openCreatePJModalRef.current &&
        selectedPJRef.current === undefined &&
        !openTranRef.current
      ) {
        fetchData(termsTC);
      }
    }, 60000); // 20秒ごとにデータを更新

    // コンポーネントがアンマウントされた時にクリーンアップ
    return () => clearInterval(interval);
  }, [termsTC, searchTrigger]);

  useEffect(() => {
    if (!projectKinds) {
      return;
    }

    const newPK = [...projectKinds, initProjectKind];
    const tmpCheck: Record<number, boolean> = {};
    newPK.map((kind) => {
      tmpCheck[Number(kind.id)] = true;
    });

    setViewPK(newPK);
    setCheckedKind(tmpCheck);
  }, [projectKinds]);

  // 日付が変更されたときにスクロールを発生させる
  const refTodate = useRef<HTMLDivElement>(null); // 対象への参照
  useEffect(() => {
    if (refTodate.current) {
      refTodate.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [refTodate.current]); // viewDateが変更されたときにスクロールを発生させる

  if (loading || !projectKinds) {
    return <Loading flg={true} />;
  }

  return (
    <>
      <Loading flg={searchLoading} />
      <SearchModal
        terms={termsTC}
        open={openSearch}
        setOpen={setOpenSearch}
        callback={(terms: any) => setTermsTC(terms)}
      />
      <PJModal
        open={
          openCreatePJModal ||
          !!selectedPJ ||
          (openTran?.pj_id !== undefined && openTran?.no !== undefined)
        }
        onClose={() => {
          setOpenCreatePJModal(false);
          setSelectedPJ(undefined);
          setSearchTrigger(Math.random().toString());
          setOpenTran(undefined);
        }}
        pjId={
          openCreatePJModal
            ? undefined
            : selectedPJ
              ? selectedPJ
              : openTran?.pj_id
        }
        tranNo={
          openCreatePJModal ? undefined : selectedPJ ? undefined : openTran?.no
        }
        initCustom={initPJCustom}
        callbackNomal={() => {
          setOpenCreatePJModal(false);
          searchTC(termsTC);
        }}
      />
      {selectedMemo && (
        <ModalMemo
          selectedMemo={selectedMemo}
          setSelectedMemo={setSelectedMemo}
        />
      )}

      <div className="row head">
        <div className="cell day">
          <Button onClick={() => setOpenSearch(true)}>検索</Button>
        </div>
        {viewPK.map((projectKind, index) => (
          <div
            key={`schedule-rowhead-${projectKind.id}`}
            className={`cell kind ${
              checkedKind[projectKind.id] ? 'active' : ''
            }`}
          >
            <Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    className="color-main"
                    onChange={(e) =>
                      setCheckedKind({
                        ...checkedKind,
                        [projectKind.id]: e.target.checked,
                      })
                    }
                    value={checkedKind[Number(projectKind.id)]}
                  />
                }
                label={
                  checkedKind[Number(projectKind.id)] ? projectKind.name : ''
                }
              />
            </Typography>
          </div>
        ))}
      </div>

      {Object.keys(dateTransports).map((date, dIndex) => {
        const dateMemos: tDailyMemo[] = getDailyMemosToDate(dailyMemos, date);

        return (
          <div
            id={`${date}`}
            ref={
              isSameDate(date, changeDateToStrDay(new Date()))
                ? refTodate
                : null
            }
            key={`schedule-column-${date}`}
            className={`row data ${viewDate[date].weekday}`}
            style={{ minHeight: '75px' }}
          >
            <Box
              className="cell day"
              onDoubleClick={() => {
                handleDayCellDoubleClick(date);
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexFlow: 'column',
                  gap: theme.spacing(1),
                }}
              >
                <Link href={`/project/input?date=${date}`}>
                  <Typography
                    sx={{ userSelect: 'none' }}
                  >{`${date}(${getCharWeekday(date)})`}</Typography>
                </Link>
                {dateMemos?.map((memo: tDailyMemo) => (
                  <Typography
                    sx={{ textAlign: 'left', margin: 'unset !important' }}
                    key={memo.id}
                    onDoubleClick={(
                      event: React.MouseEvent<HTMLButtonElement>
                    ) => {
                      if (!memo.id) {
                        return;
                      }

                      handleDayDoubleClick(event, date, memo.id);
                    }}
                  >
                    {`${truncateString(memo.memo, 7)}`}
                  </Typography>
                ))}
              </Box>
            </Box>

            {viewPK?.map((kind, index) => {
              const categoryTransports = dateTransports[date].filter(
                (project) => {
                  return project.kind === kind.id;
                }
              );

              //log.debug("categoryTransports", categoryTransports);

              return (
                <div
                  key={`schedule-column-${date}-${kind.id}`}
                  className={`cell kind ${
                    checkedKind[Number(kind.id)] ? 'active' : ''
                  } ${index % 2 === 0 ? 'even' : 'odd'}`}
                  onDoubleClick={() => {
                    handlePJCellDoubleClick(date, kind.id);
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    {categoryTransports.map((transport, i) => {
                      return (
                        <TransportCardToSchedule
                          key={`schedule-column-${date}-${kind.id}-${i}`}
                          transportCard={transport}
                          flgOmit={!checkedKind[Number(kind.id)]}
                          setSelectedPJ={setSelectedPJ}
                          type="line"
                          setOpenTran={setOpenTran}
                        />
                      );
                    })}
                  </Box>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
}

/**
 * 予定表用
 * @param param0
 * @returns
 */
const TransportCardToSchedule = ({
  transportCard, // 輸送情報
  flgOmit = true, // 略称表示かどうか
  switchCtrl = true, // 選択機能の有無
  flgAttributeLabel = true, // 属性ラベルの表示
  setSelectedPJ = undefined,
  type = 'box',
  setOpenTran,
}: {
  transportCard: tTransportCard;
  flgOmit?: any;
  switchCtrl?: boolean;
  flgAttributeLabel?: boolean;
  setSelectedPJ?:
    | React.Dispatch<React.SetStateAction<tProject['id'] | undefined>>
    | undefined;
  type?: cardType;
  setOpenTran?: (data: tTransportKey) => void;
}) => {
  const { transportCards: transports } = useSelection();
  const { addSelection, removeSelection } = useSelection(); // グローバル状態の関数

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); // ポップオーバーのアンカー
  const [selected, setSelected] = useState(false);

  const [tranKey, setTranKey] = useState<tTransportKey>({
    pj_id: transportCard.pj_id,
    no: transportCard.tran_no,
  });
  const [project, setProject] = useState<tProject>(initProject);

  /**
   * 案件情報の取得に成功したら積卸地情報を更新する
   * @param data
   */
  const callbackGetProjectSuccess = (data: tProject) => {
    setProject(data);
  };

  /**
   * クリック時の処理
   * @param event
   * @returns
   */
  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }

    // 選択されているかを確認
    if (checkSelectedTransports()) {
      handleDeselectTran();
      return;
    } else {
      handleSelectedTran();
    }
  };

  /**
   * ダブルクリック時の処理
   * @param event
   */
  const handleCardDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }

    if (!transportCard.pj_id) {
      return;
    }

    // event.currentTargetを変数に保存する
    const targetElement = event.currentTarget;

    setAnchorEl(targetElement); // クリックした要素をアンカーに設定

    // 案件情報の積卸情報を取得
    getProject(transportCard.pj_id, callbackGetProjectSuccess);
  };

  const handleToolchipClose = () => {
    setAnchorEl(null); // ポップオーバーを閉じる
  };

  /**
   * 選択処理
   * @param type
   */
  const handleSelectedTran = () => {
    addSelection({ transportCard }); // グローバル状態に選択を追加
    handleToolchipClose(); // 選択後にメニューを閉じる
  };

  /**
   * 解除処理
   * @param type
   */
  const handleDeselectTran = () => {
    removeSelection({ transportCard });
    handleToolchipClose(); // 選択後にメニューを閉じる
  };

  /**
   * 選択輸送情報が表示されているかをチェック
   * @returns
   */
  const checkSelectedTransports = () => {
    return checkInTransportCard(transports, transportCard);
  };

  // 選択状態の確認
  useEffect(() => {
    setSelected(checkSelectedTransports());
  }, [transports]);

  return (
    <Box className={`TransportCardToInstruction`} sx={{ display: 'contents' }}>
      <LineNomal
        transportCard={transportCard}
        callbackClick={handleCardClick}
        callbackDoubleClick={() => {
          if (setOpenTran && transportCard.pj_id && transportCard.tran_no) {
            setOpenTran({
              pj_id: transportCard.pj_id,
              no: transportCard.tran_no,
            });
          }
        }}
        flgOmit={flgOmit}
        type={type}
      >
        {flgAttributeLabel && (
          <>
            {checkSelectedTransports() && (
              <Typography className="stamp secondary">{`選`}</Typography>
            )}
          </>
        )}
      </LineNomal>
      {/*}
      <Toolchip
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        handleClose={handleToolchipClose}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <ComponentOperation.Trailer
                label="自社シャーシ"
                name="start_trailer_v_id"
                value={instruction.start_trailer_v_id}
                cbValueChange={(value) => {
                  handleChangeValues(
                    value,
                    'start_trailer_v_id',
                    setInstruction
                  );
                }}
              />
              <ComponentOperation.TrailerMemo
                label="自社シャーシ（備考）"
                name="start_trailer_memo"
                value={instruction.start_trailer_memo}
                cbValueChange={(value) => {
                  handleChangeValues(
                    value,
                    'start_trailer_memo',
                    setInstruction
                  );
                }}
              />
              <Grid
                item
                xs={1}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                }}
              >
                <PrimaryButton
                  label="登録"
                  onClick={() => {
                    updateToTran(tranKey, instruction);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <ComponentTransport.ContainerCode
                label="コンテナ番号"
                name="container_code"
                value={transport?.container_code || ''}
                cbValueChange={(value) => {
                  handleChangeValues(value, 'container_code', setInstruction);
                }}
              />
              <Grid
                item
                xs={1}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                }}
              >
                <PrimaryButton
                  label="登録"
                  onClick={() => {
                    apiTransport.update({
                      id: transportCard.id,
                      data: { container_code: transport.container_code },
                      callbackSuccess: callbackUpdateToTran,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PjDetail pj={project} setPj={setProject} />
          </Grid>
        </Grid>
      </Toolchip>
      */}
    </Box>
  );
};

type tViewDate = Record<string, typeHash>;

/**
 *
 * @param startDate
 * @param endDate
 * @returns
 */
function getWeekDatesWithDays(startDate: string, endDate: string): tViewDate {
  const daysOfWeek: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const weekDates: tViewDate = {};

  // startDateからendDateまでの日付を取得
  const currentDate = new Date(startDate);
  const finalDate = new Date(endDate);

  while (currentDate <= finalDate) {
    // 日付をキーに曜日を値とするオブジェクトを作成
    const dateKey: string = changeDateToStrDay(currentDate);
    const dayOfWeek: string = daysOfWeek[currentDate.getDay()];

    weekDates[dateKey] = { weekday: dayOfWeek };

    // １日加算
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return weekDates;
}

const ModalMemo = ({
  selectedMemo,
  setSelectedMemo,
}: {
  selectedMemo: tDailyMemo;
  setSelectedMemo: React.Dispatch<React.SetStateAction<tDailyMemo | undefined>>;
}) => {
  const theme = useTheme();
  const [memo, setMemo] = useState<tDailyMemo>(initDailyMemo);

  const handleSuccess = (data: any) => {
    setSelectedMemo(undefined);
  };

  useEffect(() => {
    if (selectedMemo) {
      setMemo(selectedMemo);
    }
  }, [selectedMemo]);

  return (
    <Modal
      title={`メモ:${strDateOrigin(selectedMemo?.datetime || '')}`}
      open={!!selectedMemo}
      width="sm"
      onClose={() => setSelectedMemo(undefined)}
      actions={
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <DailyMemoBtn.Delete
              info={selectedMemo}
              callbackNomal={handleSuccess}
            />
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                gap: theme.spacing(1),
              }}
            >
              {selectedMemo.id ? (
                <DailyMemoBtn.Update
                  info={memo}
                  callbackNomal={handleSuccess}
                />
              ) : (
                <DailyMemoBtn.Save info={memo} callbackNomal={handleSuccess} />
              )}
              <SecondButton
                label="閉じる"
                onClick={() => setSelectedMemo(undefined)}
              />
            </Box>
          </Grid>
        </Grid>
      }
    >
      <Box>
        <SmallTextField
          props={{
            multiline: true,
            rows: 4,
            name: 'memo',
            value: memo.memo,
            onChange: (e) => {
              handleChangeValues(e.target.value, 'memo', setMemo);
            },
          }}
        />
      </Box>
    </Modal>
  );
};

/**
 * 配車手配エリア
 * @param param0
 * @returns
 */
const SelectedArea = ({
  transportCards,
  optionNode,
}: {
  transportCards: tTransportCard[];
  optionNode: React.ReactNode;
}) => {
  return (
    <>
      {transportCards.length !== 0 && (
        <Grid container>
          <Grid item xs={11}>
            <Box
              sx={{
                display: 'flex',
                flexFlow: 'wrap',
              }}
            >
              {transportCards.map((transportCard, index) => {
                return (
                  <TransportCardToSchedule
                    key={`TransportCard-InstructionTargetArea-${index}`}
                    transportCard={transportCard}
                    flgOmit={false}
                    flgAttributeLabel={false}
                  />
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={1}>
            {optionNode}
          </Grid>
        </Grid>
      )}
    </>
  );
};
