import { Grid, Typography } from '@mui/material';
import { PrimaryButton } from 'atoms/Button';
import * as cVehicleType from 'components/mst/VehicleType';
import {
  deleteVehicleType,
  getVehicleType,
  storeVehicleType,
  updateVehicleType,
} from 'functions/api/mst';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { initialVehicleType, tVehicleType } from 'types/mst';

export default function Main() {
  const { id } = useParams();
  const [data, setData] = useState<tVehicleType>(initialVehicleType);

  useEffect(() => {
    const fetchData = async (id: number) => {
      try {
        getVehicleType(id).then((res) => {
          if (res.status !== 200) throw new Error('データ取得に失敗しました');
          setData(res.data);
        });
      } catch (error) {
        console.error(error);
      }
    };
    if (id) {
      fetchData(Number(id));
    }
  }, [id]);

  // マスタ更新
  const setPostRequestUpdate = () => {
    updateVehicleType(data)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ更新に失敗しました');
        alert('データを更新しました');
        //window.location.href = "/mst/vehicleType";
      })
      .catch((err) => {
        console.error(err);
        alert('データ更新に失敗しました');
      });
  };

  // マスタ新規登録
  const setPostRequestStore = () => {
    storeVehicleType(data)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ登録に失敗しました');
        alert('データを登録しました');
        window.location.href = '/mst/vehicleType';
      })
      .catch((err) => {
        console.error(err);
        alert('データ登録に失敗しました');
      });
  };

  // マスタ削除
  const setPostRequestDelete = () => {
    if (!window.confirm('削除しますか？')) return;
    deleteVehicleType(data.id)
      .then((res: any) => {
        if (res.status !== 200) throw new Error('データ削除に失敗しました');
        alert('データを削除しました');
        window.location.href = '/mst/vehicleType';
      })
      .catch((err) => {
        console.error(err);
        alert('データ削除に失敗しました');
      });
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {id ? (
              <>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton onClick={setPostRequestUpdate} label="更新" />
                </Grid>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton onClick={setPostRequestDelete} label="削除" />
                </Grid>
              </>
            ) : (
              <Grid item xs={4} md={4} lg={2}>
                <PrimaryButton onClick={setPostRequestStore} label="登録" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {id && (
            <Typography variant="h4">
              ID:
              <Typography
                component={'span'}
                variant="h4"
                sx={{ fontWeight: 700 }}
              >
                {data.id}
              </Typography>
            </Typography>
          )}
        </Grid>
        <cVehicleType.Name values={data} setValues={setData} />
        <cVehicleType.FlgTrailer values={data} setValues={setData} />
        <cVehicleType.Description values={data} setValues={setData} />
      </Grid>
    </div>
  );
}
