import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { FlexBox } from 'atoms/Box';
import { MinusButton, PlusButton, PrimaryButton } from 'atoms/Button';
import { DateInput } from 'atoms/DateInput';
import Loading from 'atoms/Loading';
import { AutocompleteTextField, TableCellTextField } from 'atoms/TextField';
import * as SelectItems from 'components/input/Select';
import { initAddress } from 'const/address';
import { waypointKbn, waypointKbnLoad, waypointKbnUnload } from 'const/index';
import { MstDataContext } from 'contexts/Mst';
import { storeAddress } from 'functions/api/mst';
import log from 'functions/logger';
import { getTargetAddress } from 'functions/mst/address';
import { strDateCustom } from 'functions/time';
import React, { useContext, useEffect, useState } from 'react';
import { tAddress } from 'types/address';
import { List, ListItem, typeHashValue } from 'types/index';
import { initialWaypoint, tWaypoint, tWaypoints } from 'types/project';

const LoadTableContainer = styled(TableContainer)({
  '& .button': {
    minWidth: '40px',
  },
  '& .kbn': {
    minWidth: '20px',
  },
  '& .date': {
    minWidth: '150px',
  },
  '& .a_id': {
    width: '20%',
    minWidth: '250px',
  },
  '& .abbreviation': {
    minWidth: '250px',
  },
  '& .prefectures': {
    width: '8%',
    minWidth: '140px',
  },
  '& .city': {
    width: '8%',
    minWidth: '150px',
  },
  '& .street': {
    width: '12%',
    minWidth: '170px',
  },
  '& .building': {
    width: '12%',
    minWidth: '170px',
  },
  '& .memo': {
    minWidth: '200px',
  },
  '& .proc': {
    width: '12%',
    minWidth: '50px',
  },
});

/**
 * テーブル型の入力フォームの変更を処理する関数
 * @param value
 * @param rowValues
 * @param setState
 * @param field
 */
export const handleWaypointsChange = (
  value: typeHashValue,
  rowField: number,
  columnField: string,
  setState: React.Dispatch<React.SetStateAction<tWaypoints>>
) => {
  log.debug('handleWaypointsChange', value, rowField, columnField);
  setState((prev) => {
    // 既存のrowField配列をコピー
    const newRowField = [...prev];

    // 指定されたインデックスのオブジェクトをコピーして更新
    newRowField[rowField] = {
      ...newRowField[rowField],
      [columnField]: value,
    };

    // 新しい状態を返す
    return newRowField;
  });
};

interface WaypointTableProps {
  label: string;
  values: tWaypoints;
  setValues: React.Dispatch<React.SetStateAction<tWaypoints>>;
}

/**
 * 積地と卸地のテーブル
 * @param label
 * @param columnHeads
 * @param rowHeads
 * @param transport
 * @param setTransport
 * @returns
 */
export const WaypointsTable = React.memo(
  ({ label, values, setValues }: WaypointTableProps) => {
    log.debug('WaypointsTable', values);
    const addRow = (index: number) => {
      // indexの位置に新しいオブジェクトを追加
      setValues((prev) => {
        const temp = initialWaypoint;
        const newRow = [...prev];
        newRow.splice(index + 1, 0, { ...temp });
        return newRow;
      });
    };

    const removeRow = (index: number) => {
      // indexの位置のオブジェクトを削除
      setValues((prev) => {
        const newRow = [...prev];
        newRow.splice(index, 1);
        return newRow;
      });
    };

    const rowData = (
      index: number,
      values: tWaypoints,
      setValues: React.Dispatch<React.SetStateAction<tWaypoints>>
    ) => {
      return (
        <TableRow key={`project-waypoints-${index}`}>
          <TableCell>
            <Grid container spacing={2} sx={{ textAlign: 'center' }}>
              {values.length > 2 && index !== 0 && (
                <Grid item xs={12}>
                  <MinusButton
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      removeRow(index);
                    }}
                    props={{
                      sx: {
                        minHeight: 'unset',
                        height: '15px',
                        width: '15px',
                      },
                    }}
                  />
                </Grid>
              )}

              {values.length <= 0 ||
                (index !== values.length - 1 && (
                  <Grid item xs={12}>
                    <PlusButton
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        addRow(index);
                      }}
                      props={{
                        sx: {
                          minHeight: 'unset',
                          height: '15px',
                          width: '15px',
                        },
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          </TableCell>
          <TableCell>
            {index === 0 || index === values.length - 1 ? (
              <Typography>
                {
                  waypointKbn.find(
                    (option) => option.id === Number(values[index].kbn)
                  )?.label
                }
              </Typography>
            ) : (
              <SelectItems.WaypointKbn
                label=""
                value={values[index].kbn}
                cbValueChange={(val) =>
                  handleWaypointsChange(val, index, 'kbn', setValues)
                }
              />
            )}
          </TableCell>
          <TableCell>
            <DateInput
              value={values[index].datetime}
              name={'date'}
              onDateChange={(val: string) =>
                handleWaypointsChange(val, index, 'datetime', setValues)
              }
            />
          </TableCell>
          <TableCell>
            <Abbreviation
              index={index}
              value={values[index].abbreviation1}
              value2={values[index].abbreviation2}
              setValues={setValues}
              handleValueChange={handleWaypointsChange}
            />
          </TableCell>
          <TableCell>
            <Address
              index={index}
              waypoint={values[index]}
              setValues={setValues}
            />
          </TableCell>
          <TableCell>
            <SelectItems.Prefectures
              label=""
              value={values[index].prefectures}
              cbValueChange={(val: string) => {
                handleWaypointsChange(val, index, 'prefectures', setValues);
              }}
            />
          </TableCell>
          <TableCell>
            <City
              index={index}
              value={values[index].city}
              setValues={setValues}
              handleValueChange={handleWaypointsChange}
            />
          </TableCell>
          {/*
          <TableCell>
            <Street
              index={index}
              value={values[index].street}
              setValues={setValues}
              handleValueChange={handleWaypointsChange}
            />
          </TableCell>
          <TableCell>
            <Building
              index={index}
              value={values[index].building}
              setValues={setValues}
              handleValueChange={handleWaypointsChange}
            />
          </TableCell>
          */}
          <TableCell>
            <WaypointMemo
              index={index}
              value={values[index].memo || ''}
              setValues={setValues}
              handleValueChange={handleWaypointsChange}
            />
          </TableCell>
        </TableRow>
      );
    };

    return (
      <Grid item xs={12}>
        <LoadTableContainer>
          <Typography className="label" variant="h6">
            {label}
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="button"></TableCell>
                <TableCell className="kbn">区分</TableCell>
                <TableCell className="date">日付</TableCell>
                <TableCell className="abbreviation">略称1/略称2</TableCell>
                <TableCell className="a_id">名称</TableCell>
                <TableCell className="prefectures">都道府県</TableCell>
                <TableCell className="city">市区町村</TableCell>
                {/*
                <TableCell className="street">番地</TableCell>
                <TableCell className="building">建屋・その他</TableCell>
                */}
                <TableCell className="memo">メモ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.map((value, index) => rowData(index, values, setValues))}
            </TableBody>
          </Table>
        </LoadTableContainer>
      </Grid>
    );
  }
);

interface TableItemSelectProps {
  index: number;
  value: number;
  setValues: React.Dispatch<React.SetStateAction<tWaypoints>>;
  onChange: (
    value: typeHashValue,
    rowField: number,
    columnField: string,
    setState: React.Dispatch<React.SetStateAction<tWaypoints>>
  ) => void;
  flgReadOnly?: boolean;
}

const Kbn = ({
  index,
  value,
  setValues,
  onChange,
  flgReadOnly = false,
}: TableItemSelectProps) => (
  <SelectItems.WaypointKbn
    value={value}
    cbValueChange={(val) => onChange(val, index, 'kbn', setValues)}
    disabled={flgReadOnly}
  />
);

interface TableItemAddressProps {
  index: number;
  waypoint: tWaypoint;
  setValues: React.Dispatch<React.SetStateAction<tWaypoints>>;
  /*cbChange: (
    index:number,
    values: tWaypoint,
  ) => void;
   */
}

export const Address = ({
  index,
  waypoint,
  setValues,
  //cbChange,
  flgBtn = true,
}: TableItemAddressProps & { flgBtn?: boolean }) => {
  const { addresses, loading, addAddress } = useContext(MstDataContext);
  const [options, setOptions] = useState<List>([]);

  useEffect(() => {
    if (addresses) {
      const flgLoad = waypoint.kbn === waypointKbnLoad.id ? true : false;
      const flgUnload = waypoint.kbn === waypointKbnUnload.id ? true : false;
      const temp = getTargetAddress(addresses, false, flgLoad, flgUnload);
      setOptions(
        temp.map((address) => ({
          id: address.id || null,
          label: address.name,
          key: address.id,
        }))
      );
    }
  }, [addresses, waypoint.kbn]);

  if (loading || addresses === null) {
    return <Loading flg={loading} />;
  }

  const selectedOption = addresses.find(
    (address: tAddress) => address.id === waypoint.a_id
  );

  /**
   * 変更イベント 基本的に更新値を取得して、親コンポーネントに渡す
   * @param e
   * @param newValue
   * @returns
   */
  const handleChange = (
    e: React.SyntheticEvent<Element, Event>,
    newValue: ListItem
  ) => {
    // クリアボタン押下した時用
    if (newValue === null) {
      setValues((prev) => {
        const newRowField = [...prev];
        const currentRow = newRowField[index];

        // データ更新
        currentRow['a_id'] = undefined;
        currentRow['a_name'] = '';
        currentRow['abbreviation1'] = '';
        currentRow['abbreviation2'] = '';
        currentRow['prefectures'] = '';
        currentRow['city'] = '';
        currentRow['street'] = '';
        currentRow['building'] = '';
        newRowField[index] = currentRow;

        return newRowField;
      });
    }

    const selectedOption = addresses.find((address: tAddress) =>
      address.name === newValue.label ? newValue.label : ''
    );

    setValues((prev) => {
      const newRowField = [...prev];
      const currentRow = newRowField[index];

      // データ更新
      if (selectedOption) {
        // 選択が変わったら

        currentRow['a_id'] = selectedOption.id;
        currentRow['a_name'] = selectedOption.name;
        currentRow['abbreviation1'] = selectedOption.abbreviation1;
        currentRow['abbreviation2'] = selectedOption.abbreviation2;
        currentRow['prefectures'] = selectedOption.prefectures;
        currentRow['city'] = selectedOption.city;
        currentRow['street'] = selectedOption.street;
        currentRow['building'] = selectedOption.building
          ? selectedOption.building
          : '';
      } else {
        // 選択肢にない場合

        currentRow['a_id'] = undefined;
        currentRow['a_name'] = newValue.label;
        currentRow['abbreviation1'] = '';
        currentRow['abbreviation2'] = '';
        currentRow['prefectures'] = '';
        currentRow['city'] = '';
        currentRow['street'] = '';
        currentRow['building'] = '';
        currentRow['memo'] = '';
      }

      return newRowField;
    });
  };

  return (
    <div style={{ display: 'flex', flexFlow: 'row nowrap' }}>
      <AutocompleteTextField
        freeSolo={true}
        label={''}
        options={options}
        value={
          selectedOption
            ? { id: selectedOption.id, label: waypoint.a_name }
            : null
        }
        inputValue={waypoint.a_name}
        onChange={handleChange}
      />
      {waypoint.a_id || flgBtn == false ? (
        <></>
      ) : (
        <PrimaryButton
          label={'登録'}
          onClick={() => {
            const address: tAddress = initAddress;
            address.name = waypoint.a_name;
            address.abbreviation1 = waypoint.abbreviation1;
            address.abbreviation2 = waypoint.abbreviation2;
            address.prefectures = waypoint.prefectures;
            address.city = waypoint.city;
            address.building = waypoint.building;
            if (waypoint.kbn === waypointKbnLoad.id) {
              address.flg_load = true;
            } else if (waypoint.kbn === waypointKbnUnload.id) {
              address.flg_unload = true;
            }
            storeAddress(address)
              .then((res) => {
                if (res.status !== 200) {
                  alert(`データ登録に失敗しました。[${res.status}]`);
                }
                alert('データを登録しました');

                addAddress(res.data); // useContentを更新

                setValues((prev) => {
                  const newRowField = [...prev];
                  const currentRow = newRowField[index];
                  // データ更新
                  currentRow['a_id'] = res.data.id;
                  currentRow['a_name'] = res.data.name;
                  currentRow['abbreviation1'] = res.data.abbreviation1;
                  currentRow['abbreviation2'] = res.data.abbreviation2;
                  currentRow['prefectures'] = res.data.prefectures;
                  currentRow['city'] = res.data.city;
                  currentRow['street'] = res.data.street;
                  currentRow['building'] = res.data.building;
                  newRowField[index] = currentRow;
                  return newRowField;
                });
              })
              .catch((err) => {
                const message = err.response.data.message;
                alert(
                  `データ登録に失敗しました。[${err.status}]\n\n${message}`
                );
                console.error(err);
              });
          }}
        />
      )}
    </div>
  );
};

export const Abbreviation = ({
  index,
  value,
  value2,
  setValues,
  flgReadOnly = false,
  handleValueChange,
}: TableItemTextProps & { value2: string }) => {
  return (
    <FlexBox gapSize={1}>
      <TableCellTextField
        props={{
          sx: { maxWidth: '100px' },
          InputProps: { readOnly: flgReadOnly },
          name: 'waypoints.' + index + '.abbreviation1',
          value: value,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            handleValueChange(
              e.target.value,
              index,
              'abbreviation1',
              setValues
            );
          },
        }}
      />
      <TableCellTextField
        props={{
          InputProps: { readOnly: flgReadOnly },
          name: 'waypoints.' + index + '.abbreviation2',
          value: value2,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            handleValueChange(
              e.target.value,
              index,
              'abbreviation2',
              setValues
            );
          },
        }}
      />
    </FlexBox>
  );
};
interface TableItemTextProps {
  index: number;
  value: string | null;
  setValues: React.Dispatch<React.SetStateAction<tWaypoints>>;
  handleValueChange: (
    value: typeHashValue,
    rowField: number,
    columnField: string,
    setState: React.Dispatch<React.SetStateAction<tWaypoints>>
  ) => void;
  flgReadOnly?: boolean;
}

const City = ({
  index,
  value,
  setValues,
  handleValueChange,
  flgReadOnly = false,
}: TableItemTextProps) => {
  return (
    <TableCellTextField
      props={{
        InputProps: { readOnly: flgReadOnly },
        name: 'waypoints.' + index + '.city',
        value: value,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          handleValueChange(e.target.value, index, 'city', setValues);
        },
      }}
    />
  );
};

const Street = ({
  index,
  value,
  setValues,
  handleValueChange,
  flgReadOnly = false,
}: TableItemTextProps) => {
  return (
    <TableCellTextField
      props={{
        InputProps: { readOnly: flgReadOnly },
        name: 'waypoints.' + index + '.street',
        value: value,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          handleValueChange(e.target.value, index, 'street', setValues);
        },
      }}
    />
  );
};

const Building = ({
  index,
  value,
  setValues,
  handleValueChange,
  flgReadOnly = false,
}: TableItemTextProps) => {
  return (
    <TableCellTextField
      props={{
        InputProps: { readOnly: flgReadOnly },
        name: 'waypoints.' + index + '.building',
        value: value,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          handleValueChange(e.target.value, index, 'building', setValues);
        },
      }}
    />
  );
};

const WaypointMemo = ({
  index,
  value,
  setValues,
  handleValueChange,
  flgReadOnly = false,
}: TableItemTextProps) => {
  return (
    <TableCellTextField
      props={{
        InputProps: { readOnly: flgReadOnly },
        name: 'waypoints.' + index + '.memo',
        value: value,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          handleValueChange(e.target.value, index, 'memo', setValues);
        },
      }}
    />
  );
};

/**
 * 積地・卸地のテーブルを表示する
 * @param param0
 * @returns
 */
export const ViewTable = ({ values }: { values: tWaypoints }) => {
  return (
    <LoadTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="kbn">区分</TableCell>
            <TableCell className="datetime">日付</TableCell>
            <TableCell className="a_id">名称</TableCell>
            <TableCell className="a_id">名称</TableCell>
            <TableCell className="prefectures">都道府県</TableCell>
            <TableCell className="city">市区町村</TableCell>
            <TableCell className="street">番地</TableCell>
            <TableCell className="building">建屋・その他</TableCell>
            <TableCell className="memo">メモ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((value, index) => (
            <TableRow key={`project-waypoints-${index}`}>
              <TableCell>
                <Typography>
                  {
                    waypointKbn.find(
                      (option) => option.id === Number(value.kbn)
                    )?.label
                  }
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>{strDateCustom(value.datetime)}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{value.a_name}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{value.prefectures}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{value.city}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{value.street}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{value.building}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{value.memo}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </LoadTableContainer>
  );
};
