import * as CustomGrid from 'atoms/Grid';
import { GridItemCheckbox } from 'components/GridItem';
import * as TextItems from 'components/input/Text';
import { handleChangeValues } from 'functions/handles';
import React from 'react';
import { tRole } from 'types/mst';

interface GridItemFieldProps {
  values: tRole;
  setValues: React.Dispatch<React.SetStateAction<tRole>>;
}

export const Name = ({ values, setValues }: GridItemFieldProps) => (
  <CustomGrid.GridItem size={{ sx: 12, sm: 6, lg: 4, xl: 3 }}>
    <TextItems.Name
      value={values.name}
      cbValueChange={(val: string) => {
        handleChangeValues(val, 'name', setValues);
      }}
    />
  </CustomGrid.GridItem>
);

export const FlgAdmin = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemCheckbox
    size={{ xs: 4, sm: 3, md: 2, lg: 1, xl: 1 }}
    label="管理者"
    name={'admin'}
    value={values.admin}
    handleChangeValues={(val: boolean) => {
      handleChangeValues(val, 'admin', setValues);
    }}
  />
);

export const FlgProject = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemCheckbox
    size={{ xs: 4, sm: 3, md: 2, lg: 1, xl: 1 }}
    label="案件"
    name={'project'}
    value={values.project}
    handleChangeValues={(val: boolean) => {
      handleChangeValues(val, 'project', setValues);
    }}
  />
);

export const FlgOperation = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemCheckbox
    size={{ xs: 4, sm: 3, md: 2, lg: 1, xl: 1 }}
    label="配車"
    name={'operation'}
    value={values.operation}
    handleChangeValues={(val: boolean) => {
      handleChangeValues(val, 'operation', setValues);
    }}
  />
);

export const FlgPaperwork = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemCheckbox
    size={{ xs: 4, sm: 3, md: 2, lg: 1, xl: 1 }}
    label="事務"
    name={'paperwork'}
    value={values.paperwork}
    handleChangeValues={(val: boolean) => {
      handleChangeValues(val, 'paperwork', setValues);
    }}
  />
);

export const FlgMst = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemCheckbox
    size={{ xs: 4, sm: 3, md: 2, lg: 1, xl: 1 }}
    label="マスタ"
    name={'mst'}
    value={values.mst}
    handleChangeValues={(val: boolean) => {
      handleChangeValues(val, 'mst', setValues);
    }}
  />
);

export const FlgDriver = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemCheckbox
    size={{ xs: 4, sm: 3, md: 2, lg: 1, xl: 1 }}
    label="ドライバー"
    name={'driver'}
    value={values.driver}
    handleChangeValues={(val: boolean) => {
      handleChangeValues(val, 'driver', setValues);
    }}
  />
);
