/**
 * 入力項目別のコンポーネントを作成する
 */
import * as CustomGrid from 'atoms/Grid';
import * as GridItem from 'components/GridItem';
import * as AutoComplete from 'components/input/AutoComplete';
import { handleChangeValues } from 'functions/handles';
import React from 'react';
import { ListItem } from 'types';
import { tCustomInvoiceEdit } from 'types/invoice';

interface GridItemFieldProps {
  values: tCustomInvoiceEdit;
  setValues: React.Dispatch<React.SetStateAction<tCustomInvoiceEdit>>;
  size?: Record<string, number>;
}

/**
 * 日付
 * @param param0
 * @returns
 */
export const Date = ({ values, setValues }: GridItemFieldProps) => (
  <GridItem.GridItemDateField
    label="日付"
    name="date"
    value={values.date}
    handleChangeValues={(val: string) => {
      handleChangeValues(val, 'date', setValues);
    }}
  />
);

/**
 * 品名
 * @param param0
 * @returns
 */
export const Label = ({ values, setValues }: GridItemFieldProps) => (
  <GridItem.GridItemTextField
    label="品名"
    name="label"
    value={values.label}
    handleChangeValues={(val: string) => {
      handleChangeValues(val, 'label', setValues);
    }}
  />
);

export const ClientCompany = ({
  values,
  setValues,
  size = { xs: 12, sm: 6, md: 4, lg: 3, xl: 3 },
}: GridItemFieldProps) => (
  <CustomGrid.GridItem size={size}>
    <AutoComplete.ClientCompany
      label={'取引先'}
      value={{ id: values.c_id || null, label: '' }}
      cbValueChange={(val: ListItem) => {
        handleChangeValues(val.id, 'c_id', setValues);
      }}
    />
  </CustomGrid.GridItem>
);

/**
 * 数量
 * @param param0
 * @returns
 */
export const Quantity = ({ values, setValues }: GridItemFieldProps) => (
  <GridItem.GridItemNumberField
    label="数量"
    name="quantity"
    value={values.quantity}
    cbHandleChange={(val: number) => {
      handleChangeValues(val, 'quantity', setValues);
    }}
  />
);

/**
 * 単位
 * @param param0
 * @returns
 */
export const Unit = ({ values, setValues }: GridItemFieldProps) => (
  <GridItem.GridItemTextField
    label="単位"
    name="unit"
    value={values.unit}
    handleChangeValues={(val: string) => {
      handleChangeValues(val, 'unit', setValues);
    }}
  />
);

/**
 * 単価
 * @param param0
 * @returns
 */
export const UnitPrice = ({ values, setValues }: GridItemFieldProps) => (
  <GridItem.GridItemNumberField
    label="単価"
    name="unit_price"
    value={values.unit_price}
    cbHandleChange={(val: number) => {
      handleChangeValues(val, 'unit_price', setValues);
    }}
  />
);

/**
 * 金額
 * @param param0
 * @returns
 */
export const Price = ({ values, setValues }: GridItemFieldProps) => (
  <GridItem.GridItemNumberField
    label="金額"
    name="price"
    value={values.price}
    cbHandleChange={(val: number) => {
      handleChangeValues(val, 'price', setValues);
    }}
    disabled={true}
  />
);

/**
 * 税込金額
 * @param param0
 * @returns
 */
export const PriceTax = ({ values, setValues }: GridItemFieldProps) => (
  <GridItem.GridItemNumberField
    label="税額"
    name="price_tax"
    value={values.price_tax}
    cbHandleChange={(val: number) => {
      handleChangeValues(val, 'price_tax', setValues);
    }}
    disabled={true}
  />
);

/**
 * 税込金額
 * @param param0
 * @returns
 */
export const PriceTaxInclude = ({ values, setValues }: GridItemFieldProps) => (
  <GridItem.GridItemNumberField
    label="税込金額"
    name="price_tax_include"
    value={values.price_tax_include}
    cbHandleChange={(val: number) => {
      handleChangeValues(val, 'price_tax_include', setValues);
    }}
    disabled={true}
  />
);

/**
 * メモ
 * @param param0
 * @returns
 */
export const Memo = ({ values, setValues }: GridItemFieldProps) => (
  <GridItem.GridItemTextField
    label="メモ"
    name="memo"
    value={values.memo}
    handleChangeValues={(val: string) => {
      handleChangeValues(val, 'memo', setValues);
    }}
  />
);
