import { Grid, GridProps } from '@mui/material';
import React from 'react';

interface GridItemProps {
  size?: Record<string, number> | null;
  props?: GridProps | null;
  children: React.ReactNode;
}

export const GridItem = ({
  size = null,
  props = null,
  children,
}: GridItemProps) => {
  if (size === null) {
    size = { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 };
  }
  return (
    <Grid item {...size} {...props}>
      {children}
    </Grid>
  );
};
