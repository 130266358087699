import { Grid } from '@mui/material';
import * as InstButton from 'components/instruction/Button';
import * as PJButton from 'components/project/Button';
import PJDetail from 'components/project/Detail';
import { WaypointsTable } from 'components/project/Waypoint';
import PriceDetails from 'components/projectPriceDetail/Detail';
import { MstDataContext } from 'contexts/Mst';
import ContentFrame from 'frames/ContentsFrame';
import { getProject } from 'functions/api/project';
import log from 'functions/logger';
import { initPriceDetails } from 'functions/project/index';
import { convertLocalDateToUTC } from 'functions/time';
import { useObjectList } from 'functions/useReducer/customHook';
import { useContext, useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  initProject,
  initWaypoints,
  tProject,
  tProjectPriceDetail,
  tWaypoints,
} from 'types/project';
import { tObjectListReturn } from 'types/useReducer';

export default function Main() {
  const location = useLocation();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { SYSTEM, loginUser } = useContext(MstDataContext);

  const defKind = Number(searchParams.get('kind')) ?? 0;
  const tempProjectInfo = {
    ...initProject,
    domain: loginUser.domain,
    kind: defKind,
    tax_id: SYSTEM?.tax.default.id || 0,
    in_charge: loginUser.id,
  };

  const [pj, setPj] = useState<tProject>(tempProjectInfo);

  // 積卸地
  const defDate = searchParams.get('date') ?? '';
  const [initCustomWp, setInitCustomWp] = useState<tWaypoints>(initWaypoints);
  const [wp, setWp] = useState<tWaypoints>(initWaypoints);
  useEffect(() => {
    if (defDate) {
      log.debug('defDate:', convertLocalDateToUTC(defDate).toISOString());
      const initCustomWaypoints = initWaypoints.map((v) => {
        return {
          ...v,
          datetime: convertLocalDateToUTC(defDate).toISOString(),
        };
      });
      log.debug('initCustomWaypoints:', initCustomWaypoints);
      setInitCustomWp(initCustomWaypoints);
    }
  }, [defDate]);

  useEffect(() => {
    setWp(initCustomWp);
  }, [initCustomWp]);

  const initCustomPriceDetails = initPriceDetails(SYSTEM);

  const pd = useObjectList<tProjectPriceDetail>(initCustomPriceDetails);

  /**
   * 案件情報取得処理
   * @param id
   */
  const getPj = (id: string | number) => {
    /**
     * コールバック：案件情報取得
     * @param data
     */
    const cbsGetProject = (data: tProject) => {
      setPj(data);
      setWp(data.waypoints || initCustomWp);
      pd.setList(data.price_details || initCustomPriceDetails);
    };

    /**
     * コールバック：案件情報取得エラー
     * @param error
     */
    const cbeGetproject = (error: any) => {
      console.error(error);
      alert('案件情報の取得に失敗しました。');
    };

    getProject(id, cbsGetProject, cbeGetproject);
  };

  /**
   * プロジェクト情報取得
   */
  useEffect(() => {
    if (id) {
      getPj(id);
    } else {
      tempProjectInfo.status = 10;
      setPj(tempProjectInfo);
      setWp(initCustomWp);
      pd.setList(initCustomPriceDetails);
    }
  }, [id]);

  useEffect(() => {
    // ページがマウントまたはパスが変更されたときに初期化
    setPj(tempProjectInfo);
    setWp(initCustomWp);
    pd.setList(initCustomPriceDetails);
  }, [location.pathname]);

  return (
    <ContentFrame
      HeadContent={
        <HeadContent
          id={Number(id)}
          pj={pj}
          wp={wp}
          pd={pd}
          setPj={setPj}
          setWp={setWp}
        />
      }
      MainContent={
        <MainContent pj={pj} setPJ={setPj} wp={wp} setWP={setWp} pd={pd} />
      }
      SubContent={<SubContent />}
    />
  );
}

interface HeadContentProps {
  id: number;
  pj: tProject;
  wp: tWaypoints;
  setPj: React.Dispatch<React.SetStateAction<tProject>>;
  setWp: React.Dispatch<React.SetStateAction<tWaypoints>>;
  pd: tObjectListReturn<tProjectPriceDetail>;
}
const HeadContent = ({ id, pj, wp, pd, setPj, setWp }: HeadContentProps) => {
  const navigate = useNavigate();
  return (
    <>
      {id ? (
        <>
          <PJButton.Update
            projectInfo={pj}
            waypoints={wp}
            priceDetails={pd.list}
            callbackNomal={() => navigate('/project')}
            callbackError={(data: tProject) => {
              setPj(data);
              setWp(data.waypoints);
              if (data.price_details) pd.setList(data.price_details);
            }}
          />
          <InstButton.ModalInstruction pjId={Number(id)} />
          <PJButton.Delete
            pjId={id}
            callbackNomal={() => navigate('/project')}
          />
        </>
      ) : (
        <>
          <PJButton.Insert
            label="登録"
            projectInfo={pj}
            waypoints={wp}
            priceDetails={pd.list}
            callbackNomal={() => navigate('/project')}
            callbackError={(data: tProject) => {
              setPj(data);
              setWp(data.waypoints);
              if (data.price_details) pd.setList(data.price_details);
            }}
          />
          <PJButton.Insert
            label="続けて登録"
            projectInfo={pj}
            waypoints={wp}
            callbackNomal={(data: tProject) => navigate(`/project/${data.id}`)}
          />
        </>
      )}
    </>
  );
};

interface MainContentProps {
  pj: tProject;
  wp: tWaypoints;
  pd: tObjectListReturn<tProjectPriceDetail>;
  setPJ: React.Dispatch<React.SetStateAction<tProject>>;
  setWP: React.Dispatch<React.SetStateAction<tWaypoints>>;
}
function MainContent({ pj, wp, pd, setPJ, setWP }: MainContentProps) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PJDetail pj={pj} setPj={setPJ}>
          {/* 項目とメモの間に挿入 */}
          <Grid item xs={12}>
            <WaypointsTable label="積地・卸地" values={wp} setValues={setWP} />
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <PriceDetails values={pd} />
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </PJDetail>
      </Grid>
    </Grid>
  );
}

const SubContent = () => {
  return <></>;
};
