export type ObjectAction<T> =
  | { type: 'SET_LIST'; items: T[] }
  | { type: 'ADD'; item: T }
  | { type: 'UPDATE'; index: number; item: Partial<T> }
  | { type: 'REMOVE'; index: number }
  | { type: 'CLEAR' };

const objectReducer = <T>(state: T[], action: ObjectAction<T>): T[] => {
  switch (action.type) {
    case 'SET_LIST':
      return action.items;

    case 'ADD':
      return [...state, action.item];

    case 'UPDATE':
      return state.map((obj, index) => {
        if (index === action.index) {
          return { ...obj, ...action.item }; // 指定したインデックスのみ更新
        }
        return obj;
      });

    case 'REMOVE':
      // index番目の要素を削除
      return state.filter((_, index) => index !== action.index);

    case 'CLEAR':
      return [];

    default:
      return state;
  }
};

export default objectReducer;
