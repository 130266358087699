import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import * as cPPD from 'components/projectPriceDetail/Index';
import { MstDataContext } from 'contexts/Mst';
import { useContext } from 'react';
import { tProjectPriceDetail } from 'types/project';
import { tObjectListReturn } from 'types/useReducer';

// 経費を入力するモーダルのコンテンツ
interface MainProps {
  values: tObjectListReturn<tProjectPriceDetail>;
}

const Main = ({ values }: MainProps) => {
  const { SYSTEM } = useContext(MstDataContext);

  return (
    <TableContainer>
      <Typography>金額明細</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>区分</TableCell>
            <TableCell>金額</TableCell>
            <TableCell>税率</TableCell>
            <TableCell>備考</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.list.map((price, index) => (
            <TableRow key={index}>
              <TableCell>
                <cPPD.KbnLabel value={price.kbn} />
              </TableCell>
              <TableCell>
                <cPPD.Price
                  label=""
                  value={price.price}
                  cbValueChange={(val) => {
                    values.updateItem(index, { price: val });
                    values.updateItem(index, {
                      price_unit: val / price.quantity,
                    });
                  }}
                />
              </TableCell>
              <TableCell>
                <cPPD.Tax
                  label=""
                  value={price.tax_id}
                  cbValueChange={(val) =>
                    values.updateItem(index, { tax_id: val })
                  }
                />
              </TableCell>
              <TableCell>
                <cPPD.Memo
                  label=""
                  value={price.memo}
                  cbValueChange={(val: string) =>
                    values.updateItem(index, { memo: val })
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Main;
