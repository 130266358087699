import { TableCell, TableRow } from '@mui/material';
import { SecondButton } from 'atoms/Button';
import * as CompanyBtn from 'components/company/Button';
import PageNation from 'components/Pagenation';
import { initCompanySearch } from 'const/comapny';
import TableFrame from 'frames/TableFrame';
import { getCompanies } from 'functions/api/mst';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { tCompany, tCompanySearch } from 'types/company';
import { apiGetIndexSuccess, tOrder } from 'types/index';

export default function Main() {
  const [companies, setCompanies] = useState<tCompany[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [search, setSearch] = useState<tCompanySearch>(initCompanySearch);
  const [orders, setOrders] = useState<tOrder[]>([]);

  const callbackGetCompanies = ({
    data,
    currentPage,
    lastPage,
    filter,
  }: apiGetIndexSuccess) => {
    setCompanies(data);
    setCurrentPage(currentPage);
    setLastPage(lastPage);
    setSearch(filter);
  };

  // 検索処理
  const getSearch = (page: number) => {
    try {
      // ここにデータ取得処理を記述します
      getCompanies({
        page: page,
        filter: search,
        order: orders,
        callbackSuccess: callbackGetCompanies,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSearch(currentPage);
  }, []);

  return (
    <TableFrame
      HeadContent={
        <HeadContent
          setData={setCompanies}
          setCurrentPage={setCurrentPage}
          setLastPage={setLastPage}
          setFilter={setSearch}
          setOrders={setOrders}
        />
      }
      TableHeaderRows={<TableHeaderRows />}
      TableBodyRows={<TableBodyRows companies={companies} />}
      SubContent={
        <SubContent
          lastPages={lastPage}
          currentPage={currentPage}
          getSearch={getSearch}
        />
      }
    />
  );
}

interface HeadContentProps {
  setData: React.Dispatch<React.SetStateAction<tCompany[]>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setLastPage: React.Dispatch<React.SetStateAction<number>>;
  setFilter: React.Dispatch<React.SetStateAction<tCompanySearch>>;
  setOrders: React.Dispatch<React.SetStateAction<tOrder[]>>;
}
const HeadContent = ({
  setData,
  setCurrentPage,
  setLastPage,
  setFilter,
  setOrders,
}: HeadContentProps) => {
  const navigate = useNavigate();
  return (
    <>
      <CompanyBtn.SearchBtn
        setData={setData}
        setCurrentPage={setCurrentPage}
        setLastPage={setLastPage}
        setFilter={setFilter}
        setOrders={setOrders}
      />
      <SecondButton
        onClick={() => {
          navigate('/mst/company/input');
        }}
        label="新規"
      />
    </>
  );
};

const TableHeaderRows = () => {
  return (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>会社名</TableCell>
      <TableCell>電話番号</TableCell>
      <TableCell>傭車利用</TableCell>
    </TableRow>
  );
};

interface TableBodyRowsProps {
  companies: tCompany[];
}
const TableBodyRows = ({ companies }: TableBodyRowsProps) => {
  const navigate = useNavigate();
  return (
    <>
      {companies.map((company) => {
        return (
          <TableRow
            key={`companies-${company.id}`}
            onDoubleClick={() => navigate(`/mst/company/edit/${company.id}`)}
          >
            <TableCell>{company.id}</TableCell>
            <TableCell>{company.name}</TableCell>
            <TableCell>{company.tell}</TableCell>
            <TableCell>{company.flg_use}</TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

interface SubContentProps {
  lastPages: number;
  currentPage: number;
  getSearch: (page: number) => void;
}
const SubContent = ({ lastPages, currentPage, getSearch }: SubContentProps) => {
  return (
    <PageNation
      totalPages={lastPages}
      currentPage={currentPage}
      handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
        getSearch(value)
      }
    />
  );
};
