import { tProjectPriceDetail } from 'types/project';

export const initProjectPriceDetail: tProjectPriceDetail = {
  id: 0,
  pj_id: 0,
  kbn: 0,
  name: '',
  price_unit: 1,
  quantity: 1,
  price: 0,
  tax_id: 0,
  price_tax: 0,
  memo: '',
};
