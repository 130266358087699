import { API_URL, create } from 'functions/axios';
import log from 'functions/logger';
import { apiGetIndexProps, apiListProps } from 'types/index';
import { tPjMemo, tProject, tWaypoints } from 'types/project';
/**
 * 案件情報登録
 * @returns
 */
export function storeProject(project: tProject) {
  const ins = create();
  return ins.post(API_URL.base + '/project/store', project);
}

/**
 * 案件情報取得
 * @returns
 */
export function getProjects(page: number, filter: any = {}, order: any = {}) {
  const ins = create();
  return ins.get(API_URL.base + '/project/index', {
    params: { page: page, filter: filter, order: order },
  });
}

/**
 * 案件情報取得
 * @returns
 */
export function getProjectList(
  filter: any = {},
  order: any = {},
  withs: any = []
) {
  const ins = create();
  return ins.get(API_URL.base + '/project/list', {
    params: { filter: filter, order: order, withs: withs },
  });
}

/**
 * 案件情報取得
 * @returns
 */
export function getProject(
  id: string | number,
  callbackSuccess?: (date: tProject) => void,
  callbackError?: (error: any) => void
) {
  const ins = create();
  return ins.get(API_URL.base + '/project/show/' + id).then(
    (res) => {
      if (res.status !== 200) {
        throw new Error('データの取得に失敗しました');
      }
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    },
    (error) => {
      log.debug('project.ts getProject Error', error);
      if (callbackError) {
        callbackError(error);
      }
    }
  );
}

/**
 * 案件情報更新チェック処理
 * @returns
 */
export function checkUpdateProject(
  projectInfo: tProject,
  waypoints: tWaypoints
) {
  const ins = create();
  return ins.post(API_URL.base + '/project/update/check/' + projectInfo.id, {
    projectInfo: projectInfo,
    waypoints: waypoints,
  });
}

/**
 * 案件情報更新
 * @returns
 */
export function updateProject(project: tProject) {
  const ins = create();
  return ins.post(API_URL.base + '/project/update/' + project.id, project);
}

/**
 * 案件情報ステータス更新
 * @returns
 */
export function updateProjectStatus(
  p_id: tProject['id'],
  status: tProject['status']
) {
  const ins = create();
  return ins.post(API_URL.base + '/project/update/status', {
    id: p_id,
    status: status,
  });
}

/**
 * 案件情報登録
 * @returns
 */
export function deleteProject(id: number) {
  const ins = create();
  return ins.post(API_URL.base + '/project/destroy/' + id);
}

/**
 * 案件情報取得
 * @returns
 */
export function getProjectCards(
  page: number,
  filter: any = {},
  order: any = {}
) {
  const ins = create();
  return ins.get(API_URL.base + '/project/card/index', {
    params: { page: page, filter: filter, order: order },
  });
}

/**
 * 案件情報取得
 * @returns
 */
export function getProjectCardList(filter: any = {}, order: any = {}) {
  const ins = create();
  return ins.get(API_URL.base + '/project/card/list', {
    params: { filter: filter, order: order },
  });
}

/**
 *
 *
 *
 *
 *
 */

/**
 * 案件情報登録
 * @returns
 */
export function storePjMemo(
  data: tPjMemo,
  callbackSuccess?: ((data: tPjMemo) => void) | undefined,
  callbackError?: (() => void) | undefined
) {
  const ins = create();
  return ins
    .post(API_URL.base + '/pj-memo/store', data)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの登録に失敗しました');
      }
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      if (callbackError) {
        callbackError();
      }
      alert('データの登録に失敗しました');
    });
}

/**
 * 案件情報取得
 * @returns
 */
export function getPjMemos(props: apiGetIndexProps) {
  const { page, filter, order, withs, callbackSuccess, callbackError } = props;
  const ins = create();
  return ins
    .get(API_URL.base + '/pj-memo/index', {
      params: { page: page, filter: filter, order: order, withs: withs },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データ取得に成功しました');
      }
      if (callbackSuccess) {
        callbackSuccess({
          data: res.data,
          currentPage: res.data.current_page,
          lastPage: res.data.last_page,
          filter: filter,
        });
      }
      return;
    })
    .catch((err) => {
      console.error(err);
      if (callbackError) {
        callbackError();
      }
      alert('データの取得に失敗しました');
    });
}

/**
 * 案件情報取得
 * @returns
 */
export function getPjMemoList(props: apiListProps) {
  const { filter, order, withs, callbackSuccess, callbackError } = props;
  const ins = create();
  return ins
    .get(API_URL.base + '/pj-memo/list', {
      params: { filter: filter, order: order, withs: withs },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データ取得に成功しました');
      }
      if (callbackSuccess) {
        callbackSuccess({ data: res.data, filter: filter });
      }
      return;
    })
    .catch((err) => {
      console.error(err);
      if (callbackError) {
        callbackError();
      }
      alert('データの取得に失敗しました');
    });
}

/**
 * 案件情報取得
 * @returns
 */
export function getPjMemo(
  id: string | number,
  callbackSuccess?: (date: tProject) => void,
  callbackError?: (error: any) => void
) {
  const ins = create();
  return ins.get(API_URL.base + '/pj-memo/show/' + id).then(
    (res) => {
      if (res.status !== 200) {
        throw new Error('データの取得に失敗しました');
      }
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    },
    (error) => {
      log.debug('project.ts getProject Error', error);
      if (callbackError) {
        callbackError(error);
      }
    }
  );
}

/**
 * 案件情報更新
 * @returns
 */
export function updatePjMemo(
  data: tPjMemo,
  callbackSuccess?: ((data: tPjMemo) => void) | undefined,
  callbackError?: (() => void) | undefined
) {
  const ins = create();
  return ins
    .post(API_URL.base + '/pj-memo/update/' + data.id, data)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの更新に失敗しました');
      }
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      alert('データの更新に失敗しました');
      if (callbackError) {
        callbackError();
      }
    });
}

/**
 * 案件情報登録
 * @returns
 */
export function deletePjMemo(
  id: tPjMemo['id'],
  callbackSuccess?: ((data: tPjMemo) => void) | undefined,
  callbackError?: (() => void) | undefined
) {
  if (!id) {
    return;
  }
  const ins = create();
  ins
    .post(API_URL.base + '/pj-memo/destroy/' + id)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの削除に失敗しました');
      }
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      alert('データの削除に失敗しました');
      if (callbackError) {
        callbackError();
      }
    });
}
