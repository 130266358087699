import { changeDateToStrDay } from 'functions/time';
import { tCustomInvoiceEdit, tInvoice } from 'types/invoice';

export const initCustomInvoiceEdit: tCustomInvoiceEdit = {
  id: undefined,
  label: '',
  status: 0,
  c_id: undefined,
  date: changeDateToStrDay(new Date()),
  unit: '',
  unit_price: 0,
  quantity: 0,
  price: 0,
  tax_id: undefined,
  price_tax: 0,
  memo: '',
  price_tax_include: 0,
};

export const initInvoice: tInvoice = {
  id: undefined,
  invoice_number: '',
  title: '',
  print_date: null,
  total_amount: 0,
  tax: 0,
  billing_amount: 0,
  detail_count: 0,
  c_id: undefined,
  c_invoice_no: '',
  c_name: '',
  c_postal_code: '',
  c_address1: '',
  c_address2: '',
  pj_in_charge_name: '',
  details: [],
};
