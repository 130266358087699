import {
  GridItemCheckbox,
  GridItemTextField,
  GridItemTimeField,
} from 'components/GridItem';
import { handleChangeValues } from 'functions/handles';
import React from 'react';
import { tTransportMethod } from 'types/mst';

interface GridItemFieldProps {
  values: tTransportMethod;
  setValues: React.Dispatch<React.SetStateAction<tTransportMethod>>;
}
export const Name = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="名称"
    name="name"
    value={values.name}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'name', setValues)
    }
  />
);

export const Abbreviation = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="略称"
    name="abbreviation"
    value={values.abbreviation}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'abbreviation', setValues)
    }
  />
);

export const ChipName = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="チップ名"
    name="name_chip"
    value={values.name_chip}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'name_chip', setValues)
    }
  />
);

export const FlgOn = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemCheckbox
    label="スイッチ"
    name="flg_on"
    value={values.flg_on}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: boolean) =>
      handleChangeValues(val, 'flg_on', setValues)
    }
  />
);

export const StartTime = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTimeField
    label="開始時刻"
    name="start_time"
    value={values.start_time || ''}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'start_time', setValues)
    }
  />
);

export const EndTime = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTimeField
    label="終了時刻"
    name="end_time"
    value={values.end_time || ''}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'end_time', setValues)
    }
  />
);
