import { API_URL, create } from 'functions/axios';
import { tTransport } from 'types/transport';

export function getTransports(page: number, filter: any = {}, order: any = []) {
  if (order.length === 0) {
    order = [
      { field: 'pj_no', direction: 'asc' },
      { field: 'no', direction: 'asc' },
    ];
  }
  const ins = create();
  return ins.get(API_URL.base + '/transport/index', {
    params: { page: page, filter: filter, order: order },
  });
}

export function getTransportList(filter: any = {}, order: any = []) {
  if (order.length === 0) {
    order = [
      { field: 'pj_id', direction: 'asc' },
      { field: 'no', direction: 'asc' },
    ];
  }
  const ins = create();
  return ins.get(API_URL.base + '/transport/list', {
    params: { filter: filter, order: order },
  });
}

export function getTransport(
  id: number,
  callbackSuccess?: (date: tTransport) => void,
  callbackError?: (error: any) => void
) {
  const ins = create();
  return ins.get(`${API_URL.base}/transport/show/${id}`, {}).then(
    (res) => {
      if (res.status !== 200) {
        throw new Error('データの取得に失敗しました');
      }
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    },
    (error) => {
      if (callbackError) {
        callbackError(error);
      }
    }
  );
}

export function getTransportByParams(
  pj_id: number | undefined,
  no: number | undefined,
  callbackSuccess?: (date: tTransport) => void,
  callbackError?: (error: any) => void
) {
  const ins = create();
  return ins
    .get(API_URL.base + '/transport/show/none', {
      params: { pj_id: pj_id, no: no },
    })
    .then(
      (res) => {
        if (res.status !== 200) {
          throw new Error('データの取得に失敗しました');
        }
        if (callbackSuccess) {
          callbackSuccess(res.data);
        }
      },
      (error) => {
        if (callbackError) {
          callbackError(error);
        }
      }
    );
}

/**
 * 予定表案件情報取得
 * @returns
 */
export function getTransportCards(filter: any, order: any = []) {
  if (order.length === 0) {
    order = [
      { field: 'load_datetime', direction: 'asc' },
      { field: 'pj_id', direction: 'asc' },
    ];
  }
  const ins = create();
  return ins.get(API_URL.base + '/transport/card/list', {
    params: { filter: filter, order: order },
  });
}

/**
 * 予定表案件情報取得
 * @returns
 */
export function getTransportCard(pj_id: number, tran_no: number) {
  const ins = create();
  return ins.get(`${API_URL.base}/transport/card/show/${pj_id}/${tran_no}`);
}

/**
 * 輸送情報更新処理
 * @param param0
 * @returns
 */
export function update({
  id,
  data,
  callbackSuccess = undefined,
  callbackError = undefined,
}: {
  id: tTransport['id'];
  data: tTransport | any;
  callbackSuccess?: (data: tTransport) => void;
  callbackError?: (error: any) => void;
}) {
  const ins = create();
  return ins
    .post(`${API_URL.base}/transport/update/${id}`, data)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの更新に失敗しました');
      }
      alert('データを更新しました');
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      alert('データの更新に失敗しました');
      if (callbackError) {
        callbackError(err);
      }
    });
}

/**
 * ドライバー・着日別配車情報の輸送情報
 * @param filter
 * @param order
 * @returns
 */
export function getDriverTransportByEndDate(filter: any = {}, order: any = []) {
  if (order.length === 0) {
    order = [
      { field: 'pj_id', direction: 'asc' },
      { field: 'no', direction: 'asc' },
    ];
  }
  const ins = create();
  return ins.get(API_URL.base + '/transport/list/group-enddate-driver', {
    params: { filter: filter, order: order },
  });
}
