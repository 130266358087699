import arrayReducer from 'functions/useReducer/array';
import keyedObjectReducer from 'functions/useReducer/hashTable';
import objectReducer from 'functions/useReducer/objectList';
import { useReducer } from 'react';

// 通常の配列用
export const useArrayList = <T>(initialState: T[] = []) => {
  const [state, dispatch] = useReducer(arrayReducer<T>, initialState);

  return {
    list: state,
    addItem: (item: T) => dispatch({ type: 'ADD', item }),
    updateItem: (index: number, item: T) =>
      dispatch({ type: 'UPDATE', index, item }),
    removeItem: (index: number) => dispatch({ type: 'REMOVE', index }),
    clearList: () => dispatch({ type: 'CLEAR' }),
  };
};

/**
 * 連想配列用のカスタムフック
 * @param initialState
 * @returns
 */
export const useKeyedObject = <T>(initialState: Record<string, T> = {}) => {
  const [list, dispatch] = useReducer(keyedObjectReducer<T>, initialState);

  return {
    list,
    setItem: (key: string, value: T) => dispatch({ type: 'SET', key, value }),
    updateItem: (key: string, value: Partial<T>) =>
      dispatch({ type: 'UPDATE', key, value }),
    removeItem: (key: string) => dispatch({ type: 'REMOVE', key }),
    clearList: () => dispatch({ type: 'CLEAR' }),
  };
};

// 連想配列の配列（オブジェクトの配列）用
export const useObjectList = <T>(initialState: T[] = []) => {
  const [state, dispatch] = useReducer(objectReducer<T>, initialState);

  return {
    list: state,
    setList: (items: T[]) => dispatch({ type: 'SET_LIST', items }),
    addItem: (item: T) => dispatch({ type: 'ADD', item }),
    updateItem: (index: number, item: Partial<T>) =>
      dispatch({ type: 'UPDATE', index, item }), // ✅ `index` を追加
    removeItem: (index: number) => dispatch({ type: 'REMOVE', index }),
    clearList: () => dispatch({ type: 'CLEAR' }),
  };
};
