import { TableCell, TableRow } from '@mui/material';
import { SecondButton } from 'atoms/Button';
import PageNation from 'components/Pagenation';
import * as BtnUser from 'components/user/Button';
import { initSearchUser } from 'const/user';
import { MstDataContext } from 'contexts/Mst';
import TableFrame from 'frames/TableFrame';
import * as apiMst from 'functions/api/mst';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiGetIndexSuccess } from 'types/index';
import { tUser, tUserSearch } from 'types/mst';

export default function Main() {
  const [list, setList] = useState<tUser[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [search, setSearch] = useState<tUserSearch>(initSearchUser);

  const cbs = ({ data, currentPage, lastPage, filter }: apiGetIndexSuccess) => {
    setList(data);
    setCurrentPage(currentPage);
    setLastPage(lastPage);
    setSearch(filter);
  };

  // 検索処理
  const getSearch = (page: number) => {
    try {
      // ここにデータ取得処理を記述します
      apiMst.getUsers({
        page: page,
        filter: search,
        callbackSuccess: cbs,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSearch(currentPage);
  }, []);

  return (
    <TableFrame
      HeadContent={
        <HeadContent
          setData={setList}
          setCurrentPage={setCurrentPage}
          setLastPage={setLastPage}
          setFilter={setSearch}
        />
      }
      TableHeaderRows={<TableHeaderRows />}
      TableBodyRows={<TableBodyRows list={list} />}
      SubContent={
        <SubContent
          lastPages={lastPage}
          currentPage={currentPage}
          getSearch={getSearch}
        />
      }
    />
  );
}

interface HeadContentProps {
  setData: React.Dispatch<React.SetStateAction<tUser[]>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setLastPage: React.Dispatch<React.SetStateAction<number>>;
  setFilter: React.Dispatch<React.SetStateAction<tUserSearch>>;
}
const HeadContent = (props: HeadContentProps) => {
  const navigate = useNavigate();
  return (
    <>
      <BtnUser.SearchBtn {...props} />
      <SecondButton
        onClick={() => {
          navigate('/mst/user/input');
        }}
        label="新規"
      />
    </>
  );
};

const TableHeaderRows = () => {
  return (
    <TableRow>
      <TableCell>名前</TableCell>
      <TableCell>デフォルト車両</TableCell>
      <TableCell>権限</TableCell>
    </TableRow>
  );
};

interface TableBodyRowsProps {
  list: tUser[];
}
const TableBodyRows = ({ list }: TableBodyRowsProps) => {
  const navigate = useNavigate();

  const { roles } = useContext(MstDataContext);
  return (
    <>
      {list.map((user) => {
        const role = roles?.find((role) => role.id === user.role_id);
        return (
          <TableRow
            key={`users-${user.id}`}
            onDoubleClick={() => navigate(`/mst/user/edit/${user.id}`)}
          >
            <TableCell>{user.family_name + ' ' + user.given_name}</TableCell>
            <TableCell>{user.vehicle?.plate}</TableCell>
            <TableCell>{role?.name}</TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

interface SubContentProps {
  lastPages: number;
  currentPage: number;
  getSearch: (page: number) => void;
}
const SubContent = ({ lastPages, currentPage, getSearch }: SubContentProps) => {
  return (
    <PageNation
      totalPages={lastPages}
      currentPage={currentPage}
      handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
        getSearch(value)
      }
    />
  );
};
