import { DeleteButton, PrimaryButton } from 'atoms/Button';
import ErrModal from 'components/error/Modal';
import * as apiMst from 'functions/api/mst';
import { changeToState } from 'functions/error/';
import { useState } from 'react';
import { tCompany } from 'types/company';
import { tErrMsg } from 'types/index';
import { tPerson } from 'types/mst';

interface StoreProps {
  person: tPerson;
  cbs?: (data: tCompany) => void;
  cbe?: (error: any) => void;
}
export const Store = ({ person, cbs, cbe }: StoreProps) => {
  const [showModal, setShowModal] = useState(false);
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  const handleClick = () => {
    apiMst
      .storeCompanyPerson(person)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ登録に失敗しました');
        alert('データを登録しました');
        if (cbs) cbs(res.data);
      })
      .catch((err) => {
        setValidationMsg(changeToState(err));
        setShowModal(true); // エラーがある場合モーダル表示
        if (cbe) cbe(err);
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <PrimaryButton label="登録" onClick={handleClick} />
    </>
  );
};

export const Update = ({ person, cbs, cbe }: StoreProps) => {
  const [showModal, setShowModal] = useState(false);
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  const handleClick = () => {
    apiMst
      .updateCompanyPerson(person)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ更新に失敗しました');
        alert('データを更新しました');
        if (cbs) cbs(res.data);
      })
      .catch((err) => {
        setValidationMsg(changeToState(err));
        setShowModal(true); // エラーがある場合モーダル表示
        if (cbe) cbe(err);
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <PrimaryButton label="更新" onClick={handleClick} />
    </>
  );
};

interface DeleteProps {
  id: tPerson['id'];
  cbs?: (data: tPerson) => void;
  cbe?: (error: any) => void;
}
export const Delete = ({ id, cbs, cbe }: DeleteProps) => {
  const [showModal, setShowModal] = useState(false);
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  const handleClick = () => {
    if (!id) return;
    apiMst
      .deleteCompanyPerson(id)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ更新に失敗しました');
        alert('データを更新しました');
        if (cbs) cbs(res.data);
      })
      .catch((err) => {
        setValidationMsg(changeToState(err));
        setShowModal(true); // エラーがある場合モーダル表示
        if (cbe) cbe(err);
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <DeleteButton label="削除" onClick={handleClick} />
    </>
  );
};
