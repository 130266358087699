import * as CustomGrid from 'atoms/Grid';
import { GridItemCheckbox, GridItemRowTextField } from 'components/GridItem';
import * as TextItems from 'components/input/Text';
import { handleChangeValues } from 'functions/handles';
import React from 'react';
import { tVehicleType } from 'types/mst';

interface GridItemFieldProps {
  values: tVehicleType;
  setValues: React.Dispatch<React.SetStateAction<tVehicleType>>;
}
export const Name = ({ values, setValues }: GridItemFieldProps) => (
  <CustomGrid.GridItem size={{ xs: 12, lg: 6, xl: 5 }}>
    <TextItems.Name
      value={values.name}
      cbValueChange={(val: string) => {
        handleChangeValues(val, 'name', setValues);
      }}
    />
  </CustomGrid.GridItem>
);

export const FlgTrailer = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemCheckbox
    label="非牽引車"
    name="flg_trailer"
    value={values.flg_trailer}
    handleChangeValues={(val: boolean) => {
      handleChangeValues(val, 'flg_trailer', setValues);
    }}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Description = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemRowTextField
    label="説明文"
    name="description"
    value={values.description}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'description', setValues)
    }
    size={{ xs: 12, lg: 6, xl: 5 }}
  />
);
/*
export const CmId = ({ values, setValues }: GridItemFieldProps) => {
  const { loading, carModels } = useContext(MstDataContext);
  if (loading || !carModels) return <Typography>loading...</Typography>;

  const options = carModels.map((cm) => ({
    id: cm.id,
    label: cm.name,
  }));
  const selectedOption = options.find(
    (option: any) => option.id === values.cm_id
  );

  return (
    <GridItemTextFieldCustom size={{ xs: 12, lg: 3, xl: 2 }}>
      <AutocompleteSelect
        name="cm_id"
        label={"車種"}
        options={options}
        value={selectedOption || null}
        onChange={(e: React.ChangeEvent<{}>, newValue: ListItem) =>
          handleChangeValues(newValue.id, "cm_id", setValues)
        }
      />
    </GridItemTextFieldCustom>
  );
};
*/
