import { tProjectKind, tProjectKindSearch } from 'types/mst';

export const initProjectKind: tProjectKind = {
  id: 0,
  name: '',
  color: '',
  order: 10,
};

export const initSearchProjectKind: tProjectKindSearch = {
  name: '',
  freewords: '',
};
