import { Grid } from '@mui/material';
import { SmallCheckbox } from 'atoms/Checkbox';
import { DateInput } from 'atoms/DateInput';
import * as CustomGrid from 'atoms/Grid';
import * as SelectItems from 'atoms/Select';
import { NumberField, RowTextField, SmallTextField } from 'atoms/TextField';
import { getGridSize } from 'functions/index';
import React from 'react';
import { ListItem, typeHashValue } from 'types';

//////////////////////////////////////// textfield

interface GridItemTextFieldProps {
  name: string;
  label: string;
  value: typeHashValue;
  handleChangeValues: (event: string) => void;
  size?: Record<string, number> | null;
}
export function GridItemTextField({
  name,
  label,
  value,
  handleChangeValues,
  size = { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 },
}: GridItemTextFieldProps) {
  const [xs, sm, md, lg, xl] = getGridSize(size);
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <SmallTextField
        label={label}
        props={{
          name: name,
          value: value,
          onChange: (e) => handleChangeValues(e.target.value),
        }}
      />
    </Grid>
  );
}

interface GridItemRowTextFieldProps {
  name: string;
  label: string;
  value: string;
  handleChangeValues: (event: string) => void;
  size?: Record<string, number> | null;
  row?: number;
}
export function GridItemRowTextField({
  name,
  label,
  value,
  handleChangeValues,
  size = { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 },
  row = 4,
}: GridItemRowTextFieldProps) {
  const [xs, sm, md, lg, xl] = getGridSize(size);
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <RowTextField
        label={label}
        name={name}
        value={value}
        handleChangeValues={(e) => handleChangeValues(e.target.value)}
        row={row}
      />
    </Grid>
  );
}

export interface GridItemNumberProps {
  name: string;
  label: string;
  value: number | undefined;
  cbHandleChange?: (val: number) => void;
  children?: React.ReactNode;
  size?: Record<string, number> | null;
  flgFloat?: boolean;
  disabled?: boolean;
}
export function GridItemNumberField({
  name,
  label,
  value,
  cbHandleChange,
  size = { xs: 4, lg: 1.5, xl: 1 },
  flgFloat = false,
  disabled = false,
}: GridItemNumberProps) {
  const [xs, sm, md, lg, xl] = getGridSize(size);
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <NumberField
        name={name}
        label={label}
        value={value}
        cbHandleChangeValues={cbHandleChange}
        disabled={disabled}
        flgFloat={flgFloat}
      />
    </Grid>
  );
}

interface GridItemDateFieldProps {
  name: string;
  label: string;
  value: string;
  handleChangeValues: (value: string) => void;
  size?: Record<string, number> | null;
}
export function GridItemDateField({
  name,
  label,
  value,
  handleChangeValues,
  size = undefined,
}: GridItemDateFieldProps) {
  const [xs, sm, md, lg, xl] = getGridSize(size);

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <DateInput
        type="date"
        label={label}
        value={value as string}
        name={name}
        onDateChange={handleChangeValues}
      />
    </Grid>
  );
}

interface GridItemTimeFieldProps {
  name: string;
  label: string;
  value: string;
  handleChangeValues: (event: string) => void;
  size?: Record<string, number> | null;
}
export const GridItemTimeField = ({
  name,
  label,
  value,
  handleChangeValues,
  size = undefined,
}: GridItemTimeFieldProps) => {
  const [xs, sm, md, lg, xl] = getGridSize(size);

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <SmallTextField
        label={label}
        props={{
          type: 'time',
          name: name,
          value: value,
          onChange: (e) => handleChangeValues(e.target.value),
        }}
      />
    </Grid>
  );
};

export function GridItemTextFieldCustom({
  children,
  size,
}: {
  children: React.ReactNode;
  size?: Record<string, number> | null;
}) {
  const [xs, sm, md, lg, xl] = getGridSize(size);
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      {children}
    </Grid>
  );
}

//////////////////////////////////////// checkbox
interface GridItemCheckboxProps {
  name: string;
  label: string;
  value: boolean;
  handleChangeValues: (event: boolean) => void;
  children?: React.ReactNode;
  size?: Record<string, number> | null;
}
export function GridItemCheckbox({
  name,
  label,
  value,
  handleChangeValues,
  size,
}: GridItemCheckboxProps) {
  const [xs, sm, md, lg, xl] = getGridSize(size);
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <SmallCheckbox
        label={label}
        props={{
          name: name,
          checked: value,
          onChange: (e) => handleChangeValues(e.target.checked),
        }}
      />
    </Grid>
  );
}

export const GridItemSelect = ({
  name,
  label,
  value,
  items,
  size,
  cbValueChange,
}: {
  name: string;
  label: string;
  value: number | null;
  items: ListItem[];
  cbValueChange: (val: ListItem['id']) => void;
  size?: Record<string, number> | null;
}) => {
  const [xs, sm, md, lg, xl] = getGridSize(size);
  return (
    <CustomGrid.GridItem size={{ xs: xs, sm: sm, md: md, lg: lg, xl: xl }}>
      <SelectItems.SmallSelect
        name={name}
        label={label}
        value={value}
        items={items}
        cbValueChange={cbValueChange}
      />
    </CustomGrid.GridItem>
  );
};
