import { Grid, Typography } from '@mui/material';
import { FlexColumnBox } from 'atoms/Box';
import * as CustomGrid from 'atoms/Grid';
import * as TextItems from 'components/input/Text';
import * as BtnProjectKind from 'components/projectKind/Button';
import { initProjectKind } from 'const/projectKind';
import ContentsFrame from 'frames/ContentsFrame';
import { getProjectKind } from 'functions/api/mst';
import { handleChangeValues } from 'functions/handles';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { tProjectKind } from 'types/mst';

export default function Main() {
  const { id } = useParams();
  const [luggage, setProjectKind] = useState<tProjectKind>(initProjectKind);

  useEffect(() => {
    const fetchData = async (id: number) => {
      try {
        //await getCsrf();
        getProjectKind(id)
          .then((res) => {
            if (res.status !== 200) throw new Error('データ取得に失敗しました');
            setProjectKind(res.data);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (error) {
        console.error(error);
      }
    };
    if (id) {
      fetchData(Number(id));
    }
  }, [id]);

  return (
    <ContentsFrame
      HeadContent={<HeadContent id={id} values={luggage} />}
      MainContent={<Details values={luggage} setValues={setProjectKind} />}
      SubContent={<></>}
    />
  );
}

interface HeadContentProps {
  id: string | undefined;
  values: tProjectKind;
}
const HeadContent = ({ id, values }: HeadContentProps) => {
  return (
    <>
      {id ? (
        <>
          <BtnProjectKind.Update data={values} />
          <BtnProjectKind.Delete id={id as unknown as number} />
        </>
      ) : (
        <>
          <BtnProjectKind.Store data={values} />
        </>
      )}
    </>
  );
};

interface DetailsProps {
  values: tProjectKind;
  setValues: React.Dispatch<React.SetStateAction<tProjectKind>>;
}
const Details = ({ values, setValues }: DetailsProps) => {
  const { id } = useParams();

  return (
    <FlexColumnBox>
      {id && (
        <Typography variant="h4">
          ID:
          <Typography component={'span'} variant="h4" sx={{ fontWeight: 700 }}>
            {values.id}
          </Typography>
        </Typography>
      )}
      <Grid container spacing={2}>
        <CustomGrid.GridItem>
          <TextItems.Name
            value={values.name}
            cbValueChange={(val: string) => {
              handleChangeValues(val, 'name', setValues);
            }}
          />
        </CustomGrid.GridItem>
      </Grid>
    </FlexColumnBox>
  );
};
