import { GridItemRowTextField, GridItemTextField } from 'components/GridItem';
import { handleChangeValues } from 'functions/handles';
import React from 'react';
import { tSelfInformation } from 'types/mst';

interface GridItemFieldProps {
  values: tSelfInformation;
  setValues: React.Dispatch<React.SetStateAction<tSelfInformation>>;
}
export const Name = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="会社名"
    name="name"
    value={values.name}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'name', setValues)
    }
  />
);

export const Tel = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="TEL"
    name="tel"
    value={values.tel}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'tel', setValues)
    }
  />
);

export const Fax = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="FAX"
    name="fax"
    value={values.fax}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'fax', setValues)
    }
  />
);

export const CorporateNumber = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="法人番号"
    name="corporate_number"
    value={values.corporate_number}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'corporate_number', setValues)
    }
  />
);

export const Address1 = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="住所"
    name="address1"
    value={values.address1}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'address1', setValues)
    }
  />
);

export const Address2 = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="住所"
    name="address2"
    value={values.address2}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'address2', setValues)
    }
  />
);

export const PostNumber = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="郵便番号"
    name="postal_code"
    value={values.postal_code}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'postal_code', setValues)
    }
  />
);

export const BillingInfo = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemRowTextField
    label="口座情報"
    name="billing_info"
    value={values.billing_info || ''}
    size={{ xs: 6, lg: 3, xl: 2 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'billing_info', setValues)
    }
  />
);

export const Notes = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemRowTextField
    label="備考"
    name="notes"
    value={values.notes || ''}
    size={{ xs: 12, lg: 6, xl: 4 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'notes', setValues)
    }
  />
);
