import {
  Box,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FlexBox, FlexColumnBox } from 'atoms/Box';
import { PrimaryButton, SecondButton } from 'atoms/Button';
import LoadingCircular from 'atoms/Loading';
import Modal from 'atoms/Modal';
import * as ClosingComponent from 'components/closing/Index';
import PageNation from 'components/Pagenation';
import PJModal from 'components/project/Modal';
import { closingDateTypeUnload } from 'const/comapny/closingDateType';
import { waypointKbnLoad, waypointKbnUnload } from 'const/index';
import { closingStatus } from 'const/project/index';
import { MstDataContext } from 'contexts/Mst';
import { getCompany, getCompanyPerson } from 'functions/api/mst';
import { getInvoice, storeInvoice } from 'functions/api/paperwork';
import { getProjects } from 'functions/api/project';
import { formatNumber } from 'functions/index';
import { calculateTotalPrice } from 'functions/project/validate';
import {
  formatMonthDay,
  formatYearMonth,
  getSameDayPreviousMonth,
  mathDateTime,
} from 'functions/time';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PDFDownload, PDFPreview } from 'sheets/invoice/type01';
import { tAddress } from 'types/address';
import { tCompany } from 'types/company';
import { List } from 'types/index';
import { tInvoice, tInvoiceDetail } from 'types/invoice';
import { tPerson } from 'types/mst';
import { tProject } from 'types/project';

type tCompanyCustom = tCompany & { address: tAddress };

export default function Main() {
  const { c_id, p_id } = useParams();
  const { loading, selfInfomation, SYSTEM } = useContext(MstDataContext);

  const navigate = useNavigate();
  const theme = useTheme();
  const [company, setCompany] = useState<tCompanyCustom>();
  const [person, setPerson] = useState<tPerson | null>(null);
  const [projects, setProjects] = useState<tProject[]>();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [invoice, setInvoice] = useState<tInvoice>();
  const [selectedProjectId, setSelectedProjectId] = useState<number[]>([]);
  const [projectMemo, setProjctMemo] = useState<List>([]);

  const [sheetModal, setSheetModal] = useState(false);
  const [sheetName, setSheetName] = useState('');
  const [selectedInvoice, setSelectedInvoice] = useState<tInvoice>();
  const [details, setDetails] = useState<tInvoiceDetail[]>([]);

  const [selectedPjId, setSelectedPjId] = useState<tProject['id']>(undefined);

  const closingStatusIds = closingStatus.map((status) => status.id);

  // データ取得処理
  const getSearch = async (page: number) => {
    let filter: { c_id: number; status: number | number[]; p_id?: number } = {
      c_id: Number(c_id),
      status: closingStatusIds,
    };

    if (p_id && p_id !== '0') {
      getCompanyPerson(Number(p_id)).then((res: { data: tPerson }) => {
        setPerson(res.data);
      });

      // filterに担当者IDを追加
      filter = {
        ...filter,
        p_id: Number(p_id),
      };
    } else {
      setPerson(null);
    }

    getCompany(Number(c_id)).then((res: { data: tCompanyCustom }) => {
      setCompany(res.data);

      getProjects(page, filter, [
        {
          field: 'load_datetime',
          direction: 'asc',
        },
        {
          field: 'unload_datetime',
          direction: 'asc',
        },
      ]).then(
        (res: {
          data: { data: tProject[]; current_page: number; last_page: number };
        }) => {
          setProjects(res.data.data);
          setCurrentPage(res.data.current_page);
          setTotalPages(res.data.last_page);
        }
      );
    });
  };

  const handleSelectedProject = (project: tProject) => {
    if (!project.id) return;
    // seslectedProjectIdに含まれているかを判定
    if (selectedProjectId.includes(project.id)) {
      setSelectedProjectId((prev: number[]) => {
        return prev.filter((id) => id !== project.id);
      });
    } else {
      setSelectedProjectId((prev: number[]) => {
        if (!project.id) return prev;
        return [...prev, project.id];
      });
    }
  };

  /**
   * 締め処理ボタンクリック時の処理
   */
  const handleClosingClick = () => {
    if (selectedProjectId.length === 0) {
      alert('案件を選択してください。');
      return;
    }

    const details = selectedProjectId.map((pj_id: number) => {
      const memoItem = projectMemo[pj_id]; // キーが存在するかチェック
      if (memoItem) {
        return { pj_id: pj_id, memo: memoItem }; // メモが見つかった場合の処理
      } else {
        return { pj_id: pj_id, memo: '' };
      }
    });

    const params = {
      invoice: invoice,
      details: details,
    };

    const callbackStore = (data: tInvoice) => {
      if (!data.id) {
        alert('請求書の作成に失敗しました。');
        return;
      }

      // 画面更新
      getSearch(1);

      const callbackGet = (data: tInvoice) => {
        setSelectedInvoice(data);
        setDetails(data.details || []);
        setSheetModal(true);
        setSheetName(`請求書_${data.invoice_number}.pdf`);
      };

      const callbackError = () => {
        alert('請求書の作成に失敗しました。');
        setSelectedInvoice(undefined);
        setDetails([]);
        setSheetModal(false);
        setSheetName(``);
      };

      // 請求書を発行するかを確認
      if (
        window.confirm(
          '請求書データ作成に成功しました。¥nこのまま請求書を発行しますか？'
        )
      ) {
        getInvoice(data.id, callbackGet, callbackError);
      } else {
        navigate(`/paperwork/closing`);
      }
    };

    const callbackError = () => {
      alert('請求書の作成に失敗しました。');
    };

    storeInvoice(params, callbackStore, callbackError);
  };

  useEffect(() => {
    getSearch(currentPage);
  }, [currentPage]);

  /**
   * 取得した案件情報を元に、締め日による案件選択を行う
   */
  useEffect(() => {
    if (!company || !projects) {
      return;
    }
    if (!company.closing_date) {
      return;
    }

    const closingDate = getSameDayPreviousMonth(company.closing_date);
    // 締め日の一月前の翌日を取得
    const closingDateFrom = mathDateTime(
      getSameDayPreviousMonth(company.closing_date, closingDate),
      [0, 0, 1, 0, 0, 0]
    );

    const title = `${formatYearMonth(closingDate)}分（${formatMonthDay(
      closingDateFrom
    )}〜${formatMonthDay(closingDate)}）`;

    const { totalPrice, totalPriceTax } = calculateTotalPrice(projects);

    const tempInvoice: tInvoice = {
      id: 0,
      invoice_number: '',
      title: `${title}`,
      total_amount: totalPrice,
      tax: totalPriceTax,
      billing_amount: totalPrice + totalPriceTax,
      detail_count: projects.length,
      c_id: company.id,
      c_invoice_no: company.invoice_no,
      c_name: company.name,
      c_postal_code: company.post_number,
      c_address1: company.address1,
      c_address2: company.address2,
      pj_in_charge_name: '',
    };

    // 担当者名を取得
    if (person) {
      tempInvoice.pj_in_charge_name = `${person.family_name} ${person.given_name}`;
    }

    // 今月の会社マスタ.締め日を取得
    const thisClosingDate = getSameDayPreviousMonth(company.closing_date);

    setSelectedProjectId([]); // 初期化
    projects.map((project: tProject) => {
      let targetDate = project.load_datetime;
      if (company.closing_date_type === closingDateTypeUnload.id) {
        targetDate = project.unload_datetime;
      }

      // 会社マスタに従った締め日以降の案件は選択対象外
      if (new Date(targetDate) > thisClosingDate) {
        setSelectedProjectId((prev: number[]) => {
          return prev.filter((id) => id !== project.id);
        });
      } else if (project.price_sum_detail === 0) {
        // 金額が0円の案件は選択対象外
        setSelectedProjectId((prev: number[]) => {
          return prev.filter((id) => id !== project.id);
        });
      } else {
        // 選択対象に追加
        setSelectedProjectId((prev: number[]) => {
          if (!project.id) return prev;
          return [...prev, project.id];
        });
      }
    });

    setInvoice(tempInvoice);
  }, [projects, company]);

  if (loading || !selfInfomation || !company || !projects || !invoice) {
    return <LoadingCircular flg={true} />;
  }

  return (
    <FlexColumnBox gapSize={6}>
      <Modal
        width={'xl'}
        open={sheetModal}
        onClose={() => setSheetModal(false)}
        title={sheetName}
        actions={
          <>
            <SecondButton
              onClick={() => {
                setSheetModal(false);
                navigate(`/paperwork/closing`);
              }}
              label="閉じる"
            />
            {selectedInvoice && details && (
              <PDFDownload
                invoice={selectedInvoice}
                details={details}
                mycompany={selfInfomation}
                name={sheetName}
              />
            )}
          </>
        }
      >
        {selectedInvoice && details && (
          <PDFPreview
            invoice={selectedInvoice}
            details={details}
            mycompany={selfInfomation}
          />
        )}
      </Modal>

      <PJModal
        open={selectedPjId ? true : false}
        onClose={() => {
          setSelectedPjId(undefined);
        }}
        pjId={selectedPjId}
        callbackNomal={() => {
          setSelectedPjId(undefined);
          getSearch(currentPage);
        }}
      />
      <FlexBox>
        <Box sx={{ display: 'flex', flexFlow: 'row' }}>
          <PrimaryButton onClick={handleClosingClick} label="締め処理" />
        </Box>
      </FlexBox>

      <FlexBox>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{invoice.c_name}</Typography>
          </Grid>
          <ClosingComponent.CompanyPostalCode
            values={invoice}
            setValues={setInvoice}
          />
          <ClosingComponent.CompanyAddress1
            values={invoice}
            setValues={setInvoice}
          />
          <ClosingComponent.CompanyAddress2
            values={invoice}
            setValues={setInvoice}
          />
          {p_id && p_id !== '0' && (
            <ClosingComponent.ProjectInChargeName
              values={invoice}
              setValues={setInvoice}
            />
          )}
          <Grid item xs={12} md={6}>
            <TableContainer
              component={Paper}
              sx={{ maxWidth: theme.breakpoints.values.sm }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>合計</TableCell>
                    <TableCell>消費税額</TableCell>
                    <TableCell>請求金額</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography className="number">
                        {formatNumber(String(invoice.total_amount))}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="number">
                        {formatNumber(String(invoice.tax))}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="number">
                        {formatNumber(String(invoice.billing_amount))}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </FlexBox>

      <FlexBox>
        {projects ? (
          <TableContainer component={Paper} sx={{ margin: '0 auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>対象</TableCell>
                  <TableCell>日付</TableCell>
                  <TableCell>車番</TableCell>
                  <TableCell>シャーシ</TableCell>
                  <TableCell>発地</TableCell>
                  <TableCell>着地</TableCell>
                  <TableCell>品名</TableCell>
                  <TableCell>輸送量</TableCell>
                  <TableCell>金額</TableCell>
                  <TableCell>割増料金</TableCell>
                  <TableCell>その他料金</TableCell>
                  <TableCell>備考</TableCell>
                  <TableCell>案件管理番号</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projects.map((project) => {
                  if (!project.id) return null;

                  // 積卸地点を区分で分ける
                  const load = project.waypoints.filter((waypoint) => {
                    return waypoint.kbn === waypointKbnLoad.id;
                  });
                  const unload = project.waypoints.filter((waypoint) => {
                    return waypoint.kbn === waypointKbnUnload.id;
                  });

                  return (
                    <TableRow
                      key={`project-list-${project.id}`}
                      onDoubleClick={() => setSelectedPjId(project.id)}
                    >
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Checkbox
                          checked={selectedProjectId.includes(project.id)}
                          onChange={() => handleSelectedProject(project)}
                        />
                      </TableCell>
                      <TableCell>
                        {company.closing_date_type === closingDateTypeUnload.id
                          ? `${formatMonthDay(new Date(project.unload_datetime))}`
                          : `${formatMonthDay(new Date(project.load_datetime))}`}
                      </TableCell>
                      <TableCell>
                        {project.vehicle_name}
                        {project.v_id && `(${project.v_id})`}
                      </TableCell>
                      <TableCell>
                        {project.trailer_name}
                        {project.trailer_v_id && `(${project.trailer_v_id})`}
                      </TableCell>
                      {/* 発地 */}
                      <TableCell>
                        {load.map((waypoint, index) => {
                          if (load.length === index + 1) {
                            return `${waypoint.abbreviation1}`;
                          } else {
                            return `${waypoint.abbreviation1}/`;
                          }
                        })}
                      </TableCell>
                      {/* 着地 */}
                      <TableCell>
                        {unload.map((waypoint, index) => {
                          if (unload.length === index + 1) {
                            return `${waypoint.abbreviation1}`;
                          } else {
                            return `${waypoint.abbreviation1}/`;
                          }
                        })}
                      </TableCell>
                      {/* 品名 */}
                      <TableCell>
                        {project.l_name}
                        {project.l_id && `(${project.l_id})`}
                      </TableCell>
                      {/* 輸送量 */}
                      <TableCell>{project.weight}</TableCell>
                      {/* 金額 */}
                      <TableCell>
                        {formatNumber(String(project.price_sum_detail))}
                      </TableCell>
                      {/* 割増料金 */}
                      <TableCell>
                        {formatNumber(String(project.price_sum_separate))}
                      </TableCell>
                      {/* その他料金 */}
                      <TableCell>
                        {formatNumber(String(project.price_sum_other))}
                      </TableCell>
                      {/* 備考 */}
                      <TableCell>
                        <ClosingComponent.ProjectMemo
                          values={projectMemo}
                          setValues={setProjctMemo}
                          pj_id={project.id}
                        />
                      </TableCell>
                      {/* 案件管理番号 */}
                      <TableCell>{project.id}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <PageNation
              totalPages={totalPages}
              currentPage={currentPage}
              handlePageChange={(
                e: React.ChangeEvent<unknown>,
                value: number
              ) => getSearch(value)}
            />
          </TableContainer>
        ) : (
          <Typography>案件情報はありません</Typography>
        )}
      </FlexBox>
    </FlexColumnBox>
  );
}
