import { tCompany, tCompanySearch } from 'types/company';
import { ListItem, tOrder } from 'types/index';

export const initCompany: tCompany = {
  id: undefined,
  name: '',
  abbreviation: '',
  abbreviation_kana: '',
  office_a_id: undefined,
  post_number: '',
  tell: '',
  fax: '',
  e_mail: '',
  president_position: '',
  president_name: '',
  flg_use: false,
  flg_order: false,
  flg_supplier: false,
  closing_date: 31,
  closing_date_type: 2,
  flg_billing_person: false,
  invoice_no: '',
  address1: '',
  address2: '',
  internal_memo: '',
};

export const initCompanySearch: tCompanySearch = {
  freewords: '',
};

/*
export const initCompanyOrder: tOrder = {
  field: 'id',
  direction: 'asc',
};
*/

export const orderOptions: Array<ListItem & { order: tOrder }> = [
  { id: 1, label: 'ID（昇順）', order: { field: 'id', direction: 'asc' } },
  { id: 2, label: 'ID（降順）', order: { field: 'id', direction: 'desc' } },
  {
    id: 3,
    label: '略称カナ（昇順）',
    order: { field: 'abbreviation_kana', direction: 'asc' },
  },
  {
    id: 4,
    label: '略称カナ（降順）',
    order: { field: 'abbreviation_kana', direction: 'desc' },
  },
];
