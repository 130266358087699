import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import deepmerge from 'deepmerge';

export const palette = {
  primary: '#ed7700',
  secondary: '#73a7d1',
  black: '#222222',
  white: '#FFFFFF',
  glay: '#f5f5f5',
  glayDark: '#a9a9a9',
  red: '#ff0000',
  blue: '#0000ff',
  yellow: '#ffff00',
};

export const weekPalette: Record<string, string> = {
  Monday: 'rgba(255, 211, 44)',
  Tuesday: '#F06B44',
  Wednesday: '#5D7FCC',
  Thursday: '#5DAA66',
  Friday: '#CCAA33',
  Saturday: '#8B673D',
  Sunday: '#FF873F',
};

// 全体のテーマ設定
const base = createTheme({
  palette: {
    primary: {
      main: palette.primary,
      contrastText: palette.white,
    },
    secondary: {
      main: palette.secondary,
      contrastText: palette.white,
    },
    warning: {
      main: palette.yellow,
    },
    action: {
      disabled: '#9e9e9e', // ボタンの無効時の背景色やアイコンの色
      disabledBackground: '#e0e0e0', // ボタンの無効時の背景色
    },
    text: {
      primary: palette.black,
      secondary: palette.white,
      disabled: '#bdbdbd', // 無効なテキストの色
    },
    background: {
      default: palette.white,
    },
    common: {
      black: palette.black,
      white: palette.white,
    },
  },
  typography: {
    h1: {
      fontSize: '2.0rem',
    },
    h2: {
      fontSize: '1.8rem',
    },
    h3: {
      fontSize: '1.6rem',
    },
    h4: {
      fontSize: '1.4rem',
    },
    h5: {
      fontSize: '1.2rem',
    },
    h6: {
      fontSize: '1.0rem',
    },
    body1: {
      fontSize: '1.0rem',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const themeBase = createTheme(
  deepmerge(base, {
    ...base,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          // スクロールバー非表示の設定
          '*': {
            scrollbarWidth: 'none', // Firefox用
            msOverflowStyle: 'none', // IE用
          },
          '*::-webkit-scrollbar': {
            display: 'none', // Chrome, Safari用
          },
          body: {
            whiteSpace: 'normal', // 明示的に指定/
          },
          main: {
            height: 'calc(100vh - 40px)',
            display: 'flex',
            justifyContent: 'space-between',
            flexGrow: 1 /* 残りの横幅を均等に分配する */,
            flexBasis: 0,
            minWidth: 0 /* 必要に応じて、文字がはみ出ないようにするために設定 */,
            overflow: 'scroll',
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            height: '40px',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: palette.white,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: palette.primary,
            '&.Mui-checked': {
              color: palette.primary,
            },
            '&.color-second': {
              color: palette.white,
              '&.Mui-checked': {
                color: palette.white,
              },
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            whiteSpace: 'nowrap',
            '&.number': {
              textAlign: 'right',
            },
            '&.whiteSpace-preLine': {
              whiteSpace: 'pre-line',
            },
            '&.chip': {
              padding: '1px',
              fontSize: '0.5rem',
              borderRadius: '2px',
              '&.evening': {
                backgroundColor: 'rgb(168, 88, 168)',
                color: base.palette.common.white,
              },
              '&.waypoints': {
                backgroundColor: base.palette.primary.main,
                color: base.palette.common.white,
              },
              '&.vehicle': {
                backgroundColor: 'rgb(93, 170, 102)',
                color: base.palette.common.white,
              },
              '&.transport-method': {
                backgroundColor: 'rgb(93, 127, 204)',
                color: base.palette.common.white,

                '&.transport-method-2': {
                  backgroundColor: 'rgb(255, 105, 180)',
                },
              },
              '&.warning-level': {
                backgroundColor: 'rgb(255, 0, 0)',
                color: base.palette.common.white,
                animation: 'flash 1s infinite',
                '& .wl-1': {
                  '@keyframes flash': {
                    '0%': { opacity: 1 },
                    '30%': { opacity: 0.4 },
                    '60%': { opacity: 1 },
                    '100%': { opacity: 1 },
                  },
                },
              },
              '&.unload-day': {
                border: '1px solid red',
                backgroundColor: base.palette.common.white,
                color: palette.red,
              },
              '&.tran-key': {
                backgroundColor: palette.glayDark,
                color: base.palette.common.white,
              },
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputLabel-root': {
              color: palette.primary,
            },
            /*
          "& .MuiInputBase-root": {
            color: color.primary,
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: color.primary,
          },
          */
            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.primary,
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                borderColor: palette.primary,
              },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            '&.Mui-disabled': {
              color: base.palette.text.disabled, // 無効時のテキスト色
              backgroundColor: base.palette.action.disabled, // 無効時の背景色
            },
            '&.selected': {
              backgroundColor: base.palette.primary.main,
              color: base.palette.common.white,
            },
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {},
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            position: 'sticky',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          head: {
            '& .MuiTableCell-head:first-child': {
              zIndex: 99,
            },
          },
          root: {
            '&.removing': {
              transition: 'height 0.5s ease, opacity 0.5s ease',
              height: '0',
              padding: '0',
              margin: '0',
              opacity: 0,
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            position: 'sticky',
            left: 0,
            zIndex: 9,
            backgroundColor: palette.primary,
            color: palette.white,
          },
          root: {
            border: `1px solid ${palette.black}`,
            padding: base.spacing(1),
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.primary,
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                borderColor: palette.primary,
              },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            '&.card-custom': {
              padding: '0.25rem',
              overflow: 'hidden',
              margin: 4,
              position: 'relative',
              '& *': {
                fontSize: '0.6rem',
                //position: 'relative', // 重なり順を変えるため
                zIndex: 1,
              },
              '&.blank': {
                opacity: 0.8,
                boxShadow: 'none',
                border: `1px dashed ${base.palette.common.black}`,
              },
              '&.isover': {
                border: `none`,
                backgroundColor: base.palette.primary.main,
                color: base.palette.common.white,
              },
              '& .stamp': {
                position: 'absolute',
                // 中央表示
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 0, // 一番下に表示

                // 透明にする
                opacity: 0.7,

                // 文字のサイズなど
                width: '30px',
                heiht: '30px',
                textAlign: 'center',
                lineHeight: '30px',
                borderRadius: '50%',
                fontSize: 'larger',
                fontWeight: 'bold',

                '&.primary': {
                  backgroundColor: `${base.palette.primary.main}`,
                  color: `${base.palette.common.white}`,
                },
                '&.secondary': {
                  backgroundColor: `${base.palette.secondary.main}`,
                  color: `${base.palette.common.white}`,
                },
              },
              '& .attribute-chip': {
                display: 'flex',
                gap: '0.1rem',
                flexFlow: 'row wrap',
                position: 'absolute',
                px: '0.1rem',
                right: 5,
                bottom: 2,
                '& > .chip': {
                  display: 'inline-flex',
                  minWidth: '14px',
                  height: '14px',
                  alignItems: 'center' /* 縦方向の中央揃え */,
                  justifyContent: 'center' /* 横方向の中央揃え */,
                  /*
                  backgroundColor: base.palette.primary.main,
                  color: base.palette.common.white,
                  */
                },

                '&.unload-day': {
                  borderRadius: '2px',
                  position: 'absolute',
                  marginLeft: '0.5rem',
                  top: '-1px',
                  right: '-22px',
                },
              },
              '& .key-chip': {
                display: 'flex',
                gap: '0.1rem',
                flexFlow: 'row wrap',
                position: 'absolute',
                borderRadius: '2px',
                px: '0.1rem',
              },
            },
            '&.type-line': {
              /*height: '35px',*/
              width: '95%',
              maxWidth: '400px',
              '&.omit': {
                height: '15px',
              },
              '& .contents': {
                display: 'flex',
                flexFlow: 'row nowrap',
                '& >*:not(:last-child)::after ': {
                  content: '"→"', // ここで矢印をダブルクォートで囲む,
                  margin: '0 2px' /* 矢印の左右にスペースを追加 */,
                },
              },
              '& .attribute-chip': {
                right: 5,
                top: 2,
              },
              '& .key-chip': {
                right: 5,
                bottom: 2,
              },
            },
            '&.type-box': {
              height: '60px',
              //width: '47%',
              width: '160px',
              '&.size-auto': {
                height: 'auto',
                width: 'auto',
              },
              '&.omit': {
                height: '30px',
                width: '80px',
              },
              '& .attribute-chip': {
                right: 5,
                bottom: 2,
              },
              '& .key-chip': {
                right: 5,
                top: 2,
              },
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: 24,
            "&&[data-testid='ArrowCircleUpIcon']": {
              color: palette.red,
            },
            "&[data-testid='ArrowCircleDownIcon']": {
              color: palette.blue,
            },
          },
        },
      },
    },
  })
);

export default themeBase;

import { ReactNode } from 'react';

export function ThemeProviderDark({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider theme={themeBase}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export function ThemeProviderCustom({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider theme={themeBase}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export const colorSub01 = {
  primary: '#a1cff4',
  secondary: '#FFFFFF',
  background: '#222222',
};
