import * as CustomGrid from 'atoms/Grid';
import * as SelectFeild from 'atoms/Select';
import * as TextItems from 'components/input/Text';
import { handleChangeValues } from 'functions/handles';
import React from 'react';
import { tOrderOption } from 'types/index';

interface GridItemFieldProps {
  value: string | null;
  setValues: React.Dispatch<React.SetStateAction<any>>;
}

export const Freewords = ({ value, setValues }: GridItemFieldProps) => (
  <CustomGrid.GridItem size={{ xs: 12, lg: 10, xl: 8 }}>
    <TextItems.Freeword
      value={value || ''}
      cbValueChange={(val: string) => {
        handleChangeValues(val, 'freewords', setValues);
      }}
    />
  </CustomGrid.GridItem>
);

interface OrdersProps {
  values: number;
  setValues: React.Dispatch<React.SetStateAction<number[]>>;
  no: number;
  options: tOrderOption[];
}

export const Orders = ({ values, setValues, no, options }: OrdersProps) => {
  return (
    <CustomGrid.GridItem size={{ xs: 12, lg: 2, xl: 4 }}>
      <SelectFeild.SmallSelect
        label={`並び順${no.toString()}`}
        name="order"
        value={values}
        items={options}
        cbValueChange={(val) => {
          if (val === null) return;
          setValues((prev) => {
            const newValues = [...prev];
            newValues[no] = val;
            return newValues;
          });
        }}
      />
    </CustomGrid.GridItem>
  );
};
