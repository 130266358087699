import { initProjectPriceDetail } from 'const/project/priceDetail';
import { tProject, tWaypoint } from 'types/project';
import { tSYSTEM } from 'types/system';

/**
 * 取引先名取得処理
 * @param project
 * @returns
 */
export function getTorisakiName(project: tProject) {
  if (!project.company) {
    return `新規`;
  } else if (project.company.abbreviation) {
    return `${project.company.abbreviation}`;
  } else if (project.company.name) {
    return project.company.name;
  } else {
    return `新規`;
  }
}

/**
 * 積地名称
 * @param project
 * @returns
 */
export function getLoadName(project: tProject) {
  if (project.load_address?.abbreviation2) {
    return `${project.load_address?.abbreviation2}`;
  } else if (project.load_address?.name) {
    return project.load_address.name;
  } else {
    return `${project.load_prefectures}${project.load_city}`;
  }
}

/**
 * 卸地点名称
 * @param project
 * @returns
 */
export function getUnloadName(project: tProject) {
  if (project.unload_address?.abbreviation2) {
    return `${project.unload_address?.abbreviation2}`;
  } else if (project.unload_address?.name) {
    return project.unload_address.name;
  } else {
    return `${project.unload_prefectures}${project.unload_city}`;
  }
}

/**
 * 地点名
 * @param waypoint
 * @returns
 */
export function getWaypointName(waypoint: tWaypoint) {
  if (waypoint.a_name) {
    return waypoint.a_name;
  } else {
    return `${waypoint.prefectures}${waypoint.city}`;
  }
}

export const initPriceDetails = (SYSTEM: tSYSTEM | null) => {
  return [
    {
      ...initProjectPriceDetail,
      kbn: SYSTEM?.project.price_detail.detail || 1,
      tax_id: SYSTEM?.tax.default.id || 0,
    },
    {
      ...initProjectPriceDetail,
      kbn: SYSTEM?.project.price_detail.separate || 2,
      tax_id: SYSTEM?.tax.default.id || 0,
    },
    {
      ...initProjectPriceDetail,
      kbn: SYSTEM?.project.price_detail.other || 9,
      tax_id: SYSTEM?.tax.exempt.id || 0,
    },
  ];
};
