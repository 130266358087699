import { API_URL, create } from 'functions/axios';
import { tDailyMemo } from 'types/dailymemo';

/**
 * デイリーメモリスト
 * @returns
 */
export function getDailyMemoList(filter: any = {}, order: any = []) {
  if (order.length === 0) {
    order = [
      { field: 'datetime', direction: 'asc' },
      { field: 'id', direction: 'asc' },
    ];
  }
  const ins = create();
  return ins.get(API_URL.base + '/dailyMemo/list', {
    params: { filter: filter, order: order },
  });
}

/**
 *
 * @param data
 * @returns
 */
export function storeDailyMemo(data: any = {}) {
  const ins = create();
  return ins.post(API_URL.base + '/dailyMemo/store', data);
}

/**
 *
 * @param data
 * @returns
 */
export function updateDailyMemo(
  id: tDailyMemo['id'],
  data: any = {},
  callbackSuccess?: (date: tDailyMemo) => void,
  callbackError?: (error: any) => void
) {
  const ins = create();
  return ins.post(API_URL.base + '/dailyMemo/update/' + id, data).then(
    (res) => {
      if (res.status !== 200) {
        throw new Error('データの取得に失敗しました');
      }
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    },
    (error) => {
      alert('データの更新に失敗しました');
      if (callbackError) {
        callbackError(error);
      }
    }
  );
}

/**
 *
 * @param data
 * @returns
 */
export function deleteDailyMemo(id: tDailyMemo['id']) {
  const ins = create();
  return ins.post(API_URL.base + `/dailyMemo/destroy/${id}`);
}
