import { PrimaryButton } from 'atoms/Button';
import ErrModal from 'components/error/Modal';
import SearchModal from 'components/luggage/Search';
import * as apiMst from 'functions/api/mst';
import { getLuggages } from 'functions/api/mst';
import { changeToState } from 'functions/error/';
import log from 'functions/logger';
import React, { useState } from 'react';
import { apiGetIndexSuccess, tErrMsg } from 'types/index';
import { tLuggage, tLuggageSaerch } from 'types/mst';

interface SearchBtnProps {
  setData: React.Dispatch<React.SetStateAction<tLuggage[]>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setLastPage: React.Dispatch<React.SetStateAction<number>>;
  setFilter: React.Dispatch<React.SetStateAction<tLuggageSaerch>>;
}
export const SearchBtn = ({
  setData,
  setCurrentPage,
  setLastPage,
  setFilter,
}: SearchBtnProps) => {
  const [open, setOpen] = useState(false);
  const handleClickSearch = () => {
    log.debug('handleClickSearch');
    setOpen(true);
  };

  const cbs = ({ data, currentPage, lastPage, filter }: apiGetIndexSuccess) => {
    setData(data);
    setCurrentPage(currentPage);
    setLastPage(lastPage);
    setFilter(filter);
  };

  const callbackSearch = (filter: tLuggageSaerch) => {
    getLuggages({
      page: 1,
      filter: filter,
      callbackSuccess: cbs,
    });
  };

  return (
    <>
      <SearchModal
        open={open}
        setOpen={setOpen}
        callback={(data) => callbackSearch(data)}
      />
      <PrimaryButton label="検索" onClick={handleClickSearch} />
    </>
  );
};

interface StoreProps {
  data: tLuggage;
  cbs?: (data: tLuggage) => void;
  cbe?: (error: any) => void;
}
export const Store = ({ data, cbs, cbe }: StoreProps) => {
  const [showModal, setShowModal] = useState(false);
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  const handleClick = () => {
    apiMst
      .storeLuggage(data)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ登録に失敗しました');
        alert('データを登録しました');
        if (cbs) cbs(res.data);
      })
      .catch((err) => {
        setValidationMsg(changeToState(err));
        setShowModal(true); // エラーがある場合モーダル表示
        if (cbe) cbe(err);
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <PrimaryButton label="登録" onClick={handleClick} />
    </>
  );
};

export const Update = ({ data, cbs, cbe }: StoreProps) => {
  const [showModal, setShowModal] = useState(false);
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  const handleClick = () => {
    apiMst
      .updateLuggage(data)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ更新に失敗しました');
        alert('データを更新しました');
        if (cbs) cbs(res.data);
      })
      .catch((err) => {
        console.log('err', err);
        setValidationMsg(changeToState(err));
        setShowModal(true); // エラーがある場合モーダル表示
        if (cbe) cbe(err);
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <PrimaryButton label="更新" onClick={handleClick} />
    </>
  );
};

interface DeleteProps {
  id: tLuggage['id'];
  cbs?: (data: tLuggage) => void;
  cbe?: (error: any) => void;
}
export const Delete = ({ id, cbs, cbe }: DeleteProps) => {
  const [showModal, setShowModal] = useState(false);
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  const handleClick = () => {
    if (!id) return;
    apiMst
      .deleteLuggage(id)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ更新に失敗しました');
        alert('データを更新しました');
        if (cbs) cbs(res.data);
      })
      .catch((err) => {
        setValidationMsg(changeToState(err));
        setShowModal(true); // エラーがある場合モーダル表示
        if (cbe) cbe(err);
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <PrimaryButton label="削除" onClick={handleClick} />
    </>
  );
};
