import {
  GridItemNumberField,
  GridItemRowTextField,
  GridItemTextField,
} from 'components/GridItem';
import { handleChangeValues } from 'functions/handles';
import React from 'react';

interface GridItemFieldProps {
  values: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  size?: Record<string, number>;
}

export const Title = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="タイトル"
    name="title"
    value={values.title}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'title', setValues)
    }
  />
);

export const TotalAmount = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemNumberField
    label="合計金額"
    name="total_amount"
    value={values.total_amount}
    cbHandleChange={(val: number) =>
      handleChangeValues(val, 'total_amount', setValues)
    }
  />
);

export const Tax = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemNumberField
    label="消費税額"
    name="tax"
    value={values.tax}
    cbHandleChange={(val: number) => handleChangeValues(val, 'tax', setValues)}
  />
);

export const BillingAmount = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemNumberField
    label="請求金額"
    name="billing_amount"
    value={values.billing_amount}
    cbHandleChange={(val: number) =>
      handleChangeValues(val, 'billing_amount', setValues)
    }
  />
);

export const DetailCount = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemNumberField
    label="案件件数"
    name="detail_count"
    value={values.detail_count}
    cbHandleChange={(val: number) =>
      handleChangeValues(val, 'detail_count', setValues)
    }
  />
);

export const CompanyInvoiceNo = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="インボイス番号"
    name="c_invoice_no"
    value={values.c_invoice_no}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'c_invoice_no', setValues)
    }
  />
);

export const CompanyName = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="会社名"
    name="c_name"
    value={values.c_name}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'c_name', setValues)
    }
  />
);

export const CompanyPostalCode = ({
  values,
  setValues,
}: GridItemFieldProps) => (
  <GridItemTextField
    label="郵便番号"
    name="c_postal_code"
    value={values.c_postal_code}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'c_postal_code', setValues)
    }
  />
);

export const CompanyAddress1 = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="住所1"
    name="c_address1"
    value={values.c_address1}
    size={{ xs: 12, sm: 6, lg: 4 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'c_address1', setValues)
    }
  />
);

export const CompanyAddress2 = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="住所2"
    name="c_address2"
    value={values.c_address2}
    size={{ xs: 12, sm: 6, lg: 4 }}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'c_address2', setValues)
    }
  />
);

export const ProjectInChargeName = ({
  values,
  setValues,
}: GridItemFieldProps) => (
  <GridItemTextField
    label="担当者名"
    name="pj_in_charge_name"
    value={values.pj_in_charge_name}
    handleChangeValues={(val: string) =>
      handleChangeValues(val, 'pj_in_charge_name', setValues)
    }
  />
);

export const ProjectMemo = ({
  values,
  setValues,
  pj_id,
}: GridItemFieldProps & { pj_id: number }) => (
  <GridItemRowTextField
    name="memo"
    label=""
    row={2}
    value={values[pj_id]}
    handleChangeValues={(val: string) => {
      setValues((prev: any) => ({
        ...prev,
        [pj_id]: val,
      }));
    }}
  />
);
